
import React from 'react';
import useFormatNumber from '../../../../Hooks/useFormatNumber';
import useUser from '../../../../Hooks/useUser';


function TotalsForZone({ data }) {

    const { formatNumberMile } = useFormatNumber()
    const { userData } = useUser()

    return userData?.email === 'lacarlota@gb.com' ? <></> : (<>
        <section className='card  h-auto col-span-full lg:col-span-2 px-1 lg:px-4'>
            <h6 className='font-bold pl-2'>Resumen por zona</h6>
            <div className='grid grid-cols-5 text-xs gap-y-1 mt-3 pl-2 justify-between 	'>
                <p className='font-bold text-xs  pt-[2px] bg-black rounded-[0px] rounded-tl-[8px] rounded-bl-[8px] pl-2 w-full'>Zona</p>
                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Cantidad</p>
                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Bolivares</p>
                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Dolares</p>
                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Cop</p>

                {data?.getTotals?.responseTotalsByLocation?.sort((a, b) => b.cantidad - a.cantidad)?.map((el, key) => {


                    return <React.Fragment key={key}>

                        {
                            userData.email === 'pimpinela@gb.com' ? <>
                                {
                                    el.localidad === 'BOX' ? <></> : <>
                                        <p className='pl-2 relative '>{el?.localidad}</p>
                                        <p className='pl-2 relative text-center'>{el?.cantidad}</p>
                                        <p className='pl-2 relative text-center'>{formatNumberMile(el.bolivares ? el.bolivares : 0)}</p>
                                        <p className='justify-self-center text-center'>{formatNumberMile(el.dolares ? el.dolares : 0)}</p>
                                        <p className='justify-self-center text-center'>{formatNumberMile(el.cop ? el.cop : 0)}</p>
                                    </>
                                }

                            </> : <>
                                <p className='pl-2 relative '>{el?.localidad}</p>
                                <p className='pl-2 relative text-center'>{el?.cantidad}</p>
                                <p className='pl-2 relative text-center'>{formatNumberMile(el.bolivares ? el.bolivares : 0)}</p>
                                <p className='justify-self-center text-center'>{formatNumberMile(el.dolares ? el.dolares : 0)}</p>
                                <p className='justify-self-center text-center'>{formatNumberMile(el.cop ? el.cop : 0)}</p>
                            </>
                        }
                    </React.Fragment >
                })}
            </div>
        </section>
    </>);
}

export default TotalsForZone;