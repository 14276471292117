function useDate() {
    function datesByDaysAgo(number) {
        const beforedate = new Date();
        const priordate = new Date().setDate(beforedate.getDate() - number);
        return { end: beforedate, start: new Date(priordate) }
    }

    return { datesByDaysAgo };
}

export default useDate;
