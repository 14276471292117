import axios from "axios"
import { Skeleton } from "primereact/skeleton"
import { useState } from "react"
import { useEffect } from "react"
import { TableXConfig } from "../../Components/TableX/TableXResumen"
import useFormatNumber from "../../Hooks/useFormatNumber"
import useLiquidations from "../../Hooks/useLiquidations"
import useSales from "../../Hooks/useSales"
import useUser from "../../Hooks/useUser"
import { serverBaseUrl } from "../../repository/config"
import tableConfig from "./tableConfig.json"
import tableConfigEarring from "./tableConfigEarring.json"



export default function Liquidations({ eventId }) {

    const { request } = useLiquidations()
    const { userData } = useUser()
    const { getTotals } = useSales()
    const { formatNumberMile } = useFormatNumber()

    const [loading, setLoading] = useState(true)
    const [totals, setTotals] = useState([])
    const [liquidations, setLiquidations] = useState([])

    const requestTotals = async ({ totalPaidBs, totalPaidDollar, totalPaidCop }) => {

        try {
            const resposeTotals = await axios.post(`${serverBaseUrl}/api/sales/get-all-sales-for-events`, { idEvents: [eventId] })
            const sales = resposeTotals.data.sales
            if (sales) {
                return [
                    {
                        coin: "Dolares",
                        total: formatNumberMile(resposeTotals.data.getTotals.totalDolaresProductor),
                        pagado: formatNumberMile(totalPaidDollar),
                        pendiente: eventId === 'ROMEOXSANTOSXVOL3' ? "US " + formatNumberMile(resposeTotals.data.getTotals.totalDolaresProductor - 106873.4 - totalPaidDollar) : "US " + formatNumberMile(resposeTotals.data.getTotals.totalDolaresProductor - totalPaidDollar),
                    },
                    {
                        coin: "Bolivares",
                        total: formatNumberMile(resposeTotals.data.getTotals.totalBolivaresProductor),
                        pagado: formatNumberMile(totalPaidBs),
                        pendiente: "BS " + formatNumberMile(resposeTotals.data.getTotals.totalBolivaresProductor - totalPaidBs)
                    },
                    {
                        coin: "COP",
                        total: formatNumberMile(resposeTotals.data.getTotals.totaCopProductor),
                        pagado: formatNumberMile(totalPaidCop),
                        pendiente: "COP " + formatNumberMile(resposeTotals.data.getTotals.totaCopProductor - totalPaidCop)
                    }
                ]
            }

            return []
        } catch (err) {
            console.log(err)
            return []
        }
    }

    const requestLiquidations = async () => {
        try {
            const responseLiquidations = await request({ eventId: eventId })
            const responseRequestTotals = await requestTotals({
                totalPaidBs: responseLiquidations.totalInBs,
                totalPaidDollar: responseLiquidations.totalInDollars,
                totalPaidCop: responseLiquidations.totalInCOP,
            })
            setTotals(responseRequestTotals)
            setLiquidations(responseLiquidations.arr)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const filterExportData = (params, datad) => {
        let result = datad?.filter((e) => {
            return (
                String(e?.tipoPago).includes(
                    params.filters?.tipoPago?.value ?? ""
                ) &&
                String(e?.referencia).includes(params.filters?.referencia?.value ?? "") &&
                String(e?.monto).includes(
                    params.filters?.monto?.value ?? ""
                ) &&
                String(e?.descripcion).includes(params.filters?.descripcion?.value ?? "")
            );
        });
        return result;
    };

    useEffect(() => {
        if (userData) requestLiquidations()
    }, [userData, eventId])



    return (
        <>
            {
                loading ? <>
                    <Skeleton className=" w-full h-[200px] mt-3 " width="100%" height="200px"></Skeleton>
                    <Skeleton className=" w-full h-[350px] mt-3 " width="100%" height="350px"></Skeleton>
                </>
                    : <div className="w-auto grid grid-cols-1">
                        <div className="col-span-full">
                            <TableXConfig
                                tableConfig={tableConfigEarring}
                                data={totals}
                                autoLayout={true}
                                noLazy
                                noPaginator
                            />
                            <TableXConfig
                                tableConfig={tableConfig}
                                data={liquidations}
                                exportable
                                autoLayout={true}
                                noLazy
                                filterExportData={filterExportData}
                            />
                        </div>
                    </div>
            }
        </>
    )
}