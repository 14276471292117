import { useState, useEffect } from 'react'
import { logout, onAuthState, fetchUser } from "../Firebase/client"
import { useSessionAuthContext } from '../Context/session-auth-context';

export const USER_STATES = {
    NOT_LOGGED: null,
    NOT_KNOW: undefined,
}

export default function useUser() {

    const { userData, setUserData } = useSessionAuthContext()
    const [error, setError] = useState(false)

    const closeSession = async () => {
        let res = await logout()
        return res
    }

    useEffect(() => {
        if (!userData) {
            onAuthState().onAuthStateChanged((user) => {
                if (
                    window.location.hash !== "#/SignIn"
                    && window.location.hash !== "#/Signin"
                    && window.location.hash !== "#/Login"
                    && !window.location.hash.includes('closing')
                    && !window.location.hash.includes("/File")
                ) {
                    if (!user) window.location = "/#/SignIn"
                }
                const normalizedUser = user ? user : null
                if (normalizedUser) {
                    fetchUser(user.email)
                        .then(res => {
                            if (res) {
                                setUserData(res[0])
                            } else {
                                setError("Usuario no es vendedor")
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
        }
    }, [userData])

    return { userData, closeSession }
}





