import { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import useUser from '../../../Hooks/useUser'
import { InputText } from 'primereact/inputtext'
import { BreadCrumb } from 'primereact/breadcrumb'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProgressSpinner } from 'primereact/progressspinner'
import useProducerUser from '../../../Hooks/useProducerUser'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'

export default function PublicData() {

    const items = [
        { label: 'Configuración' },
        { label: 'Datos públicos' },
    ];

    const home = { icon: 'pi pi-home', url: '/' }

    const toast = useRef()

    const [loading, setLoading] = useState(true)

    const { userData } = useUser()

    const [data, setData] = useState(false)

    const { getPublicData, publicDataConfiguration } = useProducerUser()

    const onSubmit = async (e) => {
        try {
            console.log(e)
            e.preventDefault()
            e.target.reset()
            setLoading(true)
            const response = await publicDataConfiguration({ producerId: userData.producer, data: data })
            if (response) {
                if (response.isSuccess) {
                    toast.current.show({ severity: 'info', summary: 'Exito', detail: 'Datos ajustados' })
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const requestPublicDataConfiguration = async () => {
        try {
            const request = await getPublicData({ id: userData.producer })
            if (request) {
                setData(request)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userData) requestPublicDataConfiguration()
    }, [userData])

    return (<>
        <>
            <div className="grid">
                <BreadCrumb className='border-none text-sm mb-4' model={items} home={home} />
                <div className='mb-3'>
                    <span>Datos como organizador</span>
                    <p className='grid'>Esta información será privada. En tus eventos solo indicaremos el nombre de tu productora.  Los datos serán usados para la confección de las facturas asociadas al resultado de sus eventos.</p>
                </div>
                <div className='card mb-4'>
                    {
                        loading ? <>
                            <div className="w-full  h-[40vh] flex justify-center items-center">
                                <ProgressSpinner />
                            </div>
                        </> : <>
                            <form onSubmit={onSubmit}>
                                <div className='mt-4 grid gap-3'>

                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Nombre público de la empresa</label>
                                        <div>
                                            <InputText value={data?.publicName} name="publicName" onChange={handleInputChange} className="w-full" required />
                                            <span className='text-xs text-gray-600 grid'>
                                                El nombre público de la empresa puede usarse en facturas y recibos. Asegúrate de que sea correcto.
                                            </span>
                                        </div>
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Correo electronico de soporte</label>
                                        <InputText value={data?.emailSupport} name="emailSupport" onChange={handleInputChange} required />
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Telefono de soporte</label>
                                        <InputText value={data?.supportPhone} name="supportPhone" onChange={handleInputChange} required />
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Dirección de soporte (Opcional)</label>
                                        <InputText value={data?.supportAddress} name="supportAddress" onChange={handleInputChange} />
                                    </div>

                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Descripción de la empresa</label>
                                        <div>
                                            <InputTextarea value={data?.companyDescription} rows={5} cols={30} autoResize name="companyDescription" onChange={handleInputChange} className="w-full" required />
                                            <span className='text-xs text-gray-600 grid'>
                                                Descripción breve de la compañia
                                            </span>
                                        </div>
                                    </div>

                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Sitio web la empresa</label>
                                        <InputText value={data?.companyWebsite} name="companyWebsite" onChange={handleInputChange} required />
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Instagram (Opcional)</label>
                                        <InputText value={data?.instagram} name="instagram" onChange={handleInputChange} />
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-2'>
                                        <label>Facebook (Opcional)</label>
                                        <InputText value={data?.facebook} name="facebook" onChange={handleInputChange} />
                                    </div>
                                </div>
                                <Divider />
                                <div className='flex justify-end'>
                                    <Button
                                        label="Guardar"
                                        icon="pi pi-save"
                                        // onClick={() => onSubmit()}
                                        type='submit'
                                        loading={loading}
                                        className="bg-[#9fa8daeb]"
                                    ></Button>
                                </div>
                            </form>

                        </>
                    }
                </div>
            </div>
        </>
        <Toast ref={toast}></Toast>
    </>
    )
}