import { async } from '@firebase/util';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useState } from 'react';
import AddLinkedAccount from '../../../Components/Forms/AddLinkedAccount/AddLinkedAccount';
import useProducerUser from '../../../Hooks/useProducerUser';
import useUser from '../../../Hooks/useUser';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { platformRequirements } from '../../../Components/Forms/CompletePlatformData/CompletePlatformData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';


export default function LinkedAccounts() {

    const items = [
        { label: 'Configuración' },
        { label: 'Cuentas vinculadas' },
    ];

    const home = { icon: 'pi pi-home', url: '/' }

    const [show, setShow] = useState(false)
    const [linkedAccount, setLinkedAccount] = useState([])
    const [loading, setLoading] = useState(true)
    const [linkedAccountInformation, setLinkedAccountInformation] = useState(false)

    const { userData } = useUser()

    const { findLinkedAccounts } = useProducerUser()

    const requestFindLinkedAccounts = async () => {
        try {
            const request = await findLinkedAccounts({ event: userData.producer })
            if (request) {
                if (request.methodsConfigure) {
                    setLinkedAccount(Object.keys(request.methodsConfigure))
                    setLinkedAccountInformation(request.methodsConfigure)
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }


    const defineInformationToDisplay = (x) => {
        const keysInInformationToDisplay = Object.keys(x)
        return keysInInformationToDisplay.filter(x => x !== 'currency')
    }

    useEffect(() => {
        if (userData) requestFindLinkedAccounts()
    }, [userData])

    return (<>
        {
            loading ? <>
                <div className="w-full  h-[90vh] flex justify-center items-center">
                    <ProgressSpinner />
                </div>
            </> : <>
                {show && <AddLinkedAccount
                    show={show}
                    setShow={setShow}
                    linkedAccount={linkedAccount}
                    setLinkedAccount={setLinkedAccount}
                    setLinkedAccountInformation={setLinkedAccountInformation}
                    linkedAccountInformation={linkedAccountInformation}
                />}
                <div className="grid">
                    <BreadCrumb className='border-none text-sm' model={items} home={home} />

                    <div className='flex mt-3 justify-between'>
                        <div>
                            <h5>Cuentas vinculadas</h5>
                            <span className='mt-1 text-sm text-gray-600'>Administra las cuentas vinculadas que ayudan a atender a tu empresa</span>
                        </div>
                        <Button label="Agregar cuenta" icon="pi pi-plus" onClick={() => setShow(true)} />
                    </div>

                    <div className='mt-4 mb-4'>
                        {
                            linkedAccount.length === 0 && <>
                                <Message severity="info" text="No se encontrarón cuentas vinculadas" className='w-full' />
                            </>
                        }
                        {
                            linkedAccount.length !== 0 && <Accordion className="grid gap-3">
                                {
                                    linkedAccount.map((x, index) => <AccordionTab header={<div className='flex items-center gap-3'>
                                        <img className='w-[30px] h-[25px] object-contain' src={platformRequirements[x] && platformRequirements[x].src}></img>
                                        <p>{platformRequirements[x] && platformRequirements[x].name}</p>
                                    </div>} key={index}>
                                        <p></p>
                                        {defineInformationToDisplay(linkedAccountInformation[x])?.map((y, index) => <p key={index}>{String(y).toUpperCase()}: {linkedAccountInformation[x][y]}</p>)}
                                    </AccordionTab>)
                                }
                            </Accordion>
                        }

                    </div>
                </div>
            </>
        }
    </>
    )
}