import { Tooltip } from "@mui/material"
// import { Tooltip } from "primereact/tooltip"
import { useState } from "react"
import Radiation from "../../icons/Radiation"
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export interface RadioData {
    value: string | number
    view: string
    icon?: any
}

interface Props {
    items: RadioData[]
    register: any
    name: string,
    label?: string
}



export default function RadioGroupButton(props: Props) {

    const { items, register, name, label } = props;
    const [currentValue, setCurrentValue] = useState(items[0].value)

    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#662d91",
        },
    }));


    if (name.includes('shape-of-table')) return (
        <div className="radio-button-gourp-samll-container">
            {label && <span className="text-gb-gray-900 font-bold text-white">{label}</span>}
            <div className='radio-group-button tableType' >
                {
                    items.map((item, index) => {
                        const { value, view, icon: Icon } = item;
                        return <label className="radio-button" key={name + index} >
                            <input className="check" type="radio" value={value} {...register} />
                            <BootstrapTooltip title={view}>
                                <div className="box">
                                    {item.icon ? <Icon /> : <Radiation />}
                                </div>
                            </BootstrapTooltip>
                        </label>
                    })
                }
            </div>
        </div>
    )

    return (
        <div className="radio-button-gourp-samll-container">
            {label && <span className="text-gb-gray-900 font-bold text-white">{label}</span>}
            <div className='radio-group-button locationType' >
                {
                    items.map((item, index) => {
                        const { value, view, icon } = item;
                        return (
                            <label className="radio-button" key={name + index} >
                                <input className="check" type="radio" value={value} {...register} />
                                <BootstrapTooltip title={view} style={{ width: "100%" }}>
                                    {/* <Tooltip target={`.type-location-${index}`} className="w-auto"  >{view} </Tooltip> */}
                                    <div
                                        key={index}
                                        className={`box-type-location type-location-${index}  cursor-pointer h-[158px] rounded-lg bg-[#131517]`}
                                    >
                                        <img className={`p-3 w-[250px] h-[120px] object-contain m-auto `} src={icon}></img>
                                        <span className="text-sm text-center text-gray-600 flex justify-center">{view}</span>
                                    </div>
                                </BootstrapTooltip>
                            </label>
                        )
                    })
                }
            </div>
        </div>
    )

}
