import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Rect, Transformer } from "react-konva";

const SimpleRectangle = ({ ...props }) => {
    return (<>
        <Rect  {...props} />
    </>)
}

const DesignRectanle = ({ onChange, id, selectShape, isSelected, ...props }) => {


    const shapeRef = useRef();
    const trRef = useRef()

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const handleSelect = () => {
        // trRef.current.nodes([shapeRef.current]);
        // trRef.current.getLayer().batchDraw();
        selectShape(id)
    }

    const onTransformEnd = () => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);
        onChange({
            id: id,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
        });
        selectShape(null)
    }

    const onDragEnd = (e) => {
        onChange({
            id: id,
            x: e.target.x(),
            y: e.target.y(),
        });
        selectShape(null)
    }

    return (<Fragment>
        <Rect
            {...props}
            draggable
            name="rectangle"
            ref={shapeRef}
            onTap={handleSelect}
            onClick={handleSelect}
            onDragEnd={(e) => onDragEnd(e)}
            onTransformEnd={(e) => onTransformEnd()}
        />
        {isSelected && (
            <Transformer
                ref={trRef}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
            />
        )}
    </Fragment>)
}

function Rectangle({ design, rectangleProps, ...props }) {
    


    if (design) return <DesignRectanle {...props} {...rectangleProps} />

    return <SimpleRectangle  {...props} {...rectangleProps} />
}

export default memo(Rectangle)