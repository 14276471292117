import  { useState } from "react";
import { Form } from "react-final-form";
import FormLogin from "./FormLogin";
import Logo from '../../assets/Logo'
import "./style.css";
import { loginUser } from "../../Firebase/client";
import { useSessionAuthContext } from "../../Context/session-auth-context";
import { useNavigate } from "react-router-dom";

export default function LogIn() {

  const navigate = useNavigate()

  const [, setShowMessage] = useState(false);
  const [, setFormData] = useState({});
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { setUserData } = useSessionAuthContext()

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const validate = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = "El correo es requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = "Correo Invalido. E.j. example@email.com";
    }
    if (!data.password) {
      errors.password = "La contraseña es requerida";
    }
    return errors;
  }

  const onSubmit = (data, form) => {
    setFormData(data);
    setShowMessage(true);
    form.restart();

    localStorage.clear()
    if ('caches' in window) {
      caches.keys()
        .then(function (keyList) {
          return Promise.all(keyList.map(function (key) {
            return caches.delete(key);
          }));
        })
    }
    setLoading(true)
    loginUser(data.email, data.password, true)
      .then(res => {
        if (res) {
          setUserData(res)
          if (res.rol) {
            if (res.rol === "aseguradora") {
              navigate("/Insurance")
              return null
            }
            if (res.rol === "delivery") {
              navigate("/Delivery")
              return null
            }
          }
          navigate('/')
          setLoading(false)
        } else {
          setError("Error, intente nuevamente")
          setLoading(false)
        }
      })

  }

  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  }

  return (
    <>
      <section className="w-full h-full p-2 flex flex-col gap-5 justify-center transition-all duration-150 items-center z-40 ">

        <div className="card w-full  lg:w-[420px] max-w-[420px]  h-auto py-5 pb-10 transition-all duration-150  ">
          <div className=" text-center">
            <Logo className="m-auto " />
          </div>
          {/* <h5 className="text-center font-semibold ">Iniciar sesión</h5> */}
          <Form
            onSubmit={onSubmit}
            initialValues={{ email: "", password: "" }}
            validate={validate}
            render={({ handleSubmit }) => (
              <FormLogin
                loading={loading}
                error={error}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                handleSubmit={handleSubmit}
              />
            )}
          />
        </div>
      </section>
    </>
  );
}
