
import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';

export default function SelectCountry({ setCountry, defaultValue, errorMessage }) {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: 'Venezuela', code: 'VE' },
        { name: 'Argentina', code: 'AR' },
        // { name: 'Australia', code: 'AU' },
        // { name: 'Brazil', code: 'BR' },
        // { name: 'China', code: 'CN' },
        // { name: 'Egypt', code: 'EG' },
        // { name: 'France', code: 'FR' },
        // { name: 'Germany', code: 'DE' },
        // { name: 'India', code: 'IN' },
        // { name: 'Japan', code: 'JP' },
        // { name: 'Spain', code: 'ES' },
        // { name: 'United States', code: 'US' }
    ];

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`/flags/${option.code}.png`} className={`mr-2 flag `} style={{ width: '32px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`/flags/${option.code}.png`} className={`mr-2 flag `} style={{ width: '32px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="grid">
            <label className="text-gb-gray-900" >Seleccionar país*</label>
            <Dropdown
                value={defaultValue ? countries.find(x => x.name === defaultValue) : selectedCountry}
                defaultValue={defaultValue ? countries.find(x => x.name === defaultValue) : selectedCountry}
                onChange={(e) => {
                    setSelectedCountry(e.value)
                    setCountry(e.value.name)
                }}
                options={countries}
                optionLabel="name"
                placeholder="Seleccionar país*"
                filter valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem gb-input"
                style={{ background: '#131517 !important', height: "50px" }}
            />
            {errorMessage && <span className="text-message-error  text-red-600	"> {errorMessage}</span>}

        </div>
    )
}


