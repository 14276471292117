import { useState } from "react";
import { Form } from "react-final-form";
import FormSignIn from "./FormSignIn";

export default function EnterEmail({ loading, onSubmit }) {

    const [error, setError] = useState(false)

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

    const validate = (data) => {
        let errors = {};
        if (!data.email) {
            errors.email = "El correo es requerido";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = "Correo Invalido. E.j. example@email.com";
        }
        // if (!data.password) {
        //     errors.password = "La contraseña es requerida";
        // }
        return errors;
    }

    const handleSubmit = (data, form) => {
        // form.restart()
        if (onSubmit) onSubmit(data)
    }

    const getFormErrorMessage = (meta) => {
        return (
            isFormFieldValid(meta) && <small className="p-error flex text-left">{meta.error}</small>
        );
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{ email: "", password: "" }}
            validate={validate}
            render={({ handleSubmit }) => (
                <FormSignIn
                    type="email"
                    error={error}
                    loading={loading}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    handleSubmit={handleSubmit}
                />
            )}
        />
    )
}