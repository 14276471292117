import axios from "axios";
import { useState } from "react";
import './style.css'
import { Button } from "@mui/material";

export default function Chat() {

    const [error, setError] = useState(false)
    const [messages, setMessages] = useState([
        {
            role: "assistant",
            content: '¡Bienvenido soy un asistente virtual de globalboletos! Estoy aquí para guiarte en cada paso del proceso. Permíteme ayudarte a crear y personalizar tu evento de manera sencilla y eficiente. ¿En qué puedo asistirte hoy?'
        }
    ])
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({ prompt: "", })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    }

    const handleSubmit = async (e) => {
        try {

            if(e){
                e.preventDefault()
                e.target.reset()
            }
 
            if (loading) return null
            if(String(form.prompt)==='') return null

     

            if (error) {
                // const lastMessaage = messages.reverse()[0]
                // console.log(lastMessaage)
                // setMessages(messages.filter(x => x.content !== lastMessaage.content))
                setError(false)
            }

            setLoading(true)

            setMessages(messages.concat({ role: "user", content: form.prompt }))

            const url = "https://creatorsbot.globalboletos.com/tell"
            // const url = "http://localhost:5001/tell"

            const response = await axios.post(url, { messages: messages, message: form.prompt })
            if (response.data.isSuccess) {
                setMessages((prev) => prev.concat({ role: "assistant", content: response.data.data }))
            } else {
                setError(true)

            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setError(true)
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     console.log([{ role: 'user', content: "hola" }].concat({ role: 'hola', user: 'system' }))
    // }, [])

    return (
        <>
            <div className="container max-w-2xl mx-auto">
                <div className={` flex flex-col overflow-hidden`} >
                    <div
                        className="flex-[0.75] bg-black-100  rounded-t-2xl min-h-[420px] pb-0 mt-3 mb-3"
                    >
                        <div className="chat-h grid gap-3 text-[13px] max-h-[420px] overflow-y-auto overflow-x-hidden">
                            {
                                messages.map((x, index) => <div className="" key={index}>
                                    <p className={`flex ${x.role === 'assistant' && "justify-start "}    ${x.role === 'user' && "text-right justify-end"}`}>
                                        {x.role === "assistant" && <span> <img width="25px" src="/assets/image/mini-icon.png" className="mr-2 min-w-[25px]" />  </span>}
                                        {x.content}
                                        {x.role === "user" && <span> <img width="25px" src="/assets/user.png" className="mr-1 rounded-full ml-1" />  </span>}
                                    </p>
                                </div>)
                            }
                            {
                                loading && <div>
                                    <p className={`flex justify-start`}>
                                        <span> <img width="25px" src="/assets/image/mini-icon.png" className="mr-2 min-w-[25px]" />  </span>
                                        writing...
                                    </p>
                                </div>
                            }
                            {
                                error && <div>
                                    <p className={`flex justify-start`}>
                                        <span> <img width="25px" src="/PEPE1.png" className="mr-1 min-w-[25px]" />  </span>
                                        service in high demand, try again
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="bg-black-100  rounded-b-2xl">
                        <form
                            // ref={formRef}
                            onSubmit={handleSubmit}
                            className=" flex gap-2 justify-center items-center"
                        >
                            <label className="flex flex-col w-[90%]">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="prompt"
                                    // value={form.prompt}
                                    onChange={handleChange}
                                    placeholder=""
                                    className="bg-tertiary py-2 px-3 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
                                />
                            </label>
                            <Button
                                className=" h-[30px] p-button-text p-button-plain  text-white flex items-center justify-center hover:bg-[#272727]"
                            >
                                <span className="  p-button-text p-button-plain  text-white pi pi-reply hover:bg-[#272727]"></span>
                            </Button>
                            {/* <i className="pi pi-reply" style={{ fontSize: '1rem' }}></i> */}


                            {/* <button
                        disabled={loading ? true : false}
                        type="submit"
                        className="bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary disabled"
                    >
                        {loading ? "requesting extraction..." : "bring pepe"}
                    </button> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}