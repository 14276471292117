import { memo } from "react";
// import { Rect, Text } from "react-konva";
import Seat from "../Seat";
import Rectangle from "../Rectangle";
import Typography from "../Typography";

const Table = ({ index, selectedId, selectShape, onChange, seats, margin, row, draggable, zone, position, selectChair, initialX, selected, handleShowLabel, closeShowLabel, elemetSize, text }) => {

    const tableSize = elemetSize;

    const firstGroup = seats.slice(0, 3)
    const secondGroup = seats.slice(3, 7)
    const thirdGroup = seats.slice(7)

    const x = index + 0.3
    const y = 100 + (row * 140)

    const elementNumber = text ? String(text) : false


    return (
        <>
            <Rectangle
                radius={5}
                fill="#3f3f3f"
                name='rectangle'
                stroke="#252525"
                strokeWidth={5}
                cornerRadius={5}
                onChange={onChange}
                draggable={draggable}
                design={draggable}
                selectShape={selectShape}
                id={`${zone}-F${row}-T${index}`}
                isSelected={selectedId === `${zone}-F${row}-T${index}` ? true : false}
                y={position({ parameter: 'y', id: `${zone}-F${row}-T${index}`, defaultPosition: y })}
                width={position({ parameter: 'width', id: `${zone}-F${row}-T${index}`, defaultPosition: tableSize })}
                height={position({ parameter: 'height', id: `${zone}-F${row}-T${index}`, defaultPosition: tableSize })}
                x={position({ parameter: 'x', id: `${zone}-F${row}-T${index}`, defaultPosition: initialX + x * (tableSize + margin) })}
            />
            {
                text && <Typography
                    fill="#fff"
                    name='rectangle'
                    fontStyle="bold"
                    design={draggable}
                    onChange={onChange}
                    draggable={draggable}
                    selectShape={selectShape}
                    id={`${zone}-F${row}-T${index}-Text`}
                    isSelected={selectedId === `Escenario-${zone}-Text` ? true : false}
                    text={elementNumber.length < 2 ? `0${elementNumber}` : elementNumber}
                    width={position({ parameter: "width", id: `${zone}-F${row}-T${index}-Text`, defaultPosition: tableSize })}
                    height={position({ parameter: "height", id: `${zone}-F${row}-T${index}-Text`, defaultPosition: tableSize })}
                    x={position({ parameter: "x", id: `${zone}-F${row}-T${index}-Text`, defaultPosition: 20 + x * (tableSize + margin) })}
                    y={position({ parameter: "y", id: `${zone}-F${row}-T${index}-Text`, defaultPosition: y + 20 })}
                />
            }



            {firstGroup.map((circle, circleIndex) => {
                const offsetX = (index * (tableSize + margin) + tableSize / 2) - 10;
                const offsetY = (110 + (row * 142)) + circleIndex * 16
                return (
                    <Seat
                        data={circle}
                        key={circleIndex}
                        draggable={draggable}
                        selectChair={selectChair}
                        closeShowLabel={closeShowLabel}
                        handleShowLabel={handleShowLabel}
                        isSelected={selected.some(z => z.id === circle.id)}

                        y={position({ parameter: "y", id: circle.id, defaultPosition: offsetY })}
                        x={position({ parameter: "x", id: circle.id, defaultPosition: initialX + offsetX })}


                        id={circle.id}
                        onChange={onChange}
                        selectShape={selectShape}

                    />
                );
            })}
            {secondGroup.map((circle, circleIndex) => {
                const offsetX = circleIndex === 0 ? (index * (tableSize + margin) + tableSize / 2) + circleIndex * 22 : (index * (tableSize + margin) + tableSize / 2) + circleIndex * 18;
                const offsetY = 160 + (row * 142)

                return (
                    <Seat
                        // y={offsetY}
                        data={circle}
                        key={circleIndex}
                        draggable={draggable}
                        // x={initialX + offsetX}
                        selectChair={selectChair}
                        closeShowLabel={closeShowLabel}
                        handleShowLabel={handleShowLabel}
                        isSelected={selected.some(z => z.id === circle.id)}

                        y={position({ parameter: "y", id: circle.id, defaultPosition: offsetY })}
                        x={position({ parameter: "x", id: circle.id, defaultPosition: initialX + offsetX })}


                        id={circle.id}
                        onChange={onChange}
                        selectShape={selectShape}
                    />
                );
            })}
            {thirdGroup.reverse().map((circle, circleIndex) => {
                const offsetX = (index * (tableSize + margin) + tableSize / 2) + 60;
                const offsetY = (110 + (row * 142)) + circleIndex * 16

                return (
                    <Seat
                        // y={offsetY}
                        data={circle}
                        key={circleIndex}
                        draggable={draggable}
                        // x={initialX + offsetX}
                        selectChair={selectChair}
                        closeShowLabel={closeShowLabel}
                        handleShowLabel={handleShowLabel}
                        isSelected={selected.some(z => z.id === circle.id)}

                        y={position({ parameter: "y", id: circle.id, defaultPosition: offsetY })}
                        x={position({ parameter: "x", id: circle.id, defaultPosition: initialX + offsetX })}



                        id={circle.id}
                        onChange={onChange}
                        selectShape={selectShape}
                    />
                );
            })}
        </>
    )
}

// export default Table

export default memo(Table)
