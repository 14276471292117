import { storage } from "../Config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { useState } from "react"


export default function useStorage() {

    const COMPOSE_STATES = {
        USER_NOT_KNOW: 0,
        LOADING: 1,
        SUCCESS: 2,
        ERROR: -1,
        LOADING_FORM: 10,
        NO_LOADING_FORM: 20,
    }

    const [status, setStatus] = useState(COMPOSE_STATES)
    const [task, setTask] = useState(null)
    const [imgUrl, setImgUrl] = useState(null)
    const [loading, setLoading] = useState(COMPOSE_STATES.USER_NOT_KNOW)

    const uploadImg = async (file, route) => {
        try {
            const refStorage = ref(storage, `${route}/${file.name}`)
            const responseUploadBytes = await uploadBytes(refStorage, file)
            const refImg = await getDownloadURL(responseUploadBytes.ref)
            return refImg
        } catch (err) {
            console.log(err)
            return false
        }

    }

    return { uploadImg, COMPOSE_STATES, setTask, imgUrl, loading, status, setStatus, COMPOSE_STATES, setLoading }
}