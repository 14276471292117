import React, { useState } from "react"
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Link,
    PDFViewer,
    Image,
    PDFDownloadLink,
} from "@react-pdf/renderer"
import { Button } from "primereact/button"
import { ProgressSpinner } from "primereact/progressspinner"
import { useEffect } from "react"
import axios from "axios"
import { methodsInBs, serverBaseUrl } from "../../repository/config"
import useUser from "../../Hooks/useUser"
import useFormatNumber from "../../Hooks/useFormatNumber"
import useLiquidations from "../../Hooks/useLiquidations"
import { formatDate } from "../../Firebase/client"

const Title = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "5px",
            }}
        >
            {children}
        </Text>
    )
}
const SubTitle = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "11px",
                fontWeight: "700",
                marginBottom: "10px",
            }}
        >
            {children}
        </Text>
    )
}

const MainComponentOfIndex = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "5px",
                fontSize: "11px",
                fontWeight: "700",
                marginBottom: "10px",
            }}
        >
            {children}
        </Text>
    )
}

const SecondaryComponentOfIndex = ({ children }) => {
    return (
        <Text
            style={{
                fontSize: "11px",
                marginBottom: "10px",
                marginLeft: "10px"
            }}
        >
            {children}
        </Text>
    )
}

const Footer = () => {
    return (<></>
        // <View
        //     style={{ position: "absolute", bottom: 35, left: 5 }}
        //     fixed
        // >
        //     <View
        //         style={{
        //             display: "flex",
        //             flexDirection: "row",
        //             justifyContent: "center",
        //             alignItems: "center",
        //         }}
        //     >
        //         <Image
        //             src="/assets/image/global-banner.png"
        //             style={{ width: "130px" }}
        //         />
        //         <Text
        //             style={{
        //                 fontSize: "10px",
        //                 marginTop: "10px",
        //                 marginLeft: "5px",
        //                 marginRight: "5px",
        //             }}
        //         >
        //             TODOS LOS DERECHOS RESERVADOS POR GLOBAL BOLETOS
        //         </Text>

        //         <Text
        //             style={{
        //                 fontSize: "10px",
        //                 marginLeft: "5px",
        //                 marginTop: "10px",
        //                 marginRight: "5px",
        //             }}
        //         >
        //             {new Date().toLocaleDateString()}
        //         </Text>
        //         <Text
        //             style={{
        //                 fontSize: "10px",
        //                 marginLeft: "5px",
        //                 marginRight: "5px",
        //                 marginTop: "10px",
        //             }}
        //             render={({ pageNumber, totalPages }) => `pg ${pageNumber}`}
        //         ></Text>
        //     </View>
        // </View>
    )

}

export default function OverviewReport({ totals, loading, eventId }) {


    const styles = StyleSheet.create({
        table: {
            width: "100%",
            fontSize: "8px",
            margin: 0,
            mineight: 300,
        },
        row: {
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid #EEE",
            paddingTop: 3,
            paddingBottom: 3,
        },
        header: {
            borderTop: "none",
        },
        bold: {
            fontWeight: "bold",
        },
        // So Declarative and unDRY 👌
        row1: {
            width: "27%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        row2: {
            width: "15%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"

        },
        row3: {
            width: "15%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"

        },
        row4: {
            width: "20%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"

        },
        row5: {
            width: "27%",
            display: "flex",
            justifyContent: 'center',
            margin: "auto",
            alignItems: "center"
        },
    })

    const styles2 = StyleSheet.create({
        table: {
            width: "100%",
            fontSize: "8px",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            borderTop: "1px solid #EEE",
            paddingTop: 3,
            paddingBottom: 3,
        },
        header: {
            borderTop: "none",
        },
        bold: {
            fontWeight: "bold",
        },
        // So Declarative and unDRY 👌
        row1: {
            width: "50%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        row2: {
            width: "15%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        row3: {
            width: "15%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        row4: {
            width: "20%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
        row5: {
            width: "27%",
            display: "flex",
            justifyContent: 'center',
            alignItems: "center"
        },
    })

    const { formatNumberMile } = useFormatNumber()

    const { request } = useLiquidations()

    const [loadingReport, setLoadingReport] = useState(true)
    const [errorReport, setError] = useState(false)

    const { userData } = useUser()

    const [data, setData] = useState({
        capacity: 100,
        ticketsSold: 0,
        currentHour: new Date().toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true, }),
        eventDate: "",
        eventTitle: "",
        producerName: userData?.email ? userData?.email : "username ",
        currentDate: new Date().toLocaleDateString(),
        courtesyTickets: 0,
        ticketsByLocation: [{ zona: "", capacity: 0, totalCourtesy: 0, totalTicket: 0, avairableInZone: 0 }],
        ticketsGeneratedByDiscount: [{ zone: "", amount: "" }],
        ticketsByCourtesy: [{ date: "", reason: "", totalTickets: "", zone: "" }],
        incomeIndollars: 0,
        incomeInBs: 0,
        settledInDollars: 0,
        settledInBs: 0,
        balanceInBs: 0,
        balanceInDollars: 0,
        incomenByMethodsPayment: [{ metodoDePago: "", totalBs: 0, totalDolares: 0 }],
        settlementByPaymentMethod: [{ method: "", dollars: 0, bs: 0, paid: 0, payable: 0 }],
        settlementInBs: [{ date: "", meansOfPayment: "", banco: "", bancoReceptor: "", referencia: "", monto: 0 }],
        settlementInDollars: [{ date: "", meansOfPayment: "", banco: "", bancoReceptor: "", referencia: "", monto: 0 }],
    })

    const showEventCalculations = async () => {
        try {
            setLoadingReport(true)
            const responseShowEventCalculations = await axios.post(`${serverBaseUrl}/api/su-xd/finance/show-event-calculations/`, { eventId: eventId }, {
                headers: {
                    usuario: 'VYQSfkgWjldNkfskCMu3cqFDxKo1'
                }
            })

            const requestLiquidations = await request({ eventId: eventId })

            const amountIncomeInDollars = totals.getTotals.totalDolaresProductor
            const amountIncomeInBs = totals.getTotals.totalBolivaresProductor


            const recordOfPaymentsInDollars = responseShowEventCalculations.data.calculations.summaryOfTotals.paymentsToTheProducer.map(x => { return x.totalDolares })
            const recordOfPaymentsInBs = responseShowEventCalculations.data.calculations.summaryOfTotals.paymentsToTheProducer.map(x => { return x.totalBs })
            const amountSettledInBs = Object.values(recordOfPaymentsInDollars).reduce((acc, prev) => acc + prev, 0)
            const amountSettledInDollars = Object.values(recordOfPaymentsInBs).reduce((acc, prev) => acc + prev, 0)

            const structureSettlementByPaymentMethod = responseShowEventCalculations.data.calculations.totalsForTheProducer.map(x => {
                const amount = x.totalDolares > 0 ? x.totalDolares : x.totalBs
                const thereIsAmountOfPayment = responseShowEventCalculations.data.calculations.summaryOfTotals.objetPaymentsToProducer[x.metodoDePago]
                const paymentAmount = thereIsAmountOfPayment ? thereIsAmountOfPayment : 0
                return { method: x.metodoDePago, dollars: formatNumberMile(x.totalDolares), bs: formatNumberMile(x.totalBs), paid: formatNumberMile(paymentAmount), payable: formatNumberMile(amount - paymentAmount) }
            })

            console.log({
                dolares_productor:amountIncomeInDollars,
                pago_en_dolares_a_productor:responseShowEventCalculations
            })



            setData({
                ...data,
                settlementInBs: requestLiquidations.arr.filter(x => x.type === "bs"),
                settlementInDollars: requestLiquidations.arr.filter(x => x.type === "$"),
                settledInBs: formatNumberMile(amountSettledInBs),
                settledInDollars: formatNumberMile(amountSettledInDollars),
                ticketsSold: Object.values(totals.tickets).reduce((acc, { total }) => acc + parseFloat(total), 0),
                capacity: totals.events[0].aforo,
                eventTitle: totals.events[0].title,
                courtesyTickets: Object.values(totals.ticketsForCourtesy).reduce((acc, { total }) => acc + parseFloat(total), 0),
                ticketsByLocation: totals.tickets,
                ticketsByCourtesy: totals.arrCourtesy,
                balanceInBs: formatNumberMile(amountIncomeInBs - amountSettledInBs),
                balanceInDollars: formatNumberMile(amountIncomeInDollars - amountSettledInDollars),
                incomeIndollars: formatNumberMile(amountIncomeInDollars),
                settlementByPaymentMethod: structureSettlementByPaymentMethod,
                incomeInBs: formatNumberMile(amountIncomeInBs),
                incomenByMethodsPayment: responseShowEventCalculations.data.calculations.totalsForTheProducer
            })
            setLoadingReport(false)
        } catch (err) {
            console.log(err)
            setError(true)
            setLoadingReport(false)
        }
    }

    const date = (fecha) => {
        const dia = fecha.getDate().toString().padStart(2, "0"); // Aseguramos que tenga 2 dígitos
        const mes = (fecha.getMonth() + 1).toString().padStart(2, "0"); // Sumamos 1 porque los meses van de 0 a 11
        const anio = fecha.getFullYear();
        const fechaFormateada = `${dia}/${mes}/${anio}`;
        return fechaFormateada
    }

    useEffect(() => {
        if (totals.events && !loading) {
            showEventCalculations()
        }
    }, [totals])


    if (loading) return <ProgressSpinner />

    return (
        <>
            <PDFDownloadLink
                document={
                    <Document >
                        {/* PORTADA */}
                        <Page size="A4" style={{ position: "relative", }} wrap>
                            <View style={{ position: "relative", height: "100%" }} wrap>
                                <Image
                                    src="/assets/image/concert.jpg"
                                    style={{
                                        position: "absolute",
                                        objectFit: "cover",
                                        height: "100%",
                                        width: "100%",
                                        opacity: 0.2,
                                    }}
                                />
                                <View style={{ margin: 30 }}>
                                    <View
                                        style={{
                                            display: "flex",
                                            gap: "15px",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                            position: "relative",
                                        }}
                                    >
                                        <Image
                                            src="/assets/image/global-banner.png"
                                            style={{ width: "240px", height: "64px" }}
                                        />
                                        <View
                                            style={{
                                                width: "2px",
                                                height: "100%",
                                                backgroundColor: "black",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        />
                                        <View style={{ fontSize: "13px", fontWeight: "1000" }}>
                                            <Text style={{ marginTop: "10px" }}>TU MEJOR</Text>
                                            <Text style={{ marginTop: "10px" }}>
                                                OPCIÓN EN BOLETERIA
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={{ marginTop: "250px" }}>
                                        <Text style={{ marginTop: "10px", fontSize: "15px" }}>
                                            {new Date().toLocaleDateString()}
                                        </Text>
                                        <Text style={{ marginTop: "10px", fontSize: "30px" }}>
                                            INFORME DE LIQUIDACIÓN
                                        </Text>
                                        <Text
                                            style={{
                                                marginTop: "10px",
                                                fontSize: "15px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {/* {dataEvent?.title} */}
                                        </Text>
                                        <Text style={{ marginTop: "10px", fontSize: "15px" }}>
                                            {data.producerName}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </Page>

                        {/* INDICE */}
                        <Page size="A4" style={{ margin: 30, paddingBottom: 30 }}>

                            <Title>TABLA DE CONTENIDO</Title>

                            <MainComponentOfIndex> 1. RESUMEN</MainComponentOfIndex>

                            <MainComponentOfIndex>2. BOLETOS GENERADOS</MainComponentOfIndex>
                            <SecondaryComponentOfIndex>2.1 RESUMEN DE BOLETOS GENERADOS</SecondaryComponentOfIndex>
                            <SecondaryComponentOfIndex>2.2 CUADRO DE BOLETOS GENERADOS POR LOCALIDAD</SecondaryComponentOfIndex>
                            {/* <SecondaryComponentOfIndex>2.3 CUADRO DE BOLETOS GENERADOS POR CODIGO PROMOCIONAL</SecondaryComponentOfIndex> */}
                            {/* <SecondaryComponentOfIndex>2.4  MOVIMIENTO DE BOLETOS VENDIDOS POR ORIGEN</SecondaryComponentOfIndex> */}
                            <SecondaryComponentOfIndex>2.3  CUADRO DE BOLETOS GENERADOS POR COTESIA</SecondaryComponentOfIndex>

                            <MainComponentOfIndex>3. INGRESOS</MainComponentOfIndex>
                            <SecondaryComponentOfIndex>3.1  RESUMEN DE INGRESOS</SecondaryComponentOfIndex>
                            {/* <SecondaryComponentOfIndex>3.2  CUADRO DE INGRESOS POR METODO DE PAGO</SecondaryComponentOfIndex> */}
                            {/* <SecondaryComponentOfIndex>3.3 MOVIMIENTO DE INGRESOS POR METODO DE PAGO</SecondaryComponentOfIndex> */}

                            <MainComponentOfIndex>4. LIQUIDACIÓN</MainComponentOfIndex>
                            <SecondaryComponentOfIndex>4.1 RESUMEN DE LIQUIDACIÓN</SecondaryComponentOfIndex>
                            <SecondaryComponentOfIndex>4.2 SALDO PENDIENTE POR LIQUIDAR</SecondaryComponentOfIndex>
                            {/* <SecondaryComponentOfIndex>4.3 CUADRO DE LIQUIDACIÓN POR METODO DE PAGO</SecondaryComponentOfIndex> */}
                            <SecondaryComponentOfIndex>4.3 CUADRO DE LIQUIDACIÓNES REALIZADAS EN DOLARES</SecondaryComponentOfIndex>
                            <SecondaryComponentOfIndex>4.3 CUADRO DE LIQUIDACIÓNES REALIZADAS EN BOLIVARES</SecondaryComponentOfIndex>

                            <Footer />

                        </Page>

                        {/* 1 - 2 - 2.1 - 2.2 - 2.3 */}
                        <Page size="A4" style={{ margin: 30, paddingBottom: 30, paddingRight: 30 }}
                        >

                            <View
                                style={{
                                    marginTop: 20,
                                    marginleft: 30,
                                    marginRight: 30,
                                }}
                            >

                                <Title>1. RESUMEN</Title>

                                <Text style={{ textAlign: "justify", fontSize: "11px", lineHeight: "2px", marginTop: "17px" }}>

                                    Srs: {data.producerName}, en la presente fecha {data.currentDate} {data.currentHour} se genera informe electrónico del evento {data.eventTitle}. Para la fecha se registró
                                    en nuestro sistema la venta de un total de {data.ticketsSold} boletos
                                    generando así ingresos totales por un monto de ${data.incomeIndollars} y {data.incomeInBs}Bs de los cuales
                                    se ha liquidado la cantidad de ${data.settledInDollars} y {data.settledInBs}Bs,
                                    enviados a sus diferentes cuentas bancarias, además de esto se genero la cantidad de {data.courtesyTickets} entradas de cortesía solicitadas previamente por su empresa.
                                    {/* {disputedSales?.length
                                        ? `Es importante señalar que nuestro sistema detectó la cantidad de ${disputedSales?.length
                                        } pagos fraudulentos que se encuentran en disputas ascendiendo a la cantidad de ${"totalTicketDisputed()"} boletos
                                              lo que equivale a ${"totalDollarsDisputed()"}$ que fuerón deducidos del ingreso total.`
                                        : ""}  */}
                                </Text>

                                <Title>2. BOLETOS GENERADOS</Title>

                                <SubTitle>2.1 RESUMEN DE BOLETOS GENERADOS</SubTitle>


                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <View
                                        style={{
                                            border: 1,
                                            backgroundColor: "#f5f5f4",
                                            borderColor: "#dedede",
                                            width: "170px",
                                            padding: 2,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "15px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            AFORO
                                        </Text>
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "18px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {data.capacity}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            border: 1,
                                            backgroundColor: "#f5f5f4",
                                            borderColor: "#dedede",
                                            width: "170px",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            padding: 2,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "15px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            BOLETOS VENDIDOS
                                        </Text>
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "18px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {data.ticketsSold}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            border: 1,
                                            backgroundColor: "#f5f5f4",
                                            borderColor: "#dedede",
                                            width: "170px",
                                            padding: 2,
                                            borderRadius: 5,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "15px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            CORTESIAS
                                        </Text>
                                        <Text
                                            style={{
                                                marginTop: "15px",
                                                fontSize: "18px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {data.courtesyTickets}
                                        </Text>
                                    </View>
                                </View>

                            </View>

                            <View
                                style={{
                                    marginleft: 20,
                                    marginRight: 20,
                                    marginTop: 5,
                                    marginBottom: "-40px",
                                }}
                            >
                                <SubTitle>2.2 CUADRO DE BOLETOS GENERADOS POR LOCALIDAD</SubTitle>


                                <View style={{ ...styles2.table }}>
                                    <View style={[styles2.row, styles2.bold, styles2.header]}>
                                        <Text style={styles2.row4}>Zona</Text>
                                        <Text style={styles2.row4}>Aforo</Text>
                                        <Text style={styles2.row4}>Cortesias</Text>
                                        <Text style={styles2.row2}>Ventas</Text>
                                        <Text style={styles2.row3}>Disponible</Text>
                                    </View>
                                    {data.ticketsByLocation.map((row, i) => (
                                        <View key={i} style={styles2.row} wrap={false}>
                                            <Text style={styles2.row4}>{row.zona}</Text>
                                            <Text style={styles2.row4}>{row.capacity}</Text>
                                            <Text style={styles2.row4}>{row.totalCourtesy}</Text>
                                            <Text style={styles2.row2}><Text style={styles.bold}>{row.total}</Text></Text>
                                            <Text style={styles2.row3}>{row.avairableInZone}</Text>
                                        </View>
                                    ))}
                                </View>

                                {/* 
                                <SubTitle>2.3 CUADRO DE BOLETOS GENERADOS POR CODIGO PROMOCIONAL</SubTitle>

                                <View style={styles.table}>
                                    <View style={[styles.row, styles.bold, styles.header]}>
                                        <Text style={{ width: "50%" }}>Zona</Text>
                                        <Text style={{ width: "50%" }}>Cantidad</Text>
                                    </View>
                                    {
                                        data.ticketsGeneratedByDiscount.map((x, index) => <View key={index} style={styles.row} wrap={false}>
                                            <Text style={{ width: "50%" }}> <Text style={styles.bold}>{x.zone}</Text></Text>
                                            <Text style={{ width: "50%" }}>{x.amount}</Text>
                                        </View>)
                                    }
                                </View> */}


                            </View>

                            <Footer />

                        </Page>

                        {/* 2.4 - 2.5*/}
                        <Page size="A4" style={{ margin: 30, paddingBottom: 30 }}>

                            <View
                                style={{
                                    marginleft: 20,
                                    marginRight: 20,
                                    marginTop: 5,
                                    marginBottom: "-40px",
                                }}
                            >
                                {/* <SubTitle>2.4 MOVIMIENTO DE BOLETOS VENDIDOS POR ORIGEN</SubTitle> */}

                                {/* <Image
                                    src={`${images?.bar}`}
                                    style={{ marginRight: "5px", width: "520px" }}
                                /> */}

                                <SubTitle>2.4 CUADRO DE BOLETOS GENERADOS POR COTESIA</SubTitle>


                                <View style={{ ...styles2.table }}>
                                    <View style={[styles2.row, styles2.bold, styles2.header]}>
                                        <Text style={styles2.row4}>Fecha</Text>
                                        <Text style={styles2.row4}>Razon</Text>
                                        <Text style={styles2.row4}></Text>
                                        <Text style={styles2.row2}>Boletos</Text>
                                        <Text style={styles2.row3}>Zona</Text>
                                    </View>
                                    {data.ticketsByCourtesy.map((row, i) => (
                                        <View key={i} style={styles2.row} wrap={false}>
                                            <Text style={styles2.row4}>{row.date}</Text>
                                            <Text style={styles2.row4}>{row.reason}</Text>
                                            <Text style={styles2.row4}></Text>
                                            <Text style={styles2.row2}>{row.totalTickets}</Text>
                                            <Text style={styles2.row3}>{row.zone}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>

                            <Footer />
                        </Page>


                        {/*3 - 3.1 - 3.2 - 3.3*/}
                        <Page size="A4" style={{ margin: 30, paddingBottom: 30 }}>

                            <Title>3. INGRESOS</Title>
                            <SubTitle>3.1 RESUMEN DE INGRESOS</SubTitle>
                            <View
                                wrap
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginBottom: 5,
                                    height: 50,
                                }}
                            >
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        padding: 2,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        USD
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "18px",
                                            marginTop: 2,
                                        }}
                                    >
                                        {data.incomeIndollars}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        padding: 2,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        BS
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "18px",
                                            marginTop: 2,
                                        }}
                                    >
                                        {data.incomeInBs}
                                    </Text>
                                </View>
                            </View>


                            {/* <SubTitle>3.2 CUADRO DE INGRESOS POR METODO DE PAGO</SubTitle>


                            <View style={{ ...styles2.table }}>
                                <View style={[styles2.row, styles2.bold, styles2.header]}>
                                    <Text style={styles2.row1}>Metodo de pago</Text>

                                    <Text style={styles2.row2}>Dolares</Text>
                                    <Text style={styles2.row3}>Bolivares</Text>
                                </View>
                                {data.incomenByMethodsPayment.map((row, i) => (
                                    <View key={i} style={styles2.row} wrap={false}>
                                        <Text style={styles2.row1}>{row.metodoDePago}</Text>
                                        <Text style={styles2.row2}>{formatNumberMile(row.totalDolares)}</Text>
                                        <Text style={styles2.row3}>{formatNumberMile(row.totalBs)}</Text>
                                    </View>
                                ))}
                            </View> */}

                            {/* <SubTitle>3.3 MOVIMIENTO DE INGRESOS POR METODO DE PAGO</SubTitle> */}


                            {/* <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginTop: 2,
                                }}
                            >
                                <Image
                                    src={`${images?.pie?.frist}`}
                                    style={{ marginRight: "5px", width: "200px" }}
                                />
                                <Image
                                    src={`${images?.pie?.second}`}
                                    style={{ width: "200px" }}
                                />
                            </View> */}

                            <Footer />
                        </Page>

                        {/*4 - 4.1 - 4.2 - 4.3*/}

                        <Page size="A4" style={{ margin: 30, paddingBottom: 30 }}>

                            <Title>4. LIQUIDACIÓN</Title>
                            <SubTitle>4.1 RESUMEN DE LIQUIDACIÓN</SubTitle>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        padding: 0,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "12px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        USD
                                    </Text>
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "15px",
                                            marginBottom: "15px",
                                        }}
                                    >

                                        {data.settledInDollars}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        padding: 0,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "12px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        BS
                                    </Text>
                                    <Text
                                        style={{
                                            marginTop: "10px",
                                            fontSize: "15px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {data.settledInBs}
                                    </Text>
                                </View>
                            </View>

                            <SubTitle>4.2 SALDO PENDIENTE POR LIQUIDAR</SubTitle>

                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        padding: 0,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "12px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        USD
                                    </Text>
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "15px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {data.balanceInDollars}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        border: 1,
                                        backgroundColor: "#f5f5f4",
                                        borderColor: "#dedede",
                                        width: "170px",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        padding: 0,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            marginTop: "15px",
                                            fontSize: "12px",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        BS
                                    </Text>
                                    <Text
                                        style={{
                                            marginTop: "10px",
                                            fontSize: "15px",
                                            marginBottom: "10px",
                                        }}
                                    >

                                        {data.balanceInBs}
                                    </Text>
                                </View>
                            </View>
                            {/* <SubTitle>4.3 CUADRO DE LIQUIDACIÓN POR METODO DE PAGO</SubTitle>


                            <View style={{ ...styles2.table }}>
                                <View style={[styles2.row, styles2.bold, styles2.header]}>
                                    <Text style={styles2.row4}>Metodo de pago</Text>
                                    <Text style={styles2.row4}>Dolares</Text>
                                    <Text style={styles2.row4}>Bolivares</Text>

                                    <Text style={styles2.row2}>Pagados</Text>
                                    <Text style={styles2.row3}>Por Pagar</Text>
                                </View>
                                {data.settlementByPaymentMethod.map((row, i) =>
                                    <View key={i} style={styles2.row} wrap={false}>
                                        <Text style={styles2.row4}>{row.method}</Text>
                                        <Text style={styles2.row4}>{row.dollars}</Text>
                                        <Text style={styles2.row4}>{row.bs}</Text>
                                        <Text style={styles2.row2}>{row.paid}</Text>
                                        <Text style={styles2.row3}>{row.payable}</Text>
                                    </View>
                                )}
                            </View> */}
                            <Footer />
                        </Page>


                        <Page size="A4" style={{ margin: 30, paddingBottom: 30 }}>

                            <SubTitle>4.4 CUADRO DE LIQUIDACIÓNES REALIZADAS EN DOLARES</SubTitle>

                            <View style={styles.table}>
                                <View style={[styles.row, styles.bold, styles.header]}>
                                    <Text style={styles.row2}>Fecha</Text>
                                    <Text style={styles.row3}>Forma de pago</Text>
                                    <Text style={styles.row3}>Concepto</Text>
                                    <Text style={styles.row3}>Receptor</Text>
                                    <Text style={styles.row4}>referencia</Text>
                                    <Text style={styles.row3}>Monto</Text>
                                </View>
                                {data.settlementInDollars.map((row, i) => (
                                    <View key={i} style={styles.row} wrap={false}>
                                        <Text style={styles.row2}>{row.fechaRecibo ? date(formatDate(row.fechaRecibo)) : ""}</Text>
                                        <Text style={styles.row3}>{row.meansOfPayment}</Text>
                                        <Text style={styles.row3}>{row.descripcion}</Text>
                                        <Text style={styles.row3}>
                                            <Text style={styles.bold}>{row.bancoReceptor}</Text>
                                        </Text>
                                        <Text style={styles.row4}>{row.referencia}</Text>
                                        <Text style={styles.row3}>{row.monto}</Text>
                                    </View>
                                ))}
                            </View>
                            <SubTitle> 4.5 CUADRO DE LIQUIDACIÓNES REALIZADAS EN BOLIVARES</SubTitle>


                            <View style={styles.table}>
                                <View style={[styles.row, styles.bold, styles.header]}>
                                    <Text style={styles.row2}>Fecha</Text>
                                    <Text style={styles.row3}>Forma de pago</Text>
                                    <Text style={styles.row3}>Concepto</Text>
                                    <Text style={styles.row3}>Receptor</Text>
                                    <Text style={styles.row4}>referencia</Text>
                                    <Text style={styles.row3}>Monto</Text>
                                </View>
                                {data.settlementInBs.map((row, i) => (
                                    <View key={i} style={styles.row} wrap={false}>
                                        <Text style={styles.row2}>{row.fechaRecibo ? date(formatDate(row.fechaRecibo)) : ""}</Text>
                                        <Text style={styles.row3}>{row.meansOfPayment}</Text>
                                        <Text style={styles.row3}>{row.descripcion}</Text>
                                        <Text style={styles.row3}>
                                            <Text style={styles.bold}>{row.bancoReceptor}</Text>
                                        </Text>
                                        <Text style={styles.row4}>{row.referencia}</Text>
                                        <Text style={styles.row3}>{row.monto}</Text>
                                    </View>
                                ))}
                            </View>
                            <Footer />
                        </Page>
                    </Document>
                }
                fileName="Informe de liquidación.pdf"
            >
                {({ blob, url, loading, error }) =>
                    <span className="p-float-label flex">
                        <Button
                            label="Informe"
                            disabled={loadingReport || errorReport}
                            icon="pi pi-cloud-download"
                            loading={loadingReport}
                        />
                    </span>
                }
            </PDFDownloadLink>
        </>
    )
}

