import React from "react";
import IconBs from "../../../../assets/IconBs";
import useFormatNumber from "../../../../Hooks/useFormatNumber";
import { Button } from "primereact/button";
import useUser from "../../../../Hooks/useUser";

function SalesResumen({ data, request, today,restartData, filterDateByDaysAgo, daysAgo, setDaysAgo, dinamycData, setData, setDateFilter }) {

  const { formatNumberMile } = useFormatNumber()

  const { userData } = useUser()

  const itemsRender = [
    {
      title: "Transacciones",
      data: formatNumberMile(data.operations),
      icon: "pi-ticket",
      isVisible:userData?.email==='lacarlota@gb.com'?true:true 
    },
    {
      title: "Boletos",
      data: formatNumberMile(data.getTotals.totalTickets),
      icon: "pi-ticket",
      installmentTickets: formatNumberMile(data.getTotals.totalUnpaidTickets ? data.getTotals.totalUnpaidTickets : 0),
      isVisible:userData?.email==='lacarlota@gb.com'?true:true
    },
    {
      title: "Dolares",
      data: formatNumberMile(data.getTotals.totalDolaresProductor),
      icon: "pi-dollar",
      info: formatNumberMile(data.getTotals.totalInPendingPayable ? data.getTotals.totalInPendingPayable : 0),
      isVisible:userData?.email==='lacarlota@gb.com'?false:true
    },
    {
      title: "Bolivares",
      data: formatNumberMile(Number(data.getTotals.totalBolivaresProductor)),
      iconComponent: <IconBs className="w-[18px] text-white" fill="#ffffff" />,
      isVisible:userData?.email==='lacarlota@gb.com'?false:true
    },
    {
      title: "COP",
      data: formatNumberMile(Number(data.getTotals.totaCopProductor ? data.getTotals.totaCopProductor : 0)),
      icon: "pi-dollar",
      isVisible:userData?.email==='lacarlota@gb.com'?false:true
    },
  ];



  return (
    <>

      <section className="card col-span-full w-full lg:col-span-2  h-[480px] lg:h-[330px] ">
        <div className="flex gap-x-4">
          <Button
            className={`${daysAgo === "todos" ? "" : "p-button-outlined"}`}
            label="Ultimos 30 dias"
            onClick={() => {
              setDaysAgo("todos")
              setDateFilter(false)
              request({ filters: false, date: false })
            }}
          />
          <Button
            className={` ${daysAgo === 0 ? "" : "p-button-outlined"}`}
            label="Hoy"
            onClick={() => {
              setDaysAgo(0)
              setDateFilter(false)
              today()
            }}
          />
        </div>
        <article className="grid grid-cols-1 h-[400px] lg:grid-cols-2 gap-x-4 p-4 justify-center items-center lg:h-[280px] ">
          {itemsRender?.map((el, index) => {
            return !el.isVisible?null:(
              <div
                className="w-auto card rounded-[10px] rounded-tl-[0px] h-[60px]  bg-[#662d91]/90 relative"
                key={index}
              >
                <div className="flex justify-center items-center  gap-2 font-bold self-stretch relative h-[27px]">
                  <h6 className="font-bold text-[20px]">{el.title}</h6>
                  <p className="text-lg font-bold text-[21px]"> {el.data}</p>
                  {el?.icon && <i className={`pi ${el?.icon} text-[20px]`}></i>}
                  {el?.iconComponent}
                </div>
                {
                  el.info && <div className=" flex justify-between">
                    <span className="flex items-center justify-center gap-3">  <i className={`pi  pi-info-circle text-[13px] text-blue-300  `}></i> <span className="text-[13px] text-gray-500	">Pendiente por cuotas</span></span>
                    <span className="text-[13px] text-gray-500	">{el.info}</span>
                  </div>
                }
                {
                  el.installmentTickets && <div className=" flex justify-between">
                    <span className="flex items-center justify-center gap-3">  <i className={`pi  pi-info-circle text-[13px] text-blue-300  `}></i> <span className="text-[13px] text-gray-500	">Boletos por cuotas</span></span>
                    <span className="text-[13px] text-gray-500	">{el.installmentTickets}</span>
                  </div>
                }
              </div>
            );
          })}
        </article>
      </section>
    </>
  );
}

export default SalesResumen;
