
import React from "react";

import LineGraphics from "./Line";

function Graphic({ data }) {

  return (
    <>
    <LineGraphics data={data}/>
    </>
  );
}

export default Graphic;
