import { Skeleton } from "primereact/skeleton"
import { useState } from "react"
import { useEffect } from "react"
import { fetchInsuranceOperations } from "../../../Firebase/client"
import useUser from "../../../Hooks/useUser"
import tableConfig from "./tableConfig.json"
import { TableXConfig } from "../../../Components/TableX/TableXResumen"
import { methodsInBs, serverBaseUrl } from "../../../repository/config"
import axios from "axios"

export default function InsuranceOperations({ eventId }) {

    const { userData } = useUser()

    const [loading, setLoading] = useState(true)
    const [operations, setOperations] = useState(false)

    const requestOperations = async () => {
        try {
            const url = `${serverBaseUrl}/api/refundProtection/insurance-report`
            const request = await axios.post(url, { eventId: eventId })
            setLoading(false)
            setOperations(request.data.registers)
        } catch (err) {
            console.log(err)
        }
    }

    const filterExportData = (params, datad) => {
        let result = datad?.filter((e) => {
            return (
                String(e?.tipoPago).includes(
                    params.filters?.tipoPago?.value ?? ""
                ) &&
                String(e?.referencia).includes(params.filters?.referencia?.value ?? "") &&
                String(e?.monto).includes(
                    params.filters?.monto?.value ?? ""
                ) &&
                String(e?.descripcion).includes(params.filters?.descripcion?.value ?? "")
            );
        });
        return result;
    }


    useEffect(() => {
        if (userData) requestOperations()
    }, [userData, eventId])

    return (
        <>
            {
                loading ?
                    <Skeleton className=" w-full h-[350px] mt-3 "></Skeleton>
                    : <div className="w-auto grid grid-cols-1">
                        <div className="col-span-full">
                            <TableXConfig
                                noLazy
                                exportable
                                data={operations}
                                autoLayout={true}
                                tableConfig={tableConfig}
                                filterExportData={filterExportData}
                            />
                        </div>
                    </div>
            }
        </>
    )
}