/**
 * este modulo es el modulo principal del step del la optencion de los datos basico dentro lo cual se destaca
 * datos basicos del evento
 * luager del evento si es online si es streaming, etc
 * 
 * 
 */
import { Controller, useForm } from "react-hook-form"
import * as yup from 'yup'
import TextFieldInput from "../../components/inputs/text-field-input/TextFieldInput";
import GBDatePiket from "../../components/inputs/date-piker/GBDatePiket";
import JoditEditor from 'jodit-react';
import React, { useMemo, useRef, useState } from "react"
import useYupValidationResolver from "./YupValiadtionResolvers";
import { Button } from "primereact/button";
import GBLocationPiker from "../../components/inputs/location-picker/GBLocationPiker";
import GBCityPicker from "../../components/inputs/location-picker/GBCityPicker";
import { OverlayPanel } from "primereact/overlaypanel";
import GenerateDescriptionAi from "./GenerateDescriptionAi";
import SelectCountry from "../../../../Components/Forms/SelectCountry";

type PrimeReactSelectValue = {
    code: string
    name: string
}

interface Inputs {
    title?: string
    information?: string
    eventLocation?: any
    direcion?: string
    startOfSale?: Date
    endOfSale?: Date
    date: Date,
    participatingArtists?: string,
    endEventDate: Date
    provinceOrState?: PrimeReactSelectValue
    city?: PrimeReactSelectValue
    country?: any
    place?: string
}

interface SetInputs {
    title?: string
    information?: string
    eventLocation?: any
    direcion?: string
    startOfSale?: Date
    endOfSale?: string,
    participatingArtists?: string,
    date?: Date
    endEventDate?: Date
    provinceOrState?: string
    city?: string
    country?: any
    place?: string

}

const iniTialDate = {
    startOfSale: new Date(),
    endOfSale: new Date(),
    date: new Date(),
    endEventDate: new Date()
}

interface StepBacisInfoProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    setValue: (values: any) => void
    defaultValues: SetInputs

}

const config = {
    readonly: false,
    height: 500,
    color: "#000",
    width: "100%",
    style: {

        color: "#000",
        boreder: "1px solid #000"
    }
}


export default function StepBacisInfo({ setStep, setValue: setData, defaultValues: defaultValuesProps }: StepBacisInfoProps) {

    console.log('VALORES POR DEFECTO', defaultValuesProps)

    const op = useRef<OverlayPanel>(null);

    const validationSchema = useMemo(() => {
        return yup.object({
            city: yup.string().required('Ciudad: Este campo es obligatorio.'),
            place: yup.string().required("Lugar: Este campo es obligatorio."),
            title: yup.string().required("Nombre del evento: Este campo es obligatorio."),
            country: yup.string().required("País: Este campo es obligatorio."),
            information: yup.string().required("Descripción: Este campo es obligatorio."),
            eventLocation: yup.object().required("Ubicación: Este campo es obligatorio."),
            participatingArtists: yup.string().required("Artistas: Este campo es obligatorio."),
            date: yup.date().min(new Date(), 'Fecha inicio: La fecha debe ser mayor que la fecha actual.').required('Fecha inicio: Este campo es obligatorio.'),
            endEventDate: yup.date().required('Fecha fin: Este campo es obligatorio.').min(yup.ref('date'), 'Fecha fin: La fecha de finalización debe ser mayor que la fecha de inicio.')
        })
    }, [])

    const resolver = useYupValidationResolver(validationSchema)

    const { city, provinceOrState, country, eventLocation, ...rest } = defaultValuesProps

    const defaultCities = city ? {
        name: city,
        code: city.replace(/ /g, '-')

    } : undefined

    const defaultProvinceOrState = provinceOrState ? {
        name: provinceOrState,
        code: provinceOrState.replace(/ /g, '-')

    } : undefined


    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm<Inputs>({
        defaultValues: {
            ...rest as Inputs,
            provinceOrState: defaultProvinceOrState,
        },
        resolver
    })


    const [dates, setDates] = useState(iniTialDate)
    const editor = useRef(null)

    const onSubmit = (data: Inputs) => {
        const { provinceOrState, ...resst } = data
        const dataToSet: SetInputs = {
            ...resst as SetInputs,
            provinceOrState: ".",
            direcion: "."
        }
        setData(dataToSet)
        setStep(2)
    }

    const openOverlayPanel = (e) => {
        if (op.current) {
            op.current.toggle(e)
        }
    }


    return (
        <form className="flex flex-col w-[100%] max-w-[800px] p-[15px] gap-3 m-auto" onSubmit={handleSubmit(onSubmit)} >
            <h5>Paso 2</h5>


            <Controller
                name="country"
                control={control}
                render={
                    ({
                        field: { onChange }
                    }) => {
                        return <SelectCountry
                            defaultValue={country}
                            errorMessage={errors.country ? errors.country.message : undefined}
                            setCountry={(value: any) => {
                                onChange(value)
                            }}
                        />
                    }
                }
            />

            <TextFieldInput textlLabel={'Nombre de la experiencia*'}
                id="evnet-name"
                register={register('title')}
                errorMessage={errors.title ? errors.title.message : undefined}
            />

            <TextFieldInput textlLabel={'Artistas participantes'}
                id="participatingArtists"
                placeholder="Escribe aqui cada artista separado por coma (,)"
                register={register('participatingArtists')}
                errorMessage={errors.participatingArtists ? errors.participatingArtists.message : undefined}
            />

            <div >
                <span className="text-gb-gray-800 font-bold">Fecha de inicio de la experiencia*</span>
                <Controller
                    name="date"
                    control={control}
                    render={
                        ({
                            field: { onChange, value }
                        }) => {

                            return <GBDatePiket
                                date={value || dates.date}
                                label="Inicia"
                                errorMessage={errors.date ? errors.date.message : undefined}
                                info="Coloque la fecha y Hora del evento de inicio del evento"
                                setDates={(value: Date) => {
                                    onChange(value)
                                }} />
                        }
                    }
                    defaultValue={new Date()}
                />
            </div>

            <div >
                <span className="text-gb-gray-800 font-bold">Fecha de fin de la experiencia*</span>
                <Controller
                    name="endEventDate"
                    control={control}
                    render={
                        ({
                            field: { onChange, value }
                        }) => {

                            return <GBDatePiket
                                date={value || dates.date}
                                errorMessage={errors.endEventDate ? errors.endEventDate.message : undefined}
                                label="Fin"
                                info="Coloque la fecha y hora del fin del evento"
                                setDates={(value: Date) => {
                                    onChange(value)
                                }} />
                        }
                    }
                    defaultValue={new Date()}
                />
            </div>


            <div className="gb-form-control">
                <div className="flex justify-between">
                    <label className="text-gb-gray-900">Descripción de la experiencia*</label>
                    <span className="cursor-pointer font-bold text-[#662d91]" onClick={(e) => openOverlayPanel(e)}>
                        Generar descripción con ia
                        <i className="pi pi-globe ml-2"></i>
                    </span>
                </div>
                <Controller
                    name="information"
                    control={control}
                    render={({
                        field: { onChange, value }
                    }) => {
                        const handleBlur = (v: string) => {
                            onChange(v)
                        };
                        return <JoditEditor
                            ref={editor}
                            value={value || ''}
                            config={config}
                            onBlur={handleBlur}
                        />
                    }}

                />
                {errors.information && <span className="text-message-error  text-red-600	"> {errors.information.message}</span>}
            </div>

            <GenerateDescriptionAi op={op} setValue={setValue} />

            <div className="grid">
                <label className="text-gb-gray-900" >Ciudad de la experiencia*</label>
                <Controller
                    name="city"
                    control={control}
                    render={
                        ({
                            field: { onChange, value }
                        }) => {

                            return <GBCityPicker onChange={onChange} defaultValue={city} />
                        }
                    }
                />
                {errors.city && <span className="text-message-error  text-red-600"> {errors.city.message}</span>}
            </div>

            <div className="grid">
                <TextFieldInput textlLabel={'¿Lugar de la experiencia? (Brevemente)*'}
                    id="place"
                    register={register('place')}
                    errorMessage={errors.place ? errors.place.message : undefined}
                />

            </div>

            <div className="grid">
                <label className="text-gb-gray-900" >Ubicación de la experiencia*</label>
                <Controller
                    name="eventLocation"
                    control={control}
                    render={
                        ({
                            field: { onChange, value }
                        }) => {

                            return <GBLocationPiker
                                onChange={onChange}
                                errorMessage={errors.eventLocation ? errors.eventLocation.message : undefined}
                                defaultValue={eventLocation}
                            />

                        }
                    }
                />

            </div>

            <Button label="Siguiente" />

            {
                Object.keys(errors).length > 0 && <div>
                    <p>Registro de errores:</p>
                    {
                        Object.keys(errors).map((x, index) => <p key={index}>
                            <span className="text-message-error  text-red-600">{errors[x].message} </span>
                        </p>)
                    }
                </div>
            }

            <h5>Paso 3</h5>
            <ul>
                <li>Configuración de boletos/paquetes</li>
            </ul>

            <h5>Paso 4</h5>
            <ul>
                <li>Agregar imagenes se su experiencia</li>
            </ul>


        </form>
    )
}
