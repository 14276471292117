import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import React from "react";
import { useEffect } from "react";
import Logo from "../../assets/Logo";
import { logout } from "../../Firebase/client";
import useUser from "../../Hooks/useUser";
import ItemNav from "./ItemNav";

function Drawer({ showDrawer, refNav, setShowDrawer }) {

  const { userData } = useUser()

  const itemListNav = [
    {
      title: "Resumen",
      route: "/Resume",
      id: "Resumen",
      visible: userData.email === "pimpinela@gb.com" ? false : userData.rol === "promotor" ? true : false,
      icon: "pi-book",
    },
    {
      title: "Eventos",
      route: userData.rol === "promotor" ? "/Home" : userData.rol === "delivery" ? "/Delivery" : "/Insurance",
      id: "Inicio",
      visible: true,
      icon: "pi-building",
    },
    // {
    //   title: "Liquidaciones",
    //   route: "/Liquidations",
    //   id: "Liquidaciones",
    //   visible: true,
    //   icon: "pi-book",
    // },
    // {
    //   title: "Cortesias",
    //   route: "/Courtesies",
    //   id: "Courtesies",
    //   visible: true,
    //   icon: "pi-id-card",
    // },
    // {
    //   title: "Disputas",
    //   route: "/bill-disputes",
    //   id: "Disputas",
    //   visible: true,
    //   icon: "pi-hashtag",
    // },
    {
      title: "Configuración",
      route: "/Configuration",
      id: "Configuración",
      visible: userData.rol === "promotor" ? true : false,
      icon: "pi-cog",
    },
    {
      title: "Salir",
      id: "Salir",
      route: "/",
      action: logout,
      visible: false,
      icon: "pi-share-alt",
    },

  ];

  return (
    <div className="h-full  lg:w-[50px] z-[1007] ">
      <nav className={`h-full bg-[#1e1e1e] fixed  ${showDrawer ? "translate-x-0" : "-translate-x-[100%]"
        } lg:translate-x-0  transition-all  shadow-white w-[150px] lg:w-[50px] scroll-m-7 overflow-x-hidden 	 overflow-y-auto flex flex-col   items-center`}
        ref={refNav}
      >

        <Logo width="30px" heigth="50px" mini className="mt-2 mb-3" />
        <div className="flex  lg:hidden items-center justify-center my-3 w-full">
          <Avatar label="G" className="mr-2" size="small" shape="circle" />

          <p className="text-xs mt-1 capitalize overflow-hidden">{userData.rol === "promotor" ? "PRODUCTOR" : ""} </p>
          <Button
            icon="pi pi-sign-out "
            onClick={() => logout()}
            className=" h-[30px] p-button-text p-button-plain lg:hidden block text-white"
          />
        </div>

        {userData && <ItemNav setShowDrawer={setShowDrawer} items={itemListNav} />}
      </nav>
    </div>
  );
}

export default Drawer;
