import { InputText } from "primereact/inputtext"
import { binanceImg, cuentaInternacional, cuentaNacional, metamaskImg, mobilePaymentsImg, reserveImg, zelleImg, zinliImg } from "../../../assets/methodsPayment"
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";


export const platformRequirements = {
    zelle: {
        name: "Zelle",
        corrency: "$",
        requirements: [{ id: "correo", name: "Correo asociado" }, { id: "titular", name: "Titular de la cuenta" }],
        src: zelleImg
    },
    zinli: {
        name: "Zinli",
        corrency: "$",
        requirements: [{ id: "correo", name: "Correo asociado" }, { id: "titular", name: "Titular de la cuenta" }],
        src: zinliImg
    },
    reserve: {
        name: "Reserve",
        corrency: "$",

        requirements: [{ id: "correo", name: "Correo asociado" }],
        src: reserveImg
    },
    binance: {
        name: "Binance",
        corrency: "$",

        requirements: [{ id: "payId", name: "Pay id" }],
        src: binanceImg
    },
    cuentaInternacional: {
        name: "C.Internacional",
        corrency: "$",
        requirements: [{ id: "banco", name: "Banco" }, { id: "numeroCuenta", name: "Numero de cuenta" }, { id: "titular", name: "Titular de la cuenta" }, { id: "RIF", name: "Rif" }],
        src: cuentaInternacional
    },
    trc20: {
        name: "TRC20",
        requirements: [{ id: "trc20", name: "Billetera trc20" }],
        corrency: "$",
        src: metamaskImg
    },
    pagoMovil: {
        name: "Pago movil",
        requirements: [{ id: "telefono", name: "Telefono" }, { id: "banco", name: "Banco" }, { id: "RIF", name: "RIF" }],
        src: mobilePaymentsImg,
        corrency: "bs",
    },
    cuentaNacional: {
        name: "C.Nacional",
        corrency: "bs",
        requirements: [{ id: "banco", name: "Banco" }, { id: "numeroCuenta", name: "Numero de cuenta" }, { id: "titular", name: "Titular de la cuenta" }, { id: "RIF", name: "Rif" }],
        src: cuentaNacional
    },

}

export default function CompletePlatformData({ platform, handleOnChange }) {


    const banksAvailable = [
        { name: "100%BANCO", code: "100%BANCO" },
        { name: "ABN AMRO BANK", code: "ABN AMRO BANK" },
        { name: "BANCAMIGA BANCO MICROFINANCIERO, C.A.", code: "BANCAMIGA BANCO MICROFINANCIERO, C.A." },
        { name: "BANCO AGRICOLA", code: "BANCO AGRICOLA" },
        { name: "BANCO BICENTENARIO", code: "BANCO BICENTENARIO" },
        { name: "BANCO CARONI, C.A. BANCO UNIVERSAL", code: "BANCO CARONI, C.A. BANCO UNIVERSAL" },
        { name: "BANCO ACTIVO BANCO COMERCIAL, C.A.", code: "BANCO ACTIVO BANCO COMERCIAL, C.A." },
        { name: "BANCO DE DESARROLLO DEL MICROEMPRESARIO", code: "BANCO DE DESARROLLO DEL MICROEMPRESARIO" },
        { name: "BANCO DE VENEZUELA S.A.I.C.A.", code: "BANCO DE VENEZUELA S.A.I.C.A." },
        { name: "BANCO DEL CARIBE C.A.", code: "BANCO DEL CARIBE C.A." },
        { name: "BANCO DEL PUEBLO SOBERANO C.A.", code: "BANCO DEL PUEBLO SOBERANO C.A." },
        { name: "BANCO DEL TESORO", code: "BANCO DEL TESORO" },
        { name: "BANCO EXTERIOR C.A.", code: "BANCO EXTERIOR C.A." },
        { name: "BANCO INTERNACIONAL DE DESARROLLO, C.A.", code: "BANCO INTERNACIONAL DE DESARROLLO, C.A." },
        { name: "BANCO MERCANTIL C.A.", code: "BANCO MERCANTIL C.A." },
        { name: "BANCO NACIONAL DE CREDITO", code: "BANCO NACIONAL DE CREDITO" },
        { name: "BANCO OCCIDENTAL DE DESCUENTO.", code: "BANCO OCCIDENTAL DE DESCUENTO." },
        { name: "BANCO ESPIRITO SANTO, S.A.", code: "BANCO ESPIRITO SANTO, S.A." },
        { name: "BANCO PLAZA", code: "BANCO PLAZA" },
        { name: "BANCO PROVINCIAL BBVA", code: "BANCO PROVINCIAL BBVA" },
        { name: "BANCO VENEZOLANO DE CREDITO S.A.", code: "BANCO VENEZOLANO DE CREDITO S.A." },
        { name: "BANCRECER S.A. BANCO DE DESARROLLO", code: "BANCRECER S.A. BANCO DE DESARROLLO" },
        { name: "BANESCO BANCO UNIVERSAL", code: "BANESCO BANCO UNIVERSAL" },
        { name: "BANFANB", code: "BANFANB" },
        { name: "BANGENTE", code: "BANGENTE" },
        { name: "BANPLUS BANCO COMERCIAL C.A", code: "BANPLUS BANCO COMERCIAL C.A" },
        { name: "CITIBANK", code: "CITIBANK" },
        { name: "DELSUR BANCO UNIVERSAL", code: "DELSUR BANCO UNIVERSAL" },
        { name: "CORP BANCA.", code: "CORP BANCA." },
        { name: "FONDO COMUN", code: "FONDO COMUN" },
        { name: "INSTITUTO MUNICIPAL DE CR&#201;DITO POPULAR", code: "INSTITUTO MUNICIPAL DE CR&#201;DITO POPULAR" },
        { name: "MIBANCO BANCO DE DESARROLLO, C.A.", code: "MIBANCO BANCO DE DESARROLLO, C.A." },
        { name: "SOFITASA", code: "SOFITASA" },
    ]

    const [selectedBank, setSelectedBank] = useState("")

    const onInputChangeBank = (e) => {
        setSelectedBank(e.target.value)
        handleOnChange("banco", e.target.value)
    }

    return (
        <div className="flex flex-col min-h-[56vh] justify-center">

            {platform && platformRequirements[platform] && <>
                <div>
                    <img className="w-[300px] h-[130px] m-auto object-contain" src={platformRequirements[platform].src}></img>
                </div>
                {platformRequirements[platform].requirements.map((x, index) => <div key={index}>
                    <label htmlFor={x.id} className="block font-bold">
                        {x.name}
                    </label>

                    {
                        platform === "pagoMovil" && x.id === "banco" ? <>
                            <Dropdown
                                id="meansOfPayment"
                                value={selectedBank}
                                onChange={(e) => onInputChangeBank(e)}
                                optionLabel="name"
                                optionValue="code"
                                className="w-full mb-2"
                                options={banksAvailable}
                                required
                                autoFocus
                            />
                        </> : <>
                            {
                                platform === "pagoMovil" && x.id === "banco" ? <>
                                    <Dropdown
                                        id="meansOfPayment"
                                        value={selectedBank}
                                        onChange={(e) => onInputChangeBank(e)}
                                        optionLabel="name"
                                        optionValue="code"
                                        className="w-full mb-2"
                                        options={banksAvailable}
                                        required
                                        autoFocus
                                    />
                                </> : <>
                                    <InputText type="text" name={x.id} className="block mb-2 w-full" placeholder="" onChange={(e) => handleOnChange(e.target.name, e.target.value)} />
                                </>
                            }
                        </>
                    }


                </div>)}
            </>}
        </div>
    )
}