import axios from "axios";
import { useEffect, useState } from "react";


export default function useDesign({ eventId, inititalPositions }) {

    const [positions, setPositions] = useState(inititalPositions ? inititalPositions : [])
    const [loading, setLoading] = useState(false)
    const [startingChanges, setStartingChanges] = useState(false)
    const [selectedId, selectShape] = useState(null);
    const [currentPositions, setCurrentPositions] = useState([])

    const onChange = (newAttrs) => {
        const { id } = newAttrs
        setStartingChanges(true)
        if (positions.some(x => x.id === id)) {
            setPositions((prev) => prev.map((y) => y.id === id ? { ...y, ...newAttrs } : y))
        } else {
            setPositions((prev) => prev.concat({ ...newAttrs }))
        }
    }

    const cleanChanges = () => {
        if (currentPositions.length > 0) {
            setPositions(currentPositions)
        } else {
            setPositions(inititalPositions)
        }
        setStartingChanges(false)
    }

    const save = async ({ structure }) => {
        try {
            setLoading(true)
            const url = 'https://global-boletos-main-ts-server-2cmhx3gilq-uc.a.run.app/api/v2/positions/savePositions'
            const response = await axios.post(url, { userId: "1", positions: positions, eventId: eventId, structure: structure ? structure : 'flat' })
            console.log(response.data)
            setLoading(false)
            setStartingChanges(false)
            setCurrentPositions(positions)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const position = ({ parameter, id, defaultPosition }) => {
        if (!positions.some(x => x.id === id)) return defaultPosition
        const positionParameters = positions.find(x => x.id === id)
        const query = positionParameters[parameter]
        if (!query) return defaultPosition
        return query
    }


    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    }

    useEffect(() => {
        setPositions(inititalPositions)
    }, [inititalPositions])


    return { positions, onChange, startingChanges, cleanChanges, save, position, designLoading: loading, checkDeselect, selectedId, selectShape }
}