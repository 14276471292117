import { useState } from "react"
import { Tag } from "primereact/tag"
import { Skeleton } from "primereact/skeleton"

export default function Event({ onSelectEvent, el, sizes }) {

    const [uploadedImage, setUploadedImage] = useState(false)

    return (
        <div
            className={`relative group transition-all duration-200 cursor-pointer      `}
            onClick={() => onSelectEvent(el.id)}
        >
            <div
                className={`overflow-hidden bg-transparent flex transition-all text-transparent group-hover:text-white rounded-[5px] flex-col  group-hover:bg-black/80 z-20 absolute inset-0 justify-center   items-center text-center px-2   ${sizes.width.class}  ${sizes.heigth.class}`}
            >
                <h6 className="font-bold  translate-y-12 transition-all duration-200 group-hover:text-white text-transparent group-hover:translate-y-0">
                    {el.title}
                </h6>
            </div>
            <div className="absolute right-[0px] 	m-1">
                <Tag severity={`${el.notForSale ? "danger" : "success"}  `} value={`${el.notForSale ? "Inactivo" : "Activo"}  `} className="w-[90px]"></Tag>
            </div>
            <div className="absolute right-[0px] bottom-0	m-1">
                <Tag severity={`${el.validated ? "success" : "danger"}  `} value={`${el.validated ? "Validado" : "No validado"}  `} className="w-[90px]"></Tag>
            </div>
            <img
                width={sizes.size}
                height={sizes.size}
                className={`  ${sizes.heigth.class} z-10  transition-all duration-500 rounded-[5px] object-cover `}
                src={`${el.img}`}
                alt={el.id}
                style={uploadedImage ? { display: 'initial' } : { display: 'none' }}
                onLoad={() => setUploadedImage(true)}
            />
            {
                !uploadedImage && <Skeleton
                    width={sizes.size}
                    height={sizes.size}
                    className="rounded-[5px]"
                />
            }

        </div>
    )
}