import Autocomplete from "react-google-autocomplete";

function GBCityPicker({ onChange, defaultValue }) {

    const handleInputChange = (x) => {
        console.log(x)
        onChange(x)
    }

    return (
        <Autocomplete
            aria-label="Ciudad"
            defaultValue={defaultValue ? defaultValue : ""}
            aria-labelledby="Ciudad"
            placeholder=""
            apiKey={`AIzaSyD2i9TPQyaoTGcql2ylRhNlbMtYM-FepmA`}
            style={{
                background: 'transparent',
                border: ` 1px solid #404040`,
                borderRadius: '4px',
                height: '50px',
                outline: 'none',
                padding: '10px',
            }}
            onPlaceSelected={(place) => {
                handleInputChange(place.formatted_address)
            }}
        />
    );
}

export default GBCityPicker
