import React from "react";


function TemplateSalesToEvent({ event, aforo, length }) {
  return (
    <div className="flex justify-between  text-center items-center ">
      <p className="w-[35%] lg:text-md text-sm">{event}</p>

      {aforo ? (
        <div className="w-[50%]   rounded-xl h-5  bg-[#9fa8da52] relative overflow-hidden text-center">
          <span
            className={`h-full bg-[#9c68c2] absolute inset-0  rounded-xl  `}
            style={{ width: `${Number((length / aforo) * 100).toFixed(2)}%` }}
          >
            {" "}
          </span>
          <p className="absolute w-full h-full ">
            {Number((length / aforo) * 100).toFixed(2)}%
          </p>
        </div>
      ) : (
        <p className="text-gray-400">Sin Aforo</p>
      )}
      <p className="w-[5%] text-gray-200">{isNaN(length)?'N/A':length}</p>
    </div>
  );
}

export default TemplateSalesToEvent;
