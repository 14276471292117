import {
    Page,
    Text,
    Image,
    View,
    Document,
    PDFDownloadLink,
} from "@react-pdf/renderer"
import { Button } from "primereact/button"
import { PDFViewer } from '@react-pdf/renderer'

const Title = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "14px",
                width: "100%",
                textAlign: "center",
                fontWeight: "700",
                marginBottom: "30px",
            }}
        >
            {children}
        </Text>
    )
}
const SubTitle = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "11px",
                fontWeight: "900",
                marginBottom: "25px",
            }}
        >
            {children}
        </Text>
    )
}
const Header = ({ date, id }) => {

    const opcionesFormato = {
        timeZone: 'America/Caracas',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }

    return (
        <View
            style={{ position: "absolute", top: 35, left: 5 }}
        // fixed
        >
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Image
                    src="/assets/image/global-banner.png"
                    style={{ width: "130px" }}
                />
            </View>

            <Text
                style={{
                    fontSize: "11px",
                    marginTop: "20px",
                }}
            >
                {date.toLocaleDateString("es-ES", opcionesFormato)}
            </Text>

            <Text
                style={{
                    fontSize: "11px",
                    marginTop: "22px",
                }}
            >
                N.CIERRE {id}
            </Text>
        </View>
    )

}
const Footer = ({ date }) => {
    return (
        <View
            style={{ position: "absolute", bottom: 35, right: 60 }}
        // fixed
        >
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {/* <Image
                    src="/assets/image/global-banner.png"
                    style={{ width: "130px" }}
                />
                <Text
                    style={{
                        fontSize: "10px",
                        marginTop: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                >
                    TODOS LOS DERECHOS RESERVADOS POR GLOBALBOLETOS
                </Text> */}

                <Text
                    style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        marginTop: "10px",
                        marginRight: "5px",
                    }}
                >
                    {date.toLocaleDateString()}
                </Text>
                <Text
                    style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginTop: "10px",
                    }}
                    render={({ pageNumber, totalPages }) => `PG ${pageNumber}`}
                ></Text>
            </View>
        </View>
    )

}
export default function ClosingReceipt({ data }) {

    const { addres, createdAt, income, tickets, returnAndInsurance, id, paymentOfFeesReceived, refUsed, ratesUsed, impressions } = data

    function formatText(texto) {



        if (typeof texto !== 'string') {
            return texto
        }

        // Convertir el texto a mayúsculas
        let textoFormateado = texto.toUpperCase();

        // Reemplazar la primera letra de cada palabra por esa misma letra en minúsculas
        textoFormateado = textoFormateado.replace(/\b\w/g, (match) => match.toLowerCase());

        // Convertir la primera letra de cada palabra a mayúscula
        textoFormateado = textoFormateado.replace(/\b\w/g, (match) => match.toUpperCase());

        return textoFormateado.toUpperCase()
    }

    function toFixed(num, fixed) {
        if (isNaN(num)) {
        } else {
            var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed ? fixed : 2 || -1) + "})?");
            return num?.toString().match(re)[0];
        }
    }

    const insuranceByMethod = ({ method, data }) => {
        const registers = data.filter(x => x.metodoDePago === method)
        const total = Object.values(registers).reduce(
            (acc, { value }) => acc + parseFloat(value), 0
        )
        return total
    }

    function obtenerUltimosCuatroCaracteres(cadena) {
        if (cadena.length >= 4) {
            return cadena.slice(-4);
        } else {
            return cadena;  // Devuelve la cadena completa si es más corta que 4 caracteres
        }
    }

    const MyDocument = () => {
        return (
            <Document >
                <Page size="A4" style={{ margin: 30, paddingBottom: 30, paddingRight: 30, marginBottom: 200 }}
                >
                    <Header date={createdAt} id={id} />
                    <View
                        style={{
                            width: 200,
                            height: 100,
                        }}
                    ></View>
                    <View
                        style={{
                            marginTop: 30,
                            marginleft: 30,
                            marginRight: 30,
                            paddingBottom: 50
                        }}
                    >
                        <Title>CIERRE DE TAQUILLA </Title>
                        <SubTitle>- DATOS GENERALES: </SubTitle>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: "11px",
                                    display: "flex",
                                    width: "27%",
                                }}
                            >UBICACION DE TAQUILLA:</Text>
                            <Text
                                style={{
                                    fontSize: "11px",
                                    display: "flex",
                                    width: "71%",
                                    borderBottom: "1px solid #000"

                                }}
                            >{String(addres ? addres : "").toUpperCase()}</Text>
                        </View>

                        <SubTitle>1 - INGRESO POR METODOS DE PAGOS: (LAS CANTIDADES SON EN RELACION A LA MODENA)</SubTitle>
                        <View style={{ width: "100%", marginBottom: "10px" }}>
                            <View style={{
                                width: "100%", display: "flex", flexDirection: "row",
                                border: "1px solid #EEE",
                                paddingTop: 3,
                                paddingBottom: 3,
                            }}>
                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>METODO</Text>
                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>INGRESO</Text>
                            </View>
                            {
                                Object.keys(income).map((x, index) => <View key={index} style={{
                                    width: "100%", display: "flex", flexDirection: "row",
                                    border: "1px solid #EEE",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                }}>
                                    <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{formatText(x)}</Text>
                                    <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{income[x]}</Text>
                                </View>)
                            }
                        </View>

                        <SubTitle>2 - REGISTRO DE ENTRADAS VENDIDAS:</SubTitle>

                        <View style={{ width: "100%", marginBottom: "10px" }}>
                            <View style={{
                                width: "100%", display: "flex", flexDirection: "row",
                                border: "1px solid #EEE",
                                paddingTop: 3,
                                paddingBottom: 3,
                            }}>
                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>LOCALIDAD</Text>
                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>CANTIDAD</Text>
                            </View>
                            {
                                tickets.keys.map((x, index) => <View key={index} style={{
                                    width: "100%", display: "flex", flexDirection: "row",
                                    border: "1px solid #EEE",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                }}>
                                    <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{formatText(x)}</Text>
                                    <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{tickets.ticketsByZoneObject[x]}</Text>
                                </View>)
                            }
                        </View>




                        <SubTitle>3 - REGISTROS DE IMPRESIONES SOLICITADAS: (ORDENES DE IMPRESION ENVIADAS) </SubTitle>
                        <View
                            style={{
                                width: "100%", display: "flex", flexDirection: "row",
                                border: "1px solid #EEE",
                                paddingTop: 3,
                                paddingBottom: 3,
                            }}
                        >
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>TOTAL DE ORDENES</Text>
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{impressions}</Text>
                        </View>



                        <SubTitle>4 - REGISTRO DE VUELTOS REALIZADOS: (LOS VUELTOS EN PAGO SALEN DE CUENTAS EXTERNA A LA CAJA)</SubTitle>
                        <View style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            border: "1px solid #EEE",
                            paddingTop: 3,
                            paddingBottom: 3,
                        }}>
                            <Text style={{ width: "25%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>FORMA DE INGRESO</Text>
                            <Text style={{ width: "20%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>INGRESO</Text>
                            <Text style={{ width: "25%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>FORMA DE RETORNO</Text>
                            <Text style={{ width: "20%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>RETORNO</Text>
                            <Text style={{ width: "10%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>REF</Text>

                        </View>
                        {
                            returnAndInsurance && <>
                                {
                                    returnAndInsurance.totalInReturns && <>
                                        {
                                            returnAndInsurance.totalInReturns.map((x, index) => <View key={index} style={{
                                                width: "100%", display: "flex", flexDirection: "row",
                                                border: "1px solid #EEE",
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                            }}>
                                                <Text style={{ width: "25%", textAlign: "center", fontSize: "11px", }}>{formatText(x.entryMethod ? x.entryMethod : "")}</Text>
                                                <Text style={{ width: "25%", textAlign: "center", fontSize: "11px", }}>{formatText(x.incomeAmount ? x.incomeAmount : "")}</Text>
                                                <Text style={{ width: "20%", textAlign: "center", fontSize: "11px", }}>{formatText(x.metodoDePago ? x.metodoDePago : "")}</Text>
                                                <Text style={{ width: "20%", textAlign: "center", fontSize: "11px", }}>{toFixed(x.value, 2)}</Text>
                                                <Text style={{ width: "10%", textAlign: "center", fontSize: "11px", }}>{formatText(x.ref)}</Text>

                                            </View>)
                                        }
                                    </>
                                }
                            </>
                        }

                        <SubTitle>5 - REGISTRO DE REFERENCIAS UTILIZADAS: (REFERENCIAS USADAS PARA LOS PAGOS)</SubTitle>
                        <View style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            border: "1px solid #EEE",
                            paddingTop: 3,
                            paddingBottom: 3,
                        }}>
                            <Text style={{ width: "40%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>METODO</Text>
                            <Text style={{ width: "30%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>CANTIDAD</Text>
                            <Text style={{ width: "30%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>REF</Text>
                        </View>
                        {
                            refUsed && <>
                                {
                                    refUsed.length > 0 && <>
                                        {
                                            refUsed.map((x, index) => <View key={index} style={{
                                                width: "100%", display: "flex", flexDirection: "row",
                                                border: "1px solid #EEE",
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                            }}>
                                                <Text style={{ width: "40%", textAlign: "center", fontSize: "11px", }}>{formatText(x.method ? x.method : "")}</Text>
                                                <Text style={{ width: "30%", textAlign: "center", fontSize: "11px", }}>{x.amount ? x.amount : ""}</Text>
                                                <Text style={{ width: "30%", textAlign: "center", fontSize: "11px", }}>{x.ref ? x.ref : ""}</Text>
                                            </View>)
                                        }
                                    </>
                                }
                            </>
                        }

                        <SubTitle>6 - REGISTROS DE TASAS EN USD UTILIZADAS: </SubTitle>

                        {
                            ratesUsed && <>
                                {
                                    ratesUsed.length > 0 && <>
                                        {
                                            ratesUsed.map((x, index) => <View key={index} style={{
                                                width: "100%", display: "flex", flexDirection: "row",
                                                border: "1px solid #EEE",
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                            }}>
                                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>TASA</Text>
                                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{x}</Text>
                                            </View>)
                                        }
                                    </>
                                }
                            </>
                        }


                        <SubTitle>7 - REGISTRO DE SEGUROS VENDIDOS: </SubTitle>
                        <View style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            border: "1px solid #EEE",
                            paddingTop: 3,
                            paddingBottom: 3,
                        }}>
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>METODO</Text>
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>INGRESO</Text>
                        </View>
                        {
                            returnAndInsurance && <>
                                {
                                    returnAndInsurance.totalsRefundProtecion && <>
                                        <View style={{
                                            width: "100%", display: "flex", flexDirection: "row",
                                            border: "1px solid #EEE",
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}>
                                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>DOLARES</Text>
                                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{insuranceByMethod({ method: "dolares", data: returnAndInsurance.totalsRefundProtecion })}</Text>
                                        </View>
                                        <View style={{
                                            width: "100%", display: "flex", flexDirection: "row",
                                            border: "1px solid #EEE",
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}>
                                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>BOLIVARES</Text>
                                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{insuranceByMethod({ method: "bs", data: returnAndInsurance.totalsRefundProtecion })}</Text>
                                        </View>
                                    </>
                                }
                            </>
                        }
                        <SubTitle>8 - REGISTRO DE PAGO DE CUOTAS RECIBIDOS: </SubTitle>
                        <View style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            border: "1px solid #EEE",
                            paddingTop: 3,
                            paddingBottom: 3,
                        }}>
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>FACTURA</Text>
                            <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", fontWeight: "bold" }}>CANTIDAD</Text>
                        </View>
                        {
                            paymentOfFeesReceived && <>
                                {
                                    paymentOfFeesReceived.installmentsReceived && <>
                                        {
                                            paymentOfFeesReceived.installmentsReceived.map((x, index) => <View key={index} style={{
                                                width: "100%", display: "flex", flexDirection: "row",
                                                border: "1px solid #EEE",
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                            }}>
                                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{obtenerUltimosCuatroCaracteres(x.invoiceHash)}</Text>
                                                <Text style={{ width: "50%", textAlign: "center", fontSize: "11px", }}>{toFixed(x.total, 2)}</Text>
                                            </View>)
                                        }
                                    </>
                                }
                            </>
                        }



                        <SubTitle>- OBSERVACIONES: </SubTitle>

                    </View>
                    <Footer date={createdAt} />
                </Page>
            </Document>
        )
    }

    return (

        <div className="w-full h-full">


            <div className="sm:hidden w-full h-full grid items-center justify-center"> {/* Ocultar en pantallas de escritorio */}
                <PDFDownloadLink
                    document={
                        <MyDocument></MyDocument>
                    }
                    fileName={`CIERRE DE TAQUILLA ${id}.pdf`}
                >
                    {({ blob, url, loading, error }) =>
                        <span className="p-float-label flex">
                            <Button
                                label="DESCARGAR CIERRE DE TAQUILLA"
                                icon="pi pi-cloud-download"
                            />
                        </span>
                    }
                </PDFDownloadLink>
            </div>
            <div className="hidden md:block w-full h-full"> {/* Ocultar en pantallas de celulares */}
                <PDFViewer className="w-full h-full">
                    <MyDocument></MyDocument>
                </PDFViewer>
            </div>
        </div>

    )
}
