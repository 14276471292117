import { Button } from "primereact/button";

export default function SuccessRegister({ setStep, STEPS }) {

    return (
        <div>
            <i className="pi pi-check-circle text-green-500" style={{ fontSize: "3rem" }} ></i>
            <h5 className="font-bold">Gracias por tu registro</h5>
            <div className="grid gap-3 mt-4">
                <span className="text-gray-600">Te hemos enviado un email de confirmación</span>
                <span className="text-gray-600">Verifica tu dirección de correo electrónico para completar tu registro</span>
            </div>
            <h6 className="font-bold">¿No recibiste el correo?</h6>
            <span className="text-gray-600">Revisa tu bandeja de no deseados/spam</span>
            <div className="grid mt-4">
                <Button className="w-full" label="Aceptar" onClick={()=>setStep(STEPS.START)} />
            </div>
        </div>
    )
}