import { Message } from "primereact/message";
import BasicContract from "./BasicContract";


export default function Resources({ event }) {

    if (event.admin) return (
        <div className="w-full card  flex flex-col lg:flex-row gap-2 mb-5 items-center">
            <div className="field flex gap-3 justify-between w-full">
                <div className="field flex gap-3 ">
                    <span className="p-float-label flex">
                        <BasicContract event={event} />
                    </span>
                </div>
            </div>
        </div>
    )
    return (<>
        <Message severity="warn" text="Sin recursos por mostrar" className='w-full mt-1 mb-3' />
    </>)
}