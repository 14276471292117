
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import useStorage from '../../../Hooks/useStorage';

export const FileUploadImg = ({ setImg, title, route }) => {
    const toast = useRef(null);

    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }

    const { setStatus, loading, COMPOSE_STATES, uploadImg, setLoading } = useStorage()

    const [imgLocal, setImgLocal] = useState(false)

    const handleChangeInputFile = async (event) => {
        try {
            setLoading(COMPOSE_STATES.LOADING)
            const task = await uploadImg(event.files[0], route)
            setImg(task)
            // setImgLocal(task)
            setStatus(COMPOSE_STATES)
            setLoading(COMPOSE_STATES.USER_NOT_KNOW)
        } catch (err) {
            console.log(err)
            setStatus(COMPOSE_STATES)
            setLoading(COMPOSE_STATES.USER_NOT_KNOW)
        }

    }

    if (loading === 1) return <div className="w-full h-full flex align-center justify-center"> <ProgressSpinner /></div>

    return (
        <div>
            <Toast ref={toast}></Toast>
            {imgLocal && <>
                <img className="w-full" src={imgLocal} />
            </>}

            {!imgLocal && <>
                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                <div className="card mt-5">
                    <FileUpload
                        name="demo[]"
                        uploadHandler={handleChangeInputFile}
                        onUpload={onUpload}
                        multiple
                        accept="image/*"
                        customUpload
                        maxFileSize={1000000}
                        emptyTemplate={<p className="m-0">Arrastra y suelta tu imagen aca</p>} />
                </div>
            </>}
        </div>
    )
}
