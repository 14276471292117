

export const formatDate = (item) => {
  let now = new Date((item?.seconds + item?.nanoseconds * 10 ** -9) * 1000)
    .toLocaleDateString()

  return now;
}; 


function useDateFormat() {
  const formatDate = (item) => {
    let now = new Date((item?.seconds + item?.nanoseconds * 10 ** -9) * 1000)
      .toLocaleDateString()

    return now;
  }; 

  const formatDateWithouTolocate = (item) => {
    return new Date((item?.seconds + item?.nanoseconds * 10 ** -9) * 1000);
  };
  return {formatDate, formatDateWithouTolocate};
}

export default useDateFormat;
