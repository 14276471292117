import { Skeleton } from "primereact/skeleton"
import { useState } from "react"
import { images } from "../../../../repository/config"

interface Props {
    setStep: Function
    setValue: Function
    paymentPlan: number
    defaultValues?: string
}

interface PropsEventType {
    setNexValue: any
    x: any
}

const EventType = (props: PropsEventType) => {

    const { setNexValue, x } = props

    const [uploadedImage, setUploadedImage] = useState(false)

    return <div
        className="bg-[#131517] cursor-pointer h-[50px] rounded flex justify-between p-3 items-center justify-center hover:bg-[#ffffff0a] ease-in duration-100	"
        onClick={() => setNexValue(x.value)}
    >
        <i className={`${x.icon}`}></i>
        {/* <img className={`p-3 w-[210px] h-[120px] object-cover m-auto `} src={x.icon} style={uploadedImage ? { display: "initial" } : { display: "none" }}
            onLoad={() => setUploadedImage(true)}
        ></img>
        {!uploadedImage && <Skeleton height="120px" width="90%" className={` w-[210px] object-cover m-auto mt-2`} />} */}

        <span className="text-sm text-center flex justify-center">{x.type}</span>
    </div>
}

export default function SelectEventType(props: Props) {

    const { setStep, setValue, defaultValues, paymentPlan } = props

    const categories = [
        { value: 'musica', type: "Música", icon: "pi pi-star-fill", view: true, },
        { value: "artes", type: "Artes", icon: "pi pi-users", view: true, },
        { value: "dia-festivo", type: "Dia festivo", icon: "pi pi-palette", view: true, },
        { value: "pasatiempo", type: "Pasatiempo", icon: "pi pi-box", view: true, },
        { value: "negocio", type: "Conferencias", icon: "pi pi-desktop", view: true, },
        { value: "comida-bebidas", type: "Gastronomía", icon: "pi pi-table", view: true, },
        { value: 'turismo', type: "Turismo", icon: "pi pi-palette", view: paymentPlan === 1 ? false : true, }, 
        // Puedes agregar más objetos aquí con las propiedades deseadas
    ];

    const setNexValue = (x) => {
        setValue(x)
        setStep(1)
    }

    return (
        <div>
            <h3>Publica tu experiencia</h3>
            <h5>Paso 1. Selecciona el tipo de experiencia</h5>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-4 ">
                {categories.filter(x => x.view === true).map((x, index) => {
                    return <EventType key={index} x={x} setNexValue={setNexValue} />
                })}
            </div>
        </div>
    )
}



