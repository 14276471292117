import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import "./tooltip.css";


function ItemNav({ items, setShowDrawer }) {
  const pathName = useLocation().pathname;

  return items?.map((el, key) => {
    if (!el.visible) return <React.Fragment key={key}></React.Fragment>
    return (
      <React.Fragment key={key}>
        <Tooltip target={`.${el.id}`} className=" hidden lg:block " />
        <Link
          to={el.route}
          className={`${el.id}
          
          ${String(pathName).includes(el.route)
              ? "bg-[#662d91] hover:bg-[#662d91]/80 px-5 text-white font-bold "
              : "hover:bg-[#662d91] hover:px-5 text-gray-300   hover:text-white hover:font-bold"}
          
          w-full p-3 cursor-pointer font-semibold  text-xs flex gap-4 transition-all duration-200  `}
          data-pr-tooltip={`${el.title}`}
          onClick={() => setShowDrawer(false)}
        >
          <i className={"pi " + el.icon + " text-xs"}></i>
          <p className="text-xs block lg:hidden">{el.title}</p>
        </Link>
      </React.Fragment>
    );
  });
}

export default ItemNav;
