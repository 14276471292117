import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import FormControl from "../FormControl/FormControl";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import useUser from "../../../Hooks/useUser";
import useServices from "../../../Hooks/useServices";

function SendMessage({ show, setShow }) {

    const toast = useRef()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)


    const { sendServiceRequest } = useServices()
    const { userData } = useUser()

    const reset = () => {
        setData({});
    };

    const options = [
        { name: "Solicitar cortesia", code: "courtesy" },
        { name: "Solicitar Bloqueos", code: "Bloqueos" },
        { name: "Otro", code: "other" },
    ]

    const events = () => {
        if (userData) {
            const arr = []
            userData.event.forEach(x => {
                arr.push({ name: x, code: x })
            })
            return arr
        }
        return [{ name: "", code: "" }]
    }

    const itemsInput = [
        {
            value: userData?.email,
            className: "cursor-no-drop",
            id: "email",
        },
        ,
        {
            label: "Evento",
            value: data?.["event"],
            id: "event",
            onChange: (v) => handleOnChange("event", v),
            options: events(),
            optionValue: "code",
            optionLabel: "name",
            type: "select",
        },
        {
            label: "Servicio",
            value: data?.["service"],
            id: "service",
            onChange: (v) => handleOnChange("service", v),
            options: options,
            optionValue: "code",
            optionLabel: "name",
            type: "select",
        },

        {
            label: "Mensaje",
            value: data?.["message"],
            id: "message",
            onChange: (v) => handleOnChange("message", v),
            placeholder: "",
            type: "textarea"
        }
    ]

    const sendMessage = async () => {
        try {
            setLoading(true)

            const request = await sendServiceRequest({ message: data.message, service: data.service, user: userData?.email, event: data.event })
            if (request.isSuccess) {
                setLoading(false)

                reset()
                toast.current.show({ severity: 'info', summary: 'Exito', detail: 'Solicitud enviada' });
            } else {
                setLoading(false)

                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ocurrio un error al enviar solicitud' });

            }
        } catch (err) {
            console.log(err)
            setLoading(false)

        }
    };


    const footer = () => {
        return (
            <>
                <Button
                    label="Enviar"
                    icon="pi pi-send"
                    onClick={sendMessage}
                    loading={loading}
                ></Button>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-text"
                    loading={loading}
                    onClick={() => {
                        setShow(false);
                        reset();
                    }}
                ></Button>
            </>
        );
    };

    const handleOnChange = (key, value) => {
        setData({ ...data, [key]: value });
    };


    return (
        <>
            <Dialog
                header={<p className="mr-3 text-sm">Solicitar servicio</p> ?? ""}
                visible={show}
                footer={footer}
                className=" w-full lg:p-0 p-2 lg:w-[50%] h-screen"
                onHide={() => setShow(false)}
            >
                <div>
                    {
                        loading ? <div className="flex items-center justify-center w-full h-[60-vh]"><ProgressSpinner /></div> : <>
                            <FormControl
                                data={itemsInput}
                                orderInputClassName="grid grid-cols-1 gap-2"
                            />
                        </>
                    }
                </div>
                <Toast ref={toast}></Toast>

            </Dialog>

        </>
    );
}

export default SendMessage;
