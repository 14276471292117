import { bdvImg, binanceImg, creditCardImg, cuentaInternacional, cuentaNacional, metamaskImg, mobilePaymentsImg, reserveImg, zelleImg, zinliImg } from "../../../../assets/methodsPayment";
import { platformRequirements } from "../../CompletePlatformData/CompletePlatformData";
import { STEPS } from "../AddLinkedAccount";

export default function SelectPlatform({ setPlatform, setStep, linkedAccount, setCurrency }) {

    const items = [
        { src: zelleImg, id: "zelle", currency: "$" },
        { src: binanceImg, id: "binance", currency: "$" },
        { src: zinliImg, id: "zinli", currency: "$" },
        { src: reserveImg, id: "reserve", currency: "$" },
        { src: metamaskImg, id: "trc20", currency: "$" },
        { src: cuentaInternacional, id: "cuentaInternacional", currency: "$" },
        { src: mobilePaymentsImg, id: "pagoMovil", currency: "bs" },
        { src: cuentaNacional, id: "cuentaNacional", currency: "bs" },
    ]

    const handleClick = async (x) => {
        try {
            setPlatform(x.id)
            setStep(STEPS.COMPLETE_PLATFORM_DATA)
            setCurrency(x.currency)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <div className="grid grid-cols-1 gap-3">
                {items.map((x, index) => <div
                    key={index}
                    className="card border-none cursor-pointer h-[75px] flex justify-between"
                    onClick={!linkedAccount.some(y => y === x.id) ? () => handleClick(x) : () => { }}
                >
                   <img className={`p-3 w-[65px] object-contain  ${linkedAccount.some(y => y === x.id) ? "blackAndWhiteFilter" : ""}`} src={x.src}></img>
                    <span className="flex text-sm text-center text-gray-600 flex justify-center items-center">{platformRequirements[x.id].name}</span>
                </div>)}
            </div>
        </div>
    )
} 