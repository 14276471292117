import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import StepBacisInfo from "./StepBacisInfo";
import SelectEventType from "./selectEventType";
import { Event, EventType } from "../../types/data.d.type";
import StepPlaceInitialConfig from "./StepPlaceInitialConfig";
import StepLogisticMultimediaConfig from "./StepLogisticMultimediaConfig";
// import Steps from "./Steps";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
// import useUser from "../../../../Hooks/useUser";
import { useSessionAuthContext } from "../../../../Context/session-auth-context";
import UsagePLan from "./UsagePlan";
import Chat from "../../../../Components/Chat";
// import Steps from "./Steps";
import { MenuItem } from 'primereact/menuitem';
import { Steps } from 'primereact/steps';
import { Message } from "primereact/message";

//declaracion de la enumeracione locales
enum ActionOptions {
    SETEVENTVALUE = 'SETEVENTVALUE',
    SETVALUESOBJET = 'SETVALUESOBJET',
}
// declaraciones de los tipos e interfaces locales

interface EventReducerAction {
    type: ActionOptions,
    key: string,
    value: any
}

const PAYMENTS_PLANS = {
    NP: 0,
    FREE: 1,
    P1: 2,
    P2: 3
}

function eventReducer(state: Event, action: EventReducerAction) {
    const { SETEVENTVALUE, SETVALUESOBJET } = ActionOptions
    const { type, key, value } = action
    switch (type) {
        case SETEVENTVALUE:
            return {
                ...state,
                [key]: value
            }
        case SETVALUESOBJET: return {
            ...state,
            ...value
        }
        default:
            return state;
    }
}

export default function AddNewEventForm() {

    const initialEventState: Event = {
        title: '',
        place: '',
        zones: [],
        city: '',
        country: '',
        direcion: '',
        information: '',
        date: new Date(),
        eventLocation: {},
        isVisible: false,
        multimediaData: {},
        provinceOrState: '',
        endOfSale: new Date(),
        startOfSale: new Date(),
        endEventDate: new Date(),
        participatingArtists: '',
        type: localStorage.getItem('eventType') as EventType || 'concert',
    }

    const [step, setStep] = useState(0)
    const [isLoadingNewEven, setLoadingNewEveb] = useState(false)
    const [isFinishedCreatingEventProcess, setIsFinishedCreatingEventProcess] = useState(false)
    const [isFailedProcess, setIsFailedProcess] = useState(false)
    const [state, dispatch] = useReducer(eventReducer, initialEventState)
    const [progresUpload, setProgresUpload] = useState(0)
    const [paymentPlan, setPaymentPlan] = useState(PAYMENTS_PLANS.P1)
    const changeLoadStatus = (value: boolean) => { setLoadingNewEveb(value) }
    const updataProgres = (current: number) => { setProgresUpload(current) }
    const navigate = useNavigate()
    const { userData, setUserData } = useSessionAuthContext()


    const setEvenVaule = (key: string, value: any) => {

        const { SETEVENTVALUE } = ActionOptions
        const action: EventReducerAction = {
            key,
            value,
            type: SETEVENTVALUE
        }
        dispatch(action);
    }

    const setServeralValues = (values: any) => {

        const { SETVALUESOBJET } = ActionOptions

        const newEventsValues = {
            ...state,
            ...values,
        }

        const action: EventReducerAction = {
            key: 'values',
            value: values,
            type: SETVALUESOBJET
        }

        dispatch(action);

    }

    const enviar = async () => {


        const prevData: Event = state
        const formData = new FormData()

        const { multimediaData, ...rest } = prevData

        for (let mKey in multimediaData) {
            formData.append(mKey, multimediaData[mKey])
        }

        setLoadingNewEveb(false)
        setIsFinishedCreatingEventProcess(false)
        setIsFailedProcess(false)

        if (paymentPlan) formData.append('paymentPlan', paymentPlan.toString())
        if (rest.type) formData.append('type', rest.type)
        if (rest.title) formData.append('title', rest.title)
        if (rest.information) formData.append('information', rest.information)
        if (rest.startOfSale) formData.append('startOfSale', rest.startOfSale.toString())
        if (rest.endOfSale) formData.append('endOfSale', rest.endOfSale.toString())
        if (rest.date) formData.append('date', rest.date.toString())
        if (rest.endEventDate) formData.append('endEventDate', rest.endEventDate.toString())
        if (rest.direcion) formData.append('direcion', rest.direcion)
        if (rest.city) formData.append('city', rest.city)
        if (rest.provinceOrState) formData.append('provinceOrState', rest.provinceOrState)
        if (rest.zones.length > 0) formData.append('zones', JSON.stringify(rest.zones))
        if (rest.isVisible) formData.append('isVisible', 'true')
        if (rest.place) formData.append('place', rest.place)
        if (rest.country) formData.append('country', rest.country)
        const url = 'https://global-boletos-main-ts-server-2cmhx3gilq-uc.a.run.app/api/v2/events'
        // const url = 'http://localhost:5000/api/v2/events'
        changeLoadStatus(true)
        try {
            const result = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    user: userData.id ? userData.id : ""
                },
                onUploadProgress: (progrers) => {
                    // console.log(progrers)
                    const { loaded, total }: { loaded: number, total: number } = progrers
                    console.log(loaded, "  ", total)
                    const cal = (loaded * 100 / total).toString()
                    const progres = parseInt(cal)
                    updataProgres(progres)
                    console.log(progres, "%")
                }
            })
            changeLoadStatus(false)
            setIsFinishedCreatingEventProcess(true)
            console.log(result)
            if (result.data.eventId) {
                console.log("ID DE EVENTO DE CREADO", result.data.eventId)
                setUserData({ ...userData, event: [...userData.event, result.data.eventId] })
            }
        } catch (error) {
            changeLoadStatus(false)
            setIsFailedProcess(true)
        }
        localStorage.removeItem('eventType')
    }


    const changeStep = async (e) => {
        if (e === 3) {
            setStep(3)
            return null
        } else {
            setStep(e)
        }
        // const lastStep = step - 1
        // if (step === 0) {
        //     setStep(7)
        //     return null
        // }
        // if (step === 4) {
        //     setStep(2)
        //     return null
        // }
        // setStep(lastStep)
    }

    const items: MenuItem[] = [
        {
            label: 'Categoria',
            command: (event) => {
                // console.log(event);
            }
        },
        {
            label: 'Información',
            command: (event) => {
                // console.log(event);
            }
        },
        {
            label: 'Distribución',
            command: (event) => {
                // console.log(event);
            }
        },
        {
            label: 'Multimedia',
            command: (event) => {
                // console.log(event);
            }
        }
    ];

    const goBack = () => {
        try {

            switch (step) {
                case 1:
                    setStep(0)
                    return null
                case 2:
                    setStep(1)
                    return null
                case 3:
                    setStep(2)
                    return null
                case 4:
                    setStep(3)
                    return null
            }

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        localStorage.setItem('eventType', state.type)
    }, [state.type])

    useEffect(() => {
        if (step === 6) {
            enviar()
        }
    }, [step])



    return (
        <>

            <div className="max-w-7xl m-auto ">
                {/* <div className="card mb-4">
                    <h3>¡Aquí hay un video para ayudarte!</h3>
                    <p className="text-sm tex-gray-600 mt-3">
                        ¡Echa un vistazo a este video de recorrido por el producto y aprende cómo puedes aprovechar al máximo globalboletos y sus características!
                    </p>
                    <span>Ver ahora</span>
                </div> */}
                <div className="grid mb-4 lg:grid-cols-3 gap-3 ">
                    <div className="col-span-2 card ">
                        {step !== 7 && step !== 6 && <div>

                            <div className='max-w-[87vw] overflow-x-auto'>
                                <Steps model={items} activeIndex={step} onSelect={(e) => changeStep(e.index)} readOnly={true} className="cursor-not-allowed	"/>
                            </div>
                            <Button
                                icon="pi pi-angle-left"
                                className=' p-button-text  text-white mb-5'
                                onClick={() => goBack()}
                                label="Atras"
                            />
                        </div>}
                        {step === 7 && <UsagePLan setPaymentPlan={setPaymentPlan} PAYMENTS_PLANS={PAYMENTS_PLANS} setStep={setStep} />}
                        {step === 0 && <SelectEventType
                            setStep={setStep}
                            paymentPlan={paymentPlan}
                            setValue={(value: string) => { setEvenVaule('type', value) }}
                            defaultValues={state.type}
                        />}
                        {step === 1 && <StepBacisInfo
                            setStep={setStep}
                            setValue={setServeralValues}
                            defaultValues={
                                {
                                    title: state.title,
                                    eventLocation: state.eventLocation,
                                    date: state.date,
                                    city: state.city,
                                    place: state.place,
                                    country: state.country,
                                    participatingArtists: state.participatingArtists,
                                    direcion: state.direcion,
                                    endOfSale: state.endOfSale,
                                    startOfSale: state.startOfSale,
                                    information: state.information,
                                    endEventDate: state.endEventDate,
                                    provinceOrState: state.provinceOrState
                                }
                            }
                        />}
                        {step === 2 && <StepPlaceInitialConfig
                            defaultValues={state.zones}
                            paymentPlan={paymentPlan}
                            setStep={setStep} setValue={(values) => setEvenVaule('zones', values)}
                            eventType={state.type}
                        />}
                        {step === 3 && <StepLogisticMultimediaConfig
                            setStep={setStep}
                            eventType={state.type}
                            setValue={(values: any) => setEvenVaule('multimediaData', values)}
                        />}
                        {step === 6 && <div className="h-[100%] flex flex-col justify-between p-[2rem] m-0">
                            <h5 className="mb-4">Paso 5</h5>
                            {
                                isFinishedCreatingEventProcess && <div className="succes-event mt-[40px] flex flex-col items-center h-full flex justify-end">
                                    <i className="pi pi-check-circle" style={{ fontSize: '7rem' }}></i>

                                    <h5>La experiencia ha sido creada exitosamente</h5>

                                    <p className="text-gb-gray-750">¿Ahora que desea hacer? </p>
                                    <div className="flex mt-5">
                                        <Button className="mr-2"
                                            onClick={() => navigate(`/`)}>
                                            <span >Ir a la pagina principal</span>
                                        </Button>
                                        <Button
                                            disabled
                                            className="mr-2"
                                        >
                                            <span>Seguir configurando la experiencia</span>
                                        </Button>

                                    </div>
                                    <Message severity="info" text="Tendras la opción para iniciar la venta de esta experiencia una vez sea validada por nuestro equipo. La validación puede durar algunas horas, si 
                                    desea una estructura 3D en su plano contactenos de lo contrario puede ver una vista previa en el modulo de construcción dentro de su evento. 
                                    " className='w-full mt-4' />
                                </div>
                            }
                            {
                                isFailedProcess && <div className="flex flex-col items-center h-[100%] justify-center mt-5">
                                    <h5 className="text-center">Lo siento pero ocurrio un error</h5>
                                    <p className="text-gb-gray-600 text-center">Consulte a soporte para solicitar mas información</p>
                                </div>
                            }
                            {isLoadingNewEven && <div className="flex justify-center flex-col h-[100%] items-center">
                                <div className="progress-bar">
                                    <CircularProgress variant="determinate" value={progresUpload} size={120} />
                                    <h3 className="progres-number">{progresUpload}%</h3>
                                </div>
                                <h5 className="mt-1">Creando experiencia</h5>
                                {
                                    progresUpload >= 100 && <div className="mt-[40px] flex justify-center flex-col items-center">
                                        <h5>Registrando recursos</h5>
                                        <p className="text-gb-gray-800">Esto puede tardar varios minutos segun la cantidad de recursos</p>
                                        <Box sx={{
                                            width: '100%'
                                        }}>
                                            <LinearProgress />
                                        </Box>
                                    </div>
                                }
                            </div>
                            }
                        </div>}


                    </div>
                    <div className="hidden lg:grid card sticky top-[4rem] h-[510px]">
                        <Chat />
                    </div>
                </div>
            </div>

        </>
    )
}
