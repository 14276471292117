import { collection, orderBy, query, getDocs } from "firebase/firestore";
import { useState } from "react";
import { db } from "../Config/firebaseConfig";


export default function useCourtesies() {

    const [loading, setLoading] = useState(true)

    const request = async ({ eventId }) => {
        try {
            const q = query(collection(db, "events", eventId, "courtesy-transaction"), orderBy("fecha", "desc"));
            const querySnapshot = await getDocs(q);

            const arr = querySnapshot.docs.map((doc) => {
                const datas = doc.data();
                const id = doc.id;
                return {
                    ...datas,
                    id,
                }
            });
            setLoading(false)
            return arr
        } catch (err) {
            console.log(err)
            setLoading(false)
            return []
        }
    }





    return { request, loading }
}