import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { TableXConfig } from "../../Components/TableX/TableXResumen";
import tableConfig from "./tableConfig.json"
import useDisputedInvoices from "../../Hooks/useDisputedInvoices";
import useUser from "../../Hooks/useUser";
import { BreadCrumb } from "primereact/breadcrumb";
import { Skeleton } from "primereact/skeleton";

function DisputedSales({ eventId }) {
  const home = { icon: 'pi pi-home', url: '/' }

  const items = [
    { label: 'Disputas' },
  ];

  const { userData } = useUser()

  const { disputedSales, loading } = useDisputedInvoices({ eventId: eventId, noEffect: false })

  return (
    <>
      <div className="w-auto grid grid-cols-1">
        {!loading ? <>
          {/* <BreadCrumb className='border-none text-sm' model={items} home={home} /> */}
          <div className="col-span-full">
            <TableXConfig
              tableConfig={tableConfig}
              data={disputedSales}
              autoLayout={true}
              noLazy
            />
          </div>
        </> : <div className="w-full flex justify-center items-center">
          <Skeleton className=" w-full h-[350px]" width="100%" height="350px"></Skeleton>
        </div>}
      </div>
    </>
  );
}

export default DisputedSales;
