import { useState } from "react";
import { Form } from "react-final-form";
import FormSignIn from "./FormSignIn";

export default function RegisterUser({ loading, onSubmit }) {

    const [error, setError] = useState(false)

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

    const validate = (data) => {
        let errors = {}
        if (!data.name) {
            errors.name = "El nombre es requerido";
        }
        if (!data.lastName) {
            errors.lastName = "El apellido es requerido";
        }
        return errors
    }

    const handleSubmit = (data, form) => {
        // form.restart()
        if (onSubmit) onSubmit(data)
    }

    const getFormErrorMessage = (meta) => {
        return (
            isFormFieldValid(meta) && <small className="p-error flex text-left">{meta.error}</small>
        );
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{ email: "", password: "" }}
            validate={validate}
            render={({ handleSubmit }) => (
                <FormSignIn
                    type="register"
                    error={error}
                    loading={loading}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    handleSubmit={handleSubmit}
                />
            )}
        />
    )
}