import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import useUser from "../../../Hooks/useUser";
import useProducerUser from "../../../Hooks/useProducerUser";
import SelectPlatform from "./SelectPlatform/SelectPlatform";
import CompletePlatformData from "../CompletePlatformData/CompletePlatformData";

export const STEPS = {
    SELECT_PLATFORM: 1,
    COMPLETE_PLATFORM_DATA: 2
}

function AddLinkedAccount({ show, setShow, linkedAccountInformation, setLinkedAccount, setLinkedAccountInformation, linkedAccount }) {

    const toast = useRef()

    const [data, setData] = useState({})

    const [step, setStep] = useState(STEPS.SELECT_PLATFORM)
    const [platform, setPlatform] = useState(false)
    const [currency, setCurrency] = useState("")

    const [loading, setLoading] = useState(false)

    const { addAccountSettings } = useProducerUser()
    const { userData } = useUser()

    const reset = () => {
        setData({});
    }

    const goBack = () => {
        setStep(STEPS.SELECT_PLATFORM)
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            const request = await addAccountSettings({ event: userData.producer, methodsConfigure: { ...linkedAccountInformation, [platform]: { ...data, currency: currency } } })
            if (request.isSuccess) {
                reset()
                setLoading(false)
                toast.current.show({ severity: 'info', summary: 'Exito', detail: 'Cuenta vinculada' })
                setLinkedAccount(Object.keys({ ...linkedAccountInformation, [platform]: { ...data, currency: currency } }))
                setLinkedAccountInformation({ ...linkedAccountInformation, [platform]: { ...data, currency: currency } })
                goBack()
            } else {
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Error en vincular cuenta" });
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    };

    const footer = () => {
        return (
            <>
                <Button
                    label="Guardar"
                    icon="pi pi-save"
                    onClick={onSubmit}
                    disabled={step === STEPS.SELECT_PLATFORM ? true : false}
                    loading={loading}
                ></Button>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-text"
                    loading={loading}
                    onClick={() => {
                        setShow(false);
                        reset();
                    }}
                ></Button>
            </>
        );
    };

    const handleOnChange = (key, value) => {
        setData({ ...data, [key]: value });
    };





    return (
        <>
            <Dialog
                header={step === STEPS.SELECT_PLATFORM ? <p className="text-sm">Seleccione plataforma</p> : <Button
                    onClick={() => goBack()}
                    icon="pi pi-angle-left"
                    className=" p-button-text w-[40px]"
                    aria-label="Bookmark"
                />}
                visible={show}
                footer={footer}
                className=" w-full lg:p-0 p-2 lg:w-[40%] h-screen"
                onHide={() => setShow(false)}
            >

                {
                    step === STEPS.SELECT_PLATFORM && <SelectPlatform setPlatform={setPlatform} setStep={setStep} linkedAccount={linkedAccount} setCurrency={setCurrency} />
                }


                {
                    step === STEPS.COMPLETE_PLATFORM_DATA && <CompletePlatformData platform={platform} handleOnChange={handleOnChange} />
                }

                <Toast ref={toast}></Toast>

            </Dialog>

        </>
    );
}

export default AddLinkedAccount;
