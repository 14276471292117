import { Button } from "primereact/button";
import React from "react";

function SalesToVendors({ data, text }) {
  // const [showModal, setShowModal] = useState(false);
  //const [dataSelect, setDataSelect] = useState(null);
  return (
    <>
      <section className="card w-full lg:w-full h-auto col-span-full lg:col-span-2 px-1 lg:px-4">
        <h6 className="font-bold  pl-2">{text ? text : "Boletos vendidos por taquilla"}</h6>
        <div className="grid grid-cols-3   text-xs gap-y-1 mt-3 pl-2 justify-between	">
          <p className="font-bold text-xs  pt-[2px] bg-black rounded-[0px] rounded-tl-[8px] rounded-bl-[8px] pl-2 w-full">
            Vendedor
          </p>
          <p className="font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center">
            Total
          </p>
          <p className="font-bold bg-black text-xs  pt-[2px] rounded-[0px] rounded-tr-[8px] rounded-br-[8px]"></p>
          {data?.salesForVendors?.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <p className="pl-2 justify-self-">{el?.vendor}</p>
                <p className="justify-self-center">{el?.sales}</p>
                <Button
                  icon={<i className="pi pi-ellipsis-v"></i>}
                  className="p-button-text p-button-rounded h-[25px] w-[25px]  p-button-plain text-white justify-self-end mr-10 "
                />
              </React.Fragment>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default SalesToVendors;
