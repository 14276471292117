import { Control, Controller, UseFormRegister, useWatch, } from 'react-hook-form';
import { Place, ZoneFormValue } from '../../types/data.d.type';
import ChairIcon from '@mui/icons-material/Chair';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import TaxtFromEventZone from './TaxtFromEventZone';
import TableBarIcon from '@mui/icons-material/TableBar';
import RadioGroupButton, { RadioData } from '../../components/inputs/radios-gourp/RadioGroupButtonSamll';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import Crop54Icon from '@mui/icons-material/Crop54';
import Crop75Icon from '@mui/icons-material/Crop75';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import Rombo from '../../components/icons/Rombo';
import { useState } from 'react';
import TextFieldInput from '../../components/inputs/text-field-input/TextFieldInput';
import AutoTaxtEventForm from './AutoTaxtEventForm';
import { InputText } from 'primereact/inputtext';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

// export type TableShape = 'square' | 'circle' | 'triangle' | 'rectangle' | 'longRectangle' |

interface DefaultValues {
    name?: string
    startOfNumbering?: any
    startOfRow?: any
    rowLabel?: any
    taxtData: any
    generalTiketQuantity: string
}

export const shapeTable: RadioData[] = [
    {
        value: 'triangle',
        view: 'Triangular',
        icon: ChangeHistoryIcon

    },
    {
        value: 'square',
        view: 'cuadrado',
        icon: CropSquareIcon

    }
    , {
        value: 'circle',
        view: 'circular',
        icon: PanoramaFishEyeIcon
    },
    {
        value: 'rectangle',
        view: 'Rectangulo',
        icon: Crop54Icon
    },
    {
        value: 'longRectangle',
        view: 'Mesa larga',
        icon: Crop75Icon
    }
    ,
    {
        value: 'diamond',
        view: 'Mesa de rombo',
        icon: Rombo
    }

]

export default function ConditionalFomZones({
    control,
    index,
    paymentPlan,
    defaultValues,
    register,
    eventType,
    errors
}: {
    control: Control<ZoneFormValue>;
    index: number;
    paymentPlan: number;
    defaultValues: any;
    register: UseFormRegister<ZoneFormValue>
    errors: any,
    eventType: string
}) {
    const placesWacht = useWatch({
        name: 'zones',
        control,
        defaultValue: []
    })

    const typePlace: Place | undefined = placesWacht[index]?.type


    return typePlace ? <div>
        {typePlace === 'chairs' && <ChairsPlaceFrom defaultValues={defaultValues} eventType={eventType} paymentPlan={paymentPlan} register={register} index={index} control={control} errors={errors} />}
        {typePlace === 'tables' && <TablePlaceForm defaultValues={defaultValues} eventType={eventType} paymentPlan={paymentPlan} register={register} index={index} control={control} errors={errors} />}
        {typePlace === 'standing' && <GeneralFormPlace defaultValues={defaultValues} eventType={eventType} paymentPlan={paymentPlan} control={control} register={register} index={index} errors={errors} />}
    </div> : null
}

function ChairsPlaceFrom({ register, index, defaultValues, control, errors, paymentPlan, eventType }: {
    register: UseFormRegister<ZoneFormValue>, index: number,
    control: Control<ZoneFormValue>
    errors: any
    defaultValues: DefaultValues
    paymentPlan: number
    eventType: string
}) {


    const [checked, setChecked] = useState(false)

    const [rowLabel, setRowLabel] = useState(null)

    const labels = [
        { name: '1,2,3', code: '1,2,3' },
        { name: 'A,B,C', code: 'A,B,C' },
        { name: 'AA,BB,CC', code: 'AA,BB,CC' }
    ]

    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#662d91",
        },
    }));

    return <>
        <div className='charis-form-container erros-active'>
            {/* <div className='gb-form-control erros-active'>
                <BootstrapTooltip title="Nº de Bloques">
                    <label>
                        <ViewModuleIcon />
                        <input className='gb-input' defaultValue={1} type="Number" {...register(`zones.${index}.blocks`)} />
                        <span className='box-input'></span>
                    </label>
                </BootstrapTooltip>
                {errors[`zones[${index}].blocks`] && <span className='errors-message'>{errors[`zones[${index}].blocks`].message}</span>}
            </div> */}
            <div className='gb-form-control'>
                <BootstrapTooltip title="Nº de filas">
                    <label>
                        <TableRowsIcon />
                        <input className='gb-input' type="number" {...register(`zones.${index}.rows`)} />
                        <span className='box-input'></span>
                    </label>
                </BootstrapTooltip>
                {errors[`zones[${index}].rows`] && <span className='errors-message'>{errors[`zones[${index}].rows`].message}</span>}

            </div>
            <div className='gb-form-control'>
                <BootstrapTooltip title="Nº de sillas por fila">
                    <label>
                        <ViewColumnIcon />
                        <input className='gb-input' type="number" {...register(`zones.${index}.colum`)} />
                        <span className='box-input'></span>
                    </label>

                </BootstrapTooltip>
                {errors[`zones[${index}].colum`] && <span className='errors-message'>{errors[`zones[${index}].colum`].message}</span>}
            </div>
        </div>

        <div className='flex gap-1 p-5 items-center '>
            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
            <label >Opciones avanzadas</label>
        </div>

        {/* {
            checked && <>
                <TextFieldInput
                    textlLabel="Numero de inicio de fila"
                    register={register(`zones.${index}.startOfRow`)}
                    placeholder=""
                    className='mb-3'
                    defaultValue={defaultValues.startOfRow ? defaultValues.startOfRow : ""}
                    info=""
                    errorMessage={errors[`zones[${index}].startOfRow`] ? errors[`zones[${index}].startOfRow`].message : undefined}
                />

                <TextFieldInput
                    textlLabel="Numero de inicio de lugares en la fila"
                    register={register(`zones.${index}.startOfNumbering`)}
                    placeholder=""
                    className='mb-3'
                    defaultValue={defaultValues.startOfNumbering ? defaultValues.startOfNumbering : ""}
                    info=""
                    errorMessage={errors[`zones[${index}].startOfNumbering`] ? errors[`zones[${index}].startOfNumbering`].message : undefined}
                />

                <label className="text-gb-gray-900 mt-2" >Formato de etiqueta para el orden de las filas</label>
                <Controller
                    name={`zones.${index}.rowLabel`}
                    control={control}
                    render={
                        ({
                            field: { onChange }
                        }) => {
                            return <Dropdown
                                className="w-full md:w-14rem gb-input "
                                style={{ background: '#131517 !important', height: "50px" }}
                                value={rowLabel} onChange={(e) => setRowLabel(e.value)} options={labels} optionLabel="name" placeholder="Seleccionar etiqueta"
                            />
                        }
                    }
                />
            </>
        } */}





        <Controller
            control={control}
            name={`zones.${index}.taxtData`}
            render={({
                field: { onChange, value, }
            }) => <AutoTaxtEventForm
                    onChange={onChange}
                    value={value}
                    defaultValues={defaultValues.taxtData}
                    paymentPlan={paymentPlan}
                    errorsPrice={errors[`zones[${index}].taxtData.price`] ? errors[`zones[${index}].taxtData.price`].message : undefined}
                    errorsBasePrice={errors[`zones[${index}].taxtData.basePrice`] ? errors[`zones[${index}].taxtData.basePrice`].message : undefined}
                    errorsIVA={errors[`zones[${index}].taxtData.IVA`] ? errors[`zones[${index}].taxtData.IVA`].message : undefined}
                    errorsImpMunicipal={errors[`zones[${index}].taxtData.impMunicipal`] ? errors[`zones[${index}].taxtData.impMunicipal`].message : undefined}
                    errorsServicioDeTaquilla={errors[`zones[${index}].taxtData.servicioDeTaquilla`] ? errors[`zones[${index}].taxtData.servicioDeTaquilla`].message : undefined}
                    errorsTotal={errors[`zones[${index}].taxtData.total`] ? errors[`zones[${index}].taxtData.total`].message : undefined}
                />}

        />
    </>
}

function TablePlaceForm({ register, index, defaultValues, control, errors, paymentPlan, eventType }: {
    register: UseFormRegister<ZoneFormValue>, index: number,
    control: Control<ZoneFormValue>
    errors: any,
    defaultValues: DefaultValues
    paymentPlan: number,
    eventType: string

}) {


    const [addChairsTaxt, setAddChairsTax] = useState(true);
    const [addTableTaxt, setAddTableTaxt] = useState(false)

    return <>
        <div className='table-form-container erros-active'>
            {/* <div className='gb-form-control'>
                <Tooltip title="Nº de Bloques">
                    <label>
                        <ViewModuleIcon />
                        <InputText className='gb-input' type="Number" {...register(`zones.${index}.blocks`)} />
                        <span className='box-input'></span>
                    </label>
                </Tooltip>
                {errors[`zones[${index}].blocks`] && <span className='errors-message'>{errors[`zones[${index}].blocks`].message}</span>}
            </div> */}
            <div className='gb-form-control'>
                <Tooltip title="Nº de filas">
                    <label>
                        <TableRowsIcon />
                        <InputText className='gb-input' type="number" {...register(`zones.${index}.rows`)} />
                        <span className='box-input'></span>
                    </label>
                </Tooltip>
                {errors[`zones[${index}].rows`] && <span className='errors-message'>{errors[`zones[${index}].rows`].message}</span>}
            </div>
            <div className='gb-form-control'>
                <Tooltip title="Nº de mesas por fila">
                    <label>
                        <TableBarIcon />
                        <InputText className='gb-input' type="number" {...register(`zones.${index}.colum`)} />
                        <span className='box-input'></span>
                    </label>
                </Tooltip>
                {errors[`zones[${index}].colum`] && <span className='errors-message'>{errors[`zones[${index}].colum`].message}</span>}
            </div>
            <div className='gb-form-control'>
                <Tooltip title="Nº de sillas por mesa">
                    <label>
                        <TableBarIcon />
                        <InputText className='gb-input' type="number" {...register(`zones.${index}.chairs`)} />
                        <span className='box-input'></span>
                    </label>
                </Tooltip>
                {errors[`zones[${index}].colum`] && <span className='errors-message'>{errors[`zones[${index}].chairs`].message}</span>}
            </div>
        </div>

        <RadioGroupButton
            items={shapeTable}
            name={`shape-of-table-${index}`}
            register={register(`zones.${index}.tableShape`)}
            label="Forma de la mesa"
        />

        {/* <FormControlLabel
            control={<Switch checked={addChairsTaxt}
                onClick={() => { setAddChairsTax(!addChairsTaxt) }} />}
            className="ui-check" label="Aplicar Precio por Silla" /> */}
        {
            addChairsTaxt && <Controller
                control={control}
                name={`zones.${index}.taxtData`}
                render={({
                    field: { onChange, value, }
                }) => <AutoTaxtEventForm
                        onChange={onChange}
                        defaultValues={defaultValues.taxtData}
                        paymentPlan={paymentPlan}
                        value={value}
                        errorsPrice={errors[`zones[${index}].taxtData.price`] ? errors[`zones[${index}].taxtData.price`].message : undefined}
                        errorsBasePrice={errors[`zones[${index}].taxtData.basePrice`] ? errors[`zones[${index}].taxtData.basePrice`].message : undefined}
                        errorsIVA={errors[`zones[${index}].taxtData.IVA`] ? errors[`zones[${index}].taxtData.IVA`].message : undefined}
                        errorsImpMunicipal={errors[`zones[${index}].taxtData.impMunicipal`] ? errors[`zones[${index}].taxtData.impMunicipal`].message : undefined}
                        errorsServicioDeTaquilla={errors[`zones[${index}].taxtData.servicioDeTaquilla`] ? errors[`zones[${index}].taxtData.servicioDeTaquilla`].message : undefined}
                        errorsTotal={errors[`zones[${index}].taxtData.total`] ? errors[`zones[${index}].taxtData.total`].message : undefined}
                    />}
            />
        }
        {/* <FormControlLabel
            control={<Switch checked={addTableTaxt}
                onClick={() => { setAddTableTaxt(!addTableTaxt) }} />}
            className="ui-check" label="Aplicar Precio por Mesa" /> */}
        {
            addTableTaxt && <Controller
                control={control}
                name={`zones.${index}.TableTaxtData`}

                render={({
                    field: { onChange, value, }
                }) => <AutoTaxtEventForm
                        onChange={onChange}
                        value={value}

                    />}

            />
        }
    </>
}

function GeneralFormPlace({ register, defaultValues, index, control, errors, paymentPlan, eventType }: {
    errors: any
    register: UseFormRegister<ZoneFormValue>, index: number,
    control: Control<ZoneFormValue>,
    defaultValues: DefaultValues,
    paymentPlan: number,
    eventType: string
}) {

    console.log('GENERAL', defaultValues)

    return <>
        <div className="general-place-form">
            <TextFieldInput
                textlLabel={eventType === 'turismo' ? 'Cantidad disponible' : 'Cantidad de boletos*'}
                register={register(`zones.${index}.generalTiketQuantity`)}
                placeholder=""
                defaultValue={defaultValues.generalTiketQuantity ? defaultValues.generalTiketQuantity : ""}
                info=""
                errorMessage={errors[`zones[${index}].generalTiketQuantity`] ? errors[`zones[${index}].generalTiketQuantity`].message : undefined}
            />
            {/* <TextFieldInput
                register={register(`zones.${index}.generalTiketStratNumber`)}
                textlLabel="Numeración de inicio*"
                defaultValue={1}
                info=""
                errorMessage={errors[`zones[${index}].generalTiketStratNumber`] ? errors[`zones[${index}].generalTiketStratNumber`].message : undefined}
            /> */}
            <Controller
                control={control}
                name={`zones.${index}.taxtData`}
                render={({
                    field: { onChange, value, }
                }) => <AutoTaxtEventForm
                        onChange={onChange}
                        defaultValues={defaultValues.taxtData}
                        value={value}
                        eventType={eventType}
                        paymentPlan={paymentPlan}
                        errorsPrice={errors[`zones[${index}].taxtData.price`] ? errors[`zones[${index}].taxtData.price`].message : undefined}
                        errorsBasePrice={errors[`zones[${index}].taxtData.basePrice`] ? errors[`zones[${index}].taxtData.basePrice`].message : undefined}
                        errorsIVA={errors[`zones[${index}].taxtData.IVA`] ? errors[`zones[${index}].taxtData.IVA`].message : undefined}
                        errorsImpMunicipal={errors[`zones[${index}].taxtData.impMunicipal`] ? errors[`zones[${index}].taxtData.impMunicipal`].message : undefined}
                        errorsServicioDeTaquilla={errors[`zones[${index}].taxtData.servicioDeTaquilla`] ? errors[`zones[${index}].taxtData.servicioDeTaquilla`].message : undefined}
                        errorsTotal={errors[`zones[${index}].taxtData.total`] ? errors[`zones[${index}].taxtData.total`].message : undefined}
                    />}
            />
        </div>
    </>
}