import React from "react";
import { Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from 'primereact/button';
import { classNames } from "primereact/utils";
import { Message } from 'primereact/message';

function FormLogin({
  error,
  loading,
  handleSubmit,
  getFormErrorMessage,
  isFormFieldValid,

}) {
  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }} className="p-fluid">
        <Field
          name="email"
          render={({ input, meta }) => (
            <div className="field mb-5 mt-10">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText
                  id="email"
                  {...input}
                  className={classNames({
                    "p-invalid": isFormFieldValid(meta),
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({ "p-error": isFormFieldValid(meta) })}
                >
                  Correo*
                </label>
              </span>
              {getFormErrorMessage(meta)}
            </div>
          )}
        />
        <Field
          name="password"
          render={({ input, meta }) => (
            <div className="field my-5">
              <span className="p-float-label">
                <Password
                  id="password"
                  {...input}
                  toggleMask
                  className={classNames({
                    "p-invalid": isFormFieldValid(meta),
                  })}
                  feedback={false}
                />
                <label
                  htmlFor="password"
                  className={classNames({ "p-error": isFormFieldValid(meta) })}
                >
                  Contraseña*
                </label>
              </span>
              {getFormErrorMessage(meta)}
            </div>
          )}
        />
        <Button
          label="Ingresar"
          loading={loading}
          className="p-button-raised mt-2"
        />
      </form>
      {error && <Message className="w-full mt-2" severity="error" text="Usuario o contraseña invalida"></Message>}

    </>
  );
}

export default FormLogin;
