import { collection, orderBy, query, getDocs, limit } from "firebase/firestore";
import { useState } from "react";
import { db } from "../Config/firebaseConfig";


export default function useLiquidations() {

    const [loading, setLoading] = useState(true)

    const request = async ({ eventId }) => {
        try {
            const q = query(collection(db, "events", eventId, "payments-producer"), orderBy("lastUpdateDate", "desc"));
            const querySnapshot = await getDocs(q);

            const arr = querySnapshot.docs.map((doc) => {
                const datas = doc.data();
                const id = doc.id;
                return {
                    ...datas,
                    id,
                }
            });


            const paymentsInBs = []
            const paymentsInDollars = []
            const paymentsInCOP = []

            arr.forEach((x => {
                if (x.type === "$") {
                    paymentsInDollars.push(x.monto)
                } else if (x.type === "cop") {
                    paymentsInCOP.push(x.monto)
                } else {
                    paymentsInBs.push(x.monto)
                }
            }))

            const totalInBs = paymentsInBs.reduce(
                (prev, curr) => prev + curr,
                0
            )
            const totalInDollars = paymentsInDollars.reduce(
                (prev, curr) => prev + curr,
                0
            )
            const totalInCOP = paymentsInCOP.reduce(
                (prev, curr) => prev + curr,
                0
            )
            setLoading(false)
            return { arr: arr, totalInBs: totalInBs, totalInDollars: totalInDollars, totalInCOP: totalInCOP }
        } catch (err) {
            console.log(err)
            setLoading(false)
            return {}
        }
    }


    const requestLastTransactions = async ({ eventId }) => {
        try {
            const q = query(collection(db, "events", eventId, "payments-producer"), orderBy("lastUpdateDate", "desc"), limit(4));
            const querySnapshot = await getDocs(q)
            const arr = querySnapshot.docs.map((doc) => {
                const datas = doc.data();
                const id = doc.id;
                return {
                    ...datas,
                    id,
                }
            })
            return arr
        } catch (err) {
            console.log(err)
            return []
        }
    }


    return { request, loading, requestLastTransactions }
}