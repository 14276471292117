import React from "react";
import { Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from 'primereact/button';
import { classNames } from "primereact/utils";
import { Message } from 'primereact/message';

function FormSignIn({
  type,
  error,
  loading,
  handleSubmit,
  isFormFieldValid,
  getFormErrorMessage,

}) {


  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }} className="p-fluid">
        {
          type === "email" && <Field
            name="email"
            render={({ input, meta }) => (
              <div className="field mb-5">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <InputText
                    id="email"
                    {...input}
                    className={classNames({
                      "p-invalid": isFormFieldValid(meta),
                    })}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({ "p-error": isFormFieldValid(meta) })}
                  >
                    Correo*
                  </label>
                </span>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        }
        {
          type === "password" && <Field
            name="password"
            render={({ input, meta }) => (
              <div className="field my-5">
                <span className="p-float-label">
                  <Password
                    id="password"
                    {...input}
                    promptLabel="Completar campo"
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid(meta),
                    })}
                    feedback={false}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": isFormFieldValid(meta) })}
                  >
                    Contraseña*
                  </label>
                </span>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        }
        {
          type === "register" && <>
            <Field
              name="name"
              render={({ input, meta }) => (
                <div className="field mb-5">
                  <span className="p-float-label p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <InputText
                      id="name"
                      {...input}
                      className={classNames({
                        "p-invalid": isFormFieldValid(meta),
                      })}
                    />
                    <label
                      htmlFor="name"
                      className={classNames({ "p-error": isFormFieldValid(meta) })}
                    >
                      Nombre*
                    </label>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Field
              name="lastName"
              render={({ input, meta }) => (
                <div className="field mb-5">
                  <span className="p-float-label p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <InputText
                      id="lastName"
                      {...input}
                      className={classNames({
                        "p-invalid": isFormFieldValid(meta),
                      })}
                    />
                    <label
                      htmlFor="lastName"
                      className={classNames({ "p-error": isFormFieldValid(meta) })}
                    >
                      Apellido*
                    </label>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
          </>
        }
        {
          type === "register_password" && <>
            <Field
              name="password"
              render={({ input, meta }) => (
                <div className="field mb-5">
                  <span className="p-float-label p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <Password
                      weakLabel="Vulnerable"
                      strongLabel="Fuerte"
                      promptLabel="Completar campo"
                      mediumLabel="Medio"
                      id="password"
                      {...input}
                      className={classNames({
                        "p-invalid": isFormFieldValid(meta),
                      })}
                    />
                    <label
                      htmlFor="password"
                      className={classNames({ "p-error": isFormFieldValid(meta) })}
                    >
                      Contraseña*
                    </label>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Field
              name="confirmPassword"
              render={({ input, meta }) => (
                <div className="field mb-5">
                  <span className="p-float-label p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <Password
                      id="confirmPassword"
                      weakLabel="Vulnerable"
                      strongLabel="Fuerte"
                      promptLabel="Completar campo"
                      mediumLabel="Medio"
                      feedback={false} 
                      {...input}
                      className={classNames({
                        "p-invalid": isFormFieldValid(meta),
                      })}
                    />
                    <label
                      htmlFor="confirmPassword"
                      className={classNames({ "p-error": isFormFieldValid(meta) })}
                    >
                      Confirmar contraseña*
                    </label>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
          </>
        }
        {
          type === "enter_password" && <>
            <Field
              name="password"
              render={({ input, meta }) => (
                <div className="field mb-5">
                  <span className="p-float-label">
                    {/* <i className="pi pi-envelope" /> */}
                    <Password
                      id="password"
                      mediumLabel="Medio"
                      strongLabel="Fuerte"
                      weakLabel="Vulnerable"
                      toggleMask
                      promptLabel="Completar campo"
                      feedback={false} 
                      {...input}
                      className={classNames({
                        "p-invalid": isFormFieldValid(meta),
                      })}
                    />
                    <label
                      htmlFor="password"
                      className={classNames({ "p-error": isFormFieldValid(meta) })}
                    >
                      Contraseña*
                    </label>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
          </>
        }
        <Button
          label="Continuar"
          loading={loading}
          className="p-button-raised mt-2"
        />
      </form>
      {error && <Message className="w-full mt-2 justify-start" severity="error" text="Usuario o contraseña invalida"></Message>}
    </>
  )
}

export default FormSignIn
