import {
    Page,
    Text,
    Image,
    View,
    Document,
    PDFDownloadLink,
} from "@react-pdf/renderer"
import { PDFViewer } from '@react-pdf/renderer';
import { Button } from "primereact/button";


const Title = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "14px",
                width: "100%",
                textAlign: "center",
                fontWeight: "700",
                marginBottom: "30px",
            }}
        >
            {children}
        </Text>
    )
}
const SubTitle = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "11px",
                fontWeight: "900",
                marginBottom: "25px",
            }}
        >
            {children}
        </Text>
    )
}

const Header = ({ date }) => {
    return (
        <View
            style={{ position: "absolute", top: 35, left: 5 }}
            fixed
        >
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Image
                    src="/assets/image/global-banner.png"
                    style={{ width: "130px" }}
                />
            </View>

            <Text
                style={{
                    fontSize: "11px",
                    marginLeft: "6px",
                    marginTop: "20px",
                }}
            >
                {date.toLocaleDateString()}
            </Text>
        </View>
    )

}

const Footer = ({ date }) => {
    return (
        <View
            style={{ position: "absolute", bottom: 35, left: 5 }}
            fixed
        >
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Image
                    src="/assets/image/global-banner.png"
                    style={{ width: "130px" }}
                />
                <Text
                    style={{
                        fontSize: "10px",
                        marginTop: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                >
                    TODOS LOS DERECHOS RESERVADOS POR GLOBALBOLETOS
                </Text>

                <Text
                    style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        marginTop: "10px",
                        marginRight: "5px",
                    }}
                >
                    {date.toLocaleDateString()}
                </Text>
                <Text
                    style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginTop: "10px",
                    }}
                    render={({ pageNumber, totalPages }) => `PG ${pageNumber}`}
                ></Text>
            </View>
        </View>
    )

}


export default function Receipt({ data }) {



    const { addres, name, ci, amount, dni, signature, description, date } = data


    const MyDocument = () => {
        return (
            <Document >

                <Page size="A4" style={{ margin: 30, paddingBottom: 30, paddingRight: 30 }}
                >

                    <Header date={date} />

                    <View
                        style={{
                            marginTop: 80,
                            marginleft: 30,
                            marginRight: 30,
                        }}
                    >

                        <Title>RECIBO DE ENTREGA DE DINERO EN EFECTIVO</Title>



                        <SubTitle>- DATOS GENERALES: </SubTitle>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: "11px",
                                    display: "flex",
                                    width: "27%",
                                }}
                            >UBICACION DE TAQUILLA:</Text>
                            <Text
                                style={{
                                    fontSize: "11px",
                                    display: "flex",
                                    width: "71%",
                                    borderBottom: "1px solid #000"

                                }}
                            >{addres}</Text>
                        </View>

                        <SubTitle>- DATOS DEL RESPONSABLE DE LA RECOLECCIÓN: </SubTitle>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >

                            <View
                                style={{ display: "flex", width: "50%", flexDirection: "row", }}
                            >
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "45%",
                                    }}
                                >APELLIDO Y NONBRE:</Text>
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "55%",
                                        borderBottom: "1px solid #000"

                                    }}
                                >{name}</Text>

                            </View>
                            <View
                                style={{ display: "flex", width: "50%", flexDirection: "row", }}
                            >
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "8%",
                                    }}
                                >CI:</Text>
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "89%",
                                        borderBottom: "1px solid #000"

                                    }}
                                >{ci}</Text>

                            </View>


                        </View>

                        <View
                            style={{ display: "grid", width: "100%", }}
                        >


                            <SubTitle>- DETALLES DE LA ENTREGA:</SubTitle>


                            <Text
                                style={{
                                    fontSize: "11px",
                                    width: "98%",
                                    borderBottom: "1px solid #000"
                                }}
                            >{description}</Text>
                        </View>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >

                            <View
                                style={{ display: "flex", width: "100%", flexDirection: "row", marginTop: "20px" }}
                            >
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "37%",
                                    }}
                                >CANTIDAD DE DINERO ENTREGADA:</Text>
                                <Text
                                    style={{
                                        fontSize: "11px",
                                        display: "flex",
                                        width: "61%",
                                        borderBottom: "1px solid #000"

                                    }}
                                >${amount}</Text>

                            </View>



                        </View>

                        <SubTitle>- REGISTRO DE DNI:</SubTitle>

                        {
                            dni && <Image
                                src={dni}
                                style={{
                                    height: "150px",
                                    width: "150px",
                                    objectFit: "cover",
                                    margin: "auto",
                                }}
                            />
                        }



                        <SubTitle>- CONFIRMACIÓN DE ENTREGA:</SubTitle>

                        <Text
                            style={{
                                fontSize: "11px",
                                display: "flex",
                                width: "100%",
                            }}
                        >
                            Yo, {name} confirmo que he recibido la cantidad de dinero mencionada anteriormente.
                        </Text>

                        <View
                            style={{
                                fontSize: "11px",
                                display: "flex",
                                marginTop: "125px",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >

                            {
                                signature && <Image
                                    src={signature}
                                    style={{
                                        height: "110px",
                                        position: "absolute",
                                        bottom: "20px",
                                        width: "125px",
                                        zIndex: -1
                                    }}
                                />
                            }



                            <Text
                                style={{
                                    display: "flex",
                                    textAlign: "center",
                                    width: "250px",
                                    borderTop: "1px solid #000",
                                }}
                            >
                                RECIBE
                            </Text>

                        </View>


                    </View>

                    <Footer date={date} />

                </Page>


            </Document>
        )
    }

    return (

        <div className="w-full h-full">


            <div className="sm:hidden w-full h-full grid items-center justify-center"> {/* Ocultar en pantallas de escritorio */}
                <PDFDownloadLink
                    document={
                        <MyDocument></MyDocument>
                    }
                    fileName={`RECIBO DE ENTREGA DE EFECTIVO ${date.toLocaleDateString()}.pdf`}
                >
                    {({ blob, url, loading, error }) =>
                        <span className="p-float-label flex">
                            <Button
                                label="DESCARGAR RECIBO DE EFECTIVO"
                                icon="pi pi-cloud-download"
                            />
                        </span>
                    }
                </PDFDownloadLink>
            </div>
            <div className="hidden md:block w-full h-full"> {/* Ocultar en pantallas de celulares */}
                <PDFViewer className="w-full h-full">
                    <MyDocument></MyDocument>
                </PDFViewer>
            </div>
        </div>

    )
}
