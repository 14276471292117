import axios from "axios";
import { useEffect, useState } from "react"
import { fetchZone, getZones, fetchEvent } from "../Firebase/client"


const structureBase = (sillas) => {
    const filasOrdenadas = [];

    // Iterar sobre todas las sillas
    sillas.forEach((silla) => {
        const { fila, tablePosition, positions, id, price, estado, zona } = silla;

        // Buscar la fila correspondiente en el resultado
        let filaActual = filasOrdenadas.find((filaActual) => filaActual.fila === fila);

        // Si la fila no existe en el resultado, crearla
        if (!filaActual) {
            filaActual = { fila, tables: [] };
            filasOrdenadas.push(filaActual);
        }

        // Buscar la mesa correspondiente en la fila
        let mesaActual = filaActual.tables.find((mesa) => mesa.position === tablePosition);

        // Si la mesa no existe en la fila, crearla
        if (!mesaActual) {
            mesaActual = { position: tablePosition, seats: [] };
            filaActual.tables.push(mesaActual);
        }

        // Agregar la silla a la mesa
        mesaActual.seats.push({ position: positions, id: id, price: price, state: estado, tablePosition: tablePosition, zone: zona });
    });

    // Ordenar las mesas y sillas dentro de cada fila
    filasOrdenadas.forEach((filaActual) => {
        filaActual.tables.sort((mesaA, mesaB) => mesaA.position - mesaB.position);
        filaActual.tables.forEach((mesa) => {
            mesa.seats.sort((sillaA, sillaB) => sillaA.position - sillaB.position);
        });
    });

    return filasOrdenadas;
}

const structureBaseSeats = (sillas) => {
    const filasOrdenadas = {};

    sillas.forEach((silla) => {
        const { fila, positions, id, price, estado, zona, rowLabel } = silla;

        if (filasOrdenadas[fila]) {
            filasOrdenadas[fila].seats.push({ position: positions, id: id, price: price, state: estado, zone: zona, row: rowLabel ? rowLabel : fila });
        } else {
            filasOrdenadas[fila] = { fila: fila, seats: [{ position: positions, id: id, price: price, state: estado, zone: zona ,row: rowLabel ? rowLabel : fila}] };
        }
    });

    const filasOrdenadasArray = Object.values(filasOrdenadas).sort((filaA, filaB) => filaA.fila - filaB.fila);

    filasOrdenadasArray.forEach((fila) => {
        fila.seats.sort((sillaA, sillaB) => parseInt(sillaA.position) - parseInt(sillaB.position));
    });

    return filasOrdenadasArray;
}


export default function useStructure({ eventId, flat, query }) {

    const [data, setData] = useState({
        structure: [],
        type: ""
    })
    const [zone, setZone] = useState(false)
    const [loading, setLoading] = useState(true)
    const [inititalPositions, setInitialPositions] = useState([])
    const [zones, setZones] = useState({ zones: [], capacity: 0 })
    const [map2d, setMap2d] = useState(false)


    const requestPositions = async ({ structure }) => {
        try {
            const url = `https://global-boletos-main-ts-server-2cmhx3gilq-uc.a.run.app/api/v2/positions/getPositions/${eventId}/${structure}`
            const response = await axios.get(url)

            if (!response.data.isSuccess) return { isSuccess: false, data: [] }
            return { isSuccess: response.data.isSuccess, data: response.data.data }

        } catch (err) {
            console.log(err)
            return { isSuccess: false, data: [] }
        }
    }


    const requestSeats = async ({ parameter, type }) => {
        try {
            if (type === 'standing') return;
            setLoading(true)
            setInitialPositions([])
            const responseStructure = await requestPositions({ structure: parameter })
            if (responseStructure.isSuccess) setInitialPositions(responseStructure.data)
            const response = await fetchZone({ eventId: eventId, zone: parameter })
            if (response) {
                const structure = type === "chairs" ? structureBaseSeats(response) : structureBase(response)
                setData({
                    structure: structure,
                    type: type
                })
                setZone(parameter)
                setLoading(false)
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const requestZones = async () => {
        try {
            console.log('SOLICITANDO ZONAS')
            setLoading(true)
            const response = await getZones({ eventId: eventId })
            const eventQuery = await fetchEvent(eventId)

            if (eventQuery) setMap2d(eventQuery.dynamic ? false : true)

            const responseStructure = await requestPositions({ structure: 'flat' })
            if (responseStructure.isSuccess) setInitialPositions(responseStructure.data)

            setZones({
                zones: response.placeMap,
                capacity: response.placeCapacity
            })
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const selectZone = async (x) => {
        try {
            const { type, zone } = x

            requestSeats({ parameter: zone, type })
        } catch (err) {
            console.log(err)
        }

    }

    const clearZone = () => {
        if (!zone) return;
        setData({
            structure: [],
            type: ""
        })
        setZone(false)
        requestZones()
    }

    useEffect(() => {
        if (flat && !query) requestZones()
        if (!flat && query) requestSeats({ parameter: query })
    }, [eventId, query, flat])

    return { data, loading, zone, selectZone, zones, clearZone, inititalPositions, map2d, setMap2d }
}