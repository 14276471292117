import { Button } from "primereact/button";
import OverviewReport from "../../../Components/OverviewReport/OverviewReport";

export default function RequestService({ loading, totals, eventId, ...props }) {


    return (
        <div className="w-full card  flex flex-col lg:flex-row gap-2 mb-5 items-center">
            <div className="field flex gap-3 justify-between w-full">
                <div className="field flex gap-3 ">
                    <span className="p-float-label flex">
                        <Button
                            label="Servicio"
                            icon="pi pi-bolt"
                            {...props}
                        />
                    </span>
                    <span className="p-float-label flex">
                        {!loading && <OverviewReport
                            totals={totals}
                            eventId={eventId}
                            loading={loading}
                        />}
                    </span>
                </div>
                <span className="p-float-label flex items-center cursor-pointer" onClick={() => window.open(`https://globalboletos.com/Event/${eventId}`)}>
                    <Button label="Visitar" icon="pi pi-external-link"   />
                    {/* <i className="pi pi-external-link" style={{ fontSize: '1rem' }}></i> */}
                </span>
            </div>
        </div>
    )
}