import { Label, Rect, Text } from "react-konva";
import useLabel from "../hooks/useLabel";

export default function SeatLabel({ showLabel }) {
    const { chairState } = useLabel()

    return (
        <Label x={showLabel.x - 80} y={showLabel.y + 10} opacity={1} width={120} >
            <Rect
                width={120}
                height={80}
                stroke={"#A9A9A9"}
                strokeWidth={4}
                fill='white' // Color azul pastel
                cornerRadius={5}
            />
            <Rect
                x={2}
                y={69}
                width={116}
                height={10}
                fill={`${chairState(showLabel.state)}`}
                cornerRadius={5}
            />
            <Rect
                y={66}
                x={2}
                width={116}
                height={10}
                fill={`${chairState(showLabel.state)}`}
            />
            <Text
                text={`$${showLabel.price.toFixed(2)}`}
                padding={8}
                fill="black"
                align="center"
                fontSize={15}
                fontStyle='bold'
            />
            <Text
                text={`${showLabel.zone}`}
                y={20}
                padding={8}
                fill="black"
                align="center"
                fontSize={15}
            />
            <Text
                text={showLabel.type === "chairs" ? `Fila ${showLabel.row } Silla ${showLabel.position}` : `Mesa ${showLabel.tablePosition} Silla ${showLabel.position}`}
                y={39}
                padding={8}
                fill="black"
                align="center"
                fontSize={15}
            />
            <Text
                text={showLabel.state === `ok` ? "Disponible" : ""}
                x={22} // Centra el texto horizontalmente utilizando offsetX
                y={59} // Ajusta la posición vertical del texto
                align="center"
                padding={8}
                fill="black"
                fontSize={12}
            />
        </Label>
    )
}