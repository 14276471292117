import useUser from "../../../Hooks/useUser";
import { TabMenu } from "primereact/tabmenu";
import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ResumeForInsurance from "../../../Pages/Insurance/Resume/Resume";
import InsuranceOperations from "../../../Pages/Insurance/Operations/Operations";
import { logout } from "../../../Firebase/client";

export default function EventOptionsForInsurance({ children }) {

    let { eventId } = useParams()

    const { userData } = useUser()

    const navigate = useNavigate()

    const itemsBreadCrumb = [
        { label: `${eventId}` },
    ];

    const home = { icon: 'pi pi-building', url: '/' }

    const [valueNav, setValueNav] = useState("resumen");
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(0);

    const [items] = useState([
        { label: "Resumen", icon: "pi pi-fw pi-home", value: "resumen" },
        { label: "Operaciones", icon: "pi pi-fw pi-book", value: "operaciones" },
    ])

    let nav = searchParams.get("nav")

    useEffect(() => {
        if (userData) {
            const permissionForThisEvent = userData.event.some(w => w === eventId)
            if (!permissionForThisEvent) navigate("/")
            if (userData.rol !== "aseguradora") logout()
            if (nav) {
                setValueNav(nav);
                const result = items.findIndex((el) => el.value === nav);
                setActiveIndex(result);
            } else if (valueNav === "") {
                setSearchParams({ nav: "resumen" });
                setValueNav("resumen");
                setActiveIndex(0);
            }
        }
    }, [items, nav, valueNav, setSearchParams, eventId])

    return (<>
        <BreadCrumb className='border-none text-sm mb-4' model={itemsBreadCrumb} home={home} />
        <div className=" relative">
            {nav ? (
                <TabMenu
                    model={items}
                    activeIndex={activeIndex}
                    scrollable
                    onTabChange={(e) => {
                        setActiveIndex(e.index);
                        setSearchParams({ nav: e.value.value });
                    }}
                    className="mb-4 text-sm"
                />
            ) : null}
            {(() => {
                switch (nav) {
                    case "resumen":
                        return <ResumeForInsurance eventId={eventId} />
                    case "operaciones":
                        return <InsuranceOperations eventId={eventId} />
                }
            })()}
            {children}
        </div>
    </>
    )
}

