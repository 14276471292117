import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";

function HaaderSelectEvent({ setSearch, search, sizes, setSizes }) {

    const navigate = useNavigate()

    return (
        <>
            <span className="p-input-icon-left w-[50%]">
                <i className="pi pi-search" />
                <InputText
                    value={search}
                    onChange={(e) =>
                        setSearch((x) => String(e.target.value).toLowerCase())
                    }
                    className="w-[100%] h-[30px] place-self-start self-start text-white/85  rounded-xl border-[#ffffff]/5"
                    placeholder="Buscar evento"
                />
            </span>
            <section className="flex gap-2 items-center">
                <Tooltip target={`.small`} className="z-20" mouseTrack mouseTrackLeft={10} />
                <Tooltip target={`.medium`} className="z-20" mouseTrack mouseTrackLeft={10} />
                <Tooltip target={`.large`} className="z-20" mouseTrack mouseTrackLeft={10} />
                <i
                    onClick={() => setSizes(120, "h-[120px]")}
                    data-pr-position="left"
                    data-pr-tooltip={`Pequeño`}
                    className={`small pi pi-image text-md  ${sizes === 120 ? "text-zinc-50" : "text-zinc-400"
                        } hover:text-zinc-50 cursor-pointer`}
                ></i>
                <i
                    onClick={() => setSizes(180, "h-[180px]")}
                    data-pr-position="left"
                    data-pr-tooltip={`Mediano`}
                    className={`medium pi pi-image text-xl  ${sizes === 180 ? "text-zinc-50" : "text-zinc-400"
                        } hover:text-zinc-50 cursor-pointer`}
                ></i>
                <i
                    onClick={() => setSizes(250, "h-[250px]")}
                    data-pr-position="left"
                    data-pr-tooltip={`Grande`}
                    className={`large pi pi-image text-2xl ${sizes === 250 ? "text-zinc-50" : "text-zinc-400"
                        } hover:text-zinc-50 cursor-pointer`}
                ></i>
                {/* <span>

                    <i
                        onClick={() => navigate(`/Create-Event`)}
                        data-pr-position="left"
                        data-pr-tooltip={`Crear evento`}
                        className={`large pi pi-plus-circle text-2xl text-zinc-400 hover:text-zinc-50 cursor-pointer`}
                    ></i>
                </span> */}



                <Button
                    // type="button"
                    className="mr-2 hidden sm:block"
                    label="Crear evento"
                    onClick={() => navigate(`/Create-Event`)}
                // tooltip={"Crear evento"}
                />

            </section>

     
        </>
    );
}

export default HaaderSelectEvent;
