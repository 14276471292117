import Flat from "../../modules/mapdesign/FlatTest";
import Map from "../../modules/mapdesign/MapTest";
import useStructure from "../../Hooks/useStructure";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { useState } from "react";
import useDesign from "../../modules/mapdesign/hooks/useDesign";
import { Message } from "primereact/message";
import axios from "axios";
// import Joystick from "../../modules/mapdesign/Test";

function Design({ eventId }) {

    const [designing, setDesigning] = useState(false)

    const [localLoading, setLocalLoading] = useState(false)

    const { zones, selectZone, zone, data, loading, clearZone, inititalPositions, map2d, setMap2d } = useStructure({ eventId: eventId, flat: true, query: false })

    const { onChange, startingChanges, cleanChanges, save, position, designLoading, selectShape, selectedId } = useDesign({ eventId, inititalPositions: inititalPositions })

    const selected = []

    const restore = () => {
        cleanChanges()
    }

    const stopBuilding = () => {
        cleanChanges()
        selectShape(null)
        setDesigning(false)
    }

    const activeOrDesactiveMap2d = async () => {
        try {
            setLocalLoading(true)
            const url = `https://global-boletos-main-ts-server-2cmhx3gilq-uc.a.run.app/api/v2/events/active-or-desactive-2dmap`
            const request = await axios.put(url, { eventId: eventId, state: map2d ? true : false })
            if (request.data.isSucces) setMap2d(map2d ? false : true)
            setLocalLoading(false)

        } catch (err) {
            console.log(err)
            setLocalLoading(false)
        }
    }

    const handleClickInZone = (x) => {
        if (x.type === 'tables') {
            //    Codigo en condicional para zonas con mesas.
        }
        selectZone(x)
    }


    return (
        <div className="flex flex-col gap-2">

            <div className="card overflow-scroll sm:overflow-hidden  rounded-[4px] flex gap-2  justify-between">
                <div className="flex gap-2">
                    <Button
                        label={designing ? "Dejar de construir" : "Construir"}
                        disabled={zone ? true : false}
                        onClick={designing ? () => stopBuilding() : () => setDesigning(true)}
                        icon="pi pi-star"
                        // disabled={zone}
                        loading={false}
                    />
                    <Button
                        label="Guardar"
                        onClick={() => save({ structure: zone ? zone : 'flat' })}
                        disabled={!startingChanges}
                        icon="pi pi-cloud-upload"
                        loading={designLoading}
                    />
                    <Button
                        label="Restaurar"
                        disabled={!startingChanges}
                        icon="pi pi-replay"
                        onClick={() => restore()}
                        loading={designLoading}
                    />
                </div>

                <div className="flex gap-2">
                    <Button
                        label={`${map2d ? "Desactivar" : "Activar"}`}
                        onClick={() => activeOrDesactiveMap2d()}
                        // disabled={!zone}
                        icon="pi pi-power-off"
                        loading={localLoading}
                    />
                    <Button
                        label="Atras"
                        onClick={() => clearZone()}
                        disabled={!zone}
                        icon="pi pi-arrow-left"
                        loading={false}
                    />
                </div>

            </div>

            {
                zone && <Message severity="info" text="La construcción de estructuras dentro de una localidad aun no estan disponibles para este modulo. Para solicitar una personalización de la estructura contactenos " className='w-full mt-4' />
            }


            <div className="bg-[#1e1e1e] w-full overflow-hidden h-[70vh] rounded-[4px] mb-3" id='stage_container' >
                {/* <div className='stage_container' > */}
                {/* <Joystick/> */}
                {
                    loading ? <div className="w-full flex justify-center items-center h-[70vh]"><ProgressSpinner /> </div> : <>
                        {!zone && <Flat
                            zones={zones}
                            eventId={eventId}
                            design={designing}
                            onChange={onChange}
                            position={position}
                            selectZone={handleClickInZone}
                            selectedId={selectedId}
                            selectShape={selectShape}
                        />
                        }
                        {zone && <div>
                            <Map
                                data={data}
                                zone={zone}
                                selected={selected}
                                design={designing}
                                onChange={onChange}
                                position={position}
                                selectedId={selectedId}
                                selectShape={selectShape}
                            />
                        </div>}
                    </>
                }
                {/* </div> */}
            </div>
        </div>
    )
}

export default Design;

