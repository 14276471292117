import { useState, useEffect, useRef } from 'react';
import { Marker, Map, GoogleApiWrapper } from 'google-maps-react';
import { InputText } from 'primereact/inputtext';

const Contents = ({ google, map, onChange, errorMessage, defaultValue }) => {

  const [position, setPosition] = useState(null);
  const autocompleteRef = useRef(null);

  const renderAutoComplete = () => {
    if (!google || !map) return;

    const autocomplete = new google.maps.places.Autocomplete(
      autocompleteRef.current
    );
    autocomplete.bindTo('bounds', map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
      else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      setPosition(place.geometry.location);
    });
  }

  useEffect(() => {
    renderAutoComplete();
  }, [map, google])

  useEffect(() => {
    if (autocompleteRef && autocompleteRef.current) {
      if (position) {
        const addres = {
          positions: { lat: position.lat(), lng: position.lng() }, name: autocompleteRef.current.value
        }
        onChange(addres)
      }
    }
  }, [position])

  return (
    <div>
      <div>
        <InputText
          className='w-full mb-2'
          ref={autocompleteRef}
          type="text"
          defaultValue={defaultValue ? defaultValue.name : false}
          placeholder=''
        />

        {errorMessage && <span className="text-message-error text-red-600	">{errorMessage}</span>}


      </div>
      <div >
        <Map
          {...{ google, map }}
          center={position ? position : defaultValue.positions}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '50vh',
            position: 'relative',
            width: '100%'
          }}>

          {position ? <Marker position={position} /> : <>
            {
              defaultValue ? <Marker position={defaultValue.positions} /> : <></>
            }
          </>}

        </Map>
      </div>
    </div>
  );
}

const MapWrapper = props => (
  <Map
    className="map relative"
    google={props.google}
    visible={false}
    style={{ position: "relative" }}
    containerStyle={{
      position: 'relative',
      borderRadius: `6px`
    }}
  >
    <Contents {...props} />
  </Map>
)

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD2i9TPQyaoTGcql2ylRhNlbMtYM-FepmA'
})(MapWrapper)

