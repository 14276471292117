import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useNavigate } from "react-router-dom";
import useUser from "../../Hooks/useUser";

export default function Configuration() {
    const navigate = useNavigate()

    const home = { icon: 'pi pi-home', url: '/' }

    const items = [
        { label: 'Configuración' },
    ]

    const { userData } = useUser()


    return (
        <div className="grid">
            <BreadCrumb className='border-none text-sm mb-3' model={items} home={home} />
            <h3>Configuración empresarial</h3>

            {
                userData && <>

                    {
                        userData.producer && <>
                            <span className="text-gray-600">Tu empresa</span>
                            <Button
                                type="button"
                                className="w-[160px] border-none bg-transparent text-purple-400 text-sm text-left"
                                label="Datos de la cuenta"
                                onClick={() => navigate("/Configuration/Account")}
                                tooltip={"Actualiza tu nombre, tu dirección y otros datos"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#c084fc"
                                }}
                            />
                            <Button
                                type="button"
                                className="w-[190px] border-none bg-transparent text-purple-400 text-sm text-left"
                                label="Datos como organizador"
                                onClick={() => navigate("/Configuration/Public-data")}
                                tooltip={"Actualiza tus datos como organizador"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#c084fc"
                                }}
                            />
                            <Button
                                type="button"
                                className="w-[160px] border-none bg-transparent text-purple-400 text-sm text-left"
                                label="Imagen de marca"
                                onClick={() => navigate("/Configuration/Brand-image")}
                                tooltip={"Personaliza tu marca"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#c084fc"
                                }}
                            />
                            <Button
                                type="button"
                                className="w-[160px] border-none bg-transparent text-purple-400 text-sm text-left"
                                label="Cuentas vinculadas"
                                onClick={() => navigate("/Configuration/Linked-accounts")}
                                tooltip={"Ver, añadir o eliminar cuentas vinculadas"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#c084fc"
                                }}
                            />
                        </>
                    }

                    {
                        !userData.producer && <>
                            <Message severity="info" text="No tienes acceso a este modulo" className='w-full mt-4' />
                        </>
                    }

                </>
            }

        </div>
    )
}