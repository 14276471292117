import {
    Page,
    Text,
    View,
    Document,
    PDFDownloadLink,
} from "@react-pdf/renderer"
import { Button } from "primereact/button"
import useUser from "../../../Hooks/useUser"

const Title = ({ children }) => {
    return (
        <Text
            style={{
                width: "100%",
                textAlign: "center",
                marginTop: "15px",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "5px",
            }}
        >
            {children}
        </Text>
    )
}

const SubTitle = ({ children }) => {
    return (
        <Text
            style={{
                marginTop: "15px",
                fontSize: "11px",
                fontWeight: "700",
                marginBottom: "10px",
            }}
        >
            {children}
        </Text>
    )
}


export default function BasicContract({ event }) {

    const { userData } = useUser()


    return (
        <>
            <PDFDownloadLink
                document={
                    <Document >

                        <Page size="A4" style={{ margin: 30, paddingBottom: 30, paddingRight: 30 }}
                        >
                            <View
                                style={{
                                    marginTop: 20,
                                    marginleft: 30,
                                    marginRight: 30,
                                }}
                            >

                                <Title>CONTRATO DE SERVICIO DE BOLETERÍA PARA EVENTOS</Title>

                                <Text style={{ textAlign: "justify", fontSize: "11px", lineHeight: "2px", marginTop: "17px" }}>
                                    Entre, la Empresa Globalboletos, en adelante "LA EMPRESA", y el usuario {userData.email}, en adelante "EL CONTRATISTA", se acuerda realizar el presente contrato de servicio,
                                    bajo los siguientes términos: PRIMERO: Objeto:
                                    El objeto de este contrato consiste en la prestación de servicios de boletería
                                    por parte de Globalboletos a "EL CONTRATISTA" para el evento denominado {event.title}, que se llevará a cabo en la fecha
                                    {event.date.toLocaleDateString("es-ES", { timeZone: "America/Caracas" })}
                                    en {event.place}.
                                    SEGUNDO: Servicios de boletería: Globalboletos proporcionará a
                                    "EL CONTRATISTA" una plataforma en línea para la venta de boletos del evento. A través de dicha plataforma,
                                    "EL CONTRATISTA" podrá crear, personalizar y administrar los eventos, establecer precios de los boletos, gestionar
                                    las ventas y acceder a informes y estadísticas de venta. TERCERO: Responsabilidad de Globalboletos: Globalboletos se compromete a
                                    brindar un servicio confiable y seguro para la venta de boletos a través de la plataforma en línea. Globalboletos no será responsable
                                    de los aspectos relacionados con la producción, planificación, ejecución y logística del evento, ni de cualquier reclamo o disputa surgida entre
                                    "EL CONTRATISTA" y los asistentes al evento. CUARTO: Responsabilidad de "EL CONTRATISTA": "EL CONTRATISTA" será el único responsable de la organización,
                                    producción, promoción y ejecución del evento, incluyendo la contratación de artistas, proveedores y cualquier otra entidad relacionada con el evento. "EL CONTRATISTA"
                                    garantiza que cumple con todas las leyes y regulaciones aplicables y se compromete a indemnizar y mantener indemne a Globalboletos de cualquier reclamo o responsabilidad
                                    derivada del evento. QUINTO: Propiedad de la información: Globalboletos es propietaria de la plataforma en línea y de toda la información y datos generados a través de la
                                    misma, incluyendo los datos de los asistentes al evento. "EL CONTRATISTA" no podrá utilizar dicha información con fines comerciales o compartirla con terceros sin autorización
                                    previa de Globalboletos. SEXTO: Privacidad y protección de datos: Ambas partes se comprometen a cumplir con las leyes y regulaciones aplicables en materia de privacidad
                                    y protección de datos. Globalboletos recopilará y tratará los datos personales de los asistentes al evento de acuerdo con su política de privacidad, la cual está disponible
                                    en el sitio web de Globalboletos. SÉPTIMO: Terminación del contrato: Este contrato podrá ser terminado por cualquiera de las partes mediante notificación por escrito con
                                    al menos 30 días de anticipación. En caso de terminación, "EL CONTRATISTA" seguirá siendo responsable de cumplir con las obligaciones derivadas de
                                    los boletos vendidos hasta la fecha de terminación. OCTAVO: Ley aplicable y jurisdicción: Este contrato se regirá e interpretará de acuerdo con las leyes del pais en donde se dara a presentar el evento.
                                    Cualquier controversia o disputa surgida en relación con este contrato será sometida a los tribunales de {event.city}, renunciando ambas partes a cualquier otro fuero que
                                    pudiera corresponderles. DECIMOSEXTO: Cancelación del evento: En caso de que "EL CONTRATISTA" decida cancelar el evento, deberá notificar a Globalboletos por escrito con al
                                    menos 30 días de anticipación a la fecha programada del evento. En caso de cancelación, Globalboletos no será responsable de ninguna indemnización o reembolso a "EL CONTRATISTA
                                    " o a los asistentes al evento. DECIMOSÉPTIMO: Responsabilidad por cambios en el evento: Si "EL CONTRATISTA" realiza cambios en la fecha, hora, ubicación u otros aspectos del
                                    evento después de que los boletos hayan sido vendidos a través de la plataforma en línea, "EL CONTRATISTA" será responsable de notificar y hacer los arreglos necesarios para
                                    que los asistentes al evento sean informados de dichos cambios. DECIMOCTAVO: Disputas y resolución de conflictos: Cualquier disputa o controversia surgida del presente
                                    contrato será resuelta de manera amistosa entre las partes. En caso de no llegar a un acuerdo, las partes acuerdan someterse a la jurisdicción exclusiva de los tribunales
                                    competentes de la Ciudad de Caracas, Distrito Capital, renunciando a cualquier otro fuero que pudiera corresponderles. DECIMONOVENO: Divisibilidad: Si alguna disposición de este contrato se considera inválida, ilegal o inaplicable en cualquier jurisdicción, dicha disposición será eliminada o modificada en la medida necesaria para que el resto del contrato siga siendo válido y aplicable. VIGÉSIMO: Idioma: Este contrato se ha redactado en [idioma], y en caso de cualquier discrepancia en la interpretación entre la versión en [idioma] y cualquier traducción a otro idioma, prevalecerá la versión en [idioma].
                                    VIGÉSIMO PRIMERO: Configuración de la cuenta de productor: "EL CONTRATISTA" se compromete a proporcionar y mantener actualizada toda la información necesaria para configurar su
                                    cuenta de productor en la plataforma de Globalboletos. Esto incluye, pero no se limita a, los datos fiscales, la información de contacto, la dirección de facturación y cualquier
                                    otra información requerida por Globalboletos para cumplir con las obligaciones legales y fiscales. "EL CONTRATISTA" garantiza que la información proporcionada es precisa y
                                    verídica, y se compromete a informar de cualquier cambio o actualización de dicha información de manera oportuna. VIGÉSIMO SEGUNDO: Responsabilidad del contenido:
                                    "EL CONTRATISTA" declara y garantiza que todo el contenido, incluyendo imágenes, texto, videos y cualquier otro material proporcionado a Globalboletos para su publicación
                                    en la plataforma, es de su propiedad o cuenta con los derechos necesarios para su uso. "EL CONTRATISTA" será el único responsable de cualquier reclamo o disputa relacionada
                                    con el contenido proporcionado y acepta indemnizar y eximir de responsabilidad a Globalboletos por cualquier reclamación que surja de dicha utilización. VIGÉSIMO TERCERO:
                                    Propiedad intelectual: Todas las marcas registradas, logotipos, nombres comerciales y demás derechos de propiedad intelectual relacionados con la plataforma de Globalboletos
                                    son propiedad exclusiva de Globalboletos. "EL CONTRATISTA" se compromete a no utilizar dichos derechos sin la autorización previa y por escrito de Globalboletos. Cualquier
                                    uso no autorizado de los derechos de propiedad intelectual de Globalboletos dará lugar a la terminación inmediata del contrato y a la posible imposición de sanciones legales.
                                    VIGÉSIMO CUARTO: Modificación del contrato: Globalboletos se reserva el derecho de modificar los términos y condiciones de este contrato en cualquier momento, previa notificación a
                                    "EL CONTRATISTA". Dichas modificaciones serán efectivas a partir de la fecha especificada en la notificación. Si "EL CONTRATISTA" no está de acuerdo con las modificaciones propuestas,
                                    tiene el derecho de rescindir el contrato sin penalización, siempre y cuando lo notifique por escrito a Globalboletos dentro de un plazo de treinta (30) días a partir de
                                    la fecha de la notificación de las modificaciones.


                                    En prueba de conformidad, ambas partes estan de acuerdo con el contrato al iniciar la venta de mencionado evento.


                                </Text>

                            </View>

                        </Page>


                    </Document>
                }
                fileName={`Contrato base para ${event.title}.pdf`}
            >
                {({ blob, url, loading, error }) =>
                    <span className="">
                        <Button
                            label="Contrato base"
                            disabled={false}
                            icon="pi pi-cloud-download"
                            loading={false}
                        />
                    </span>
                }
            </PDFDownloadLink>
        </>
    )
}

