import axios from "axios";
import React, { useEffect, useState } from "react";

import Graphic from "./Graphics/Graphics";
import SkeletonHome from "./Skeleton/Skeleton";

import SalesHome from "./Sales/Sales";
import useUser from "../../Hooks/useUser";
import ModalResumenZone from "./Modals/ModalResumenZone";
import { serverBaseUrl } from "../../repository/config";

import useSalesData from "../../Hooks/useSaleData";
import useDate from "../../Hooks/useDate";
import DateFilter from "./DateFilter/DateFilter";
import SendMessage from "../../Components/Forms/SendMessage/SendMessage";
import RequestService from "./RequestService/RequestService";
import { useNavigate } from "react-router-dom";

function Resume() {

  const [showModal, setShowModal] = useState(false)
  const [onSelectEvent, setOnSelectEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const { userData } = useUser()
  const { setData, data, dataOriginal, dateFilter, setDateFilter, setDateRangeFilter, dateRangeFilter, onFilterDate, setDataOriginal } = useSalesData()

  const request = async ({ filters, date }) => {
    setLoading(true)
    try {
      const response = await axios.post(`${serverBaseUrl}/api/sales/event-report-for-creator`, {
        idEvents: userData.event,
        dateToFilter: date ? date : false,
        id: userData.uid,
        filterByRange: filters ? filters : false,
        chargeLockerServiceIn: userData.chargeLockerServiceIn ? userData.chargeLockerServiceIn : false,
      })
      setData(response.data)
      setDataOriginal(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userData) {
      if (userData.email === 'lacarlota@gb.com') {
        navigate('/Home')
      }
      if (userData.rol === "promotor") {
        request({ filters: false, date: false })
      }
    }
  }, [userData])

  const [daysAgo, setDaysAgo] = useState("todos")
  const { datesByDaysAgo } = useDate()

  function filterDateByDaysAgo(number) {
    const rangeDate = datesByDaysAgo(number);
    const filterRangeDate = dataOriginal?.sales?.filter(
      (x) =>
        new Date(x?.fechaDate) >= rangeDate?.start &&
        new Date(x?.fechaDate) <= rangeDate?.end
    )
    return filterRangeDate;
  }

  function restartData() {
    setData(dataOriginal)
  }

  const changeTimezone = (date) => {
    let formatter = new Intl.DateTimeFormat('en-US', { timeZone: "America/Caracas" });
    let usDate = formatter.format(date);
    return new Date(usDate)
  }

  const today = () => {
    onFilterDate()
    setDateFilter(null)
    setDateRangeFilter(null)
    request({ filters: [changeTimezone(new Date()), changeTimezone(new Date())], date: false })
  }


  return (
    <>


      <div>
        <DateFilter
          setData={setData}
          request={request}
          dateFilter={dateFilter}
          onFilterDate={onFilterDate}
          dataOriginal={dataOriginal}
          setDateFilter={setDateFilter}
          dateRangeFilter={dateRangeFilter}
          setDateRangeFilter={setDateRangeFilter}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-4   ">
        {!loading ? (
          <>
            {/* <ModalResumenZone
              onHide={() => {
                setShowModal(false)
                setOnSelectEvent(null)
              }}
              id={onSelectEvent}
              show={showModal}
              dataCourtesy={data.arrCourtesy}
              data={data?.sales}
              tickets={data?.tickets}
              ticketsForCourtesy={data?.ticketsForCourtesy}
              dataEvent={data?.events?.find(x => x.id === onSelectEvent)}
            /> */}
            <SalesHome
              data={data}
              request={request}
              setData={setData}
              today={today}
              setDateFilter={setDateFilter}
              section="resumen"
              daysAgo={daysAgo}
              restartData={restartData}
              setDaysAgo={setDaysAgo}
              filterDateByDaysAgo={filterDateByDaysAgo}
            />
            {userData?.email === 'lacarlota@gb.com' ? <></> : <>
              <SalesHome
                data={data}
                section="sales" />
              <Graphic
                data={data} />
            </>

            }

            <SalesHome
              data={data}
              section="toEvent"
              setShowModal={setShowModal}
              setOnSelectEvent={setOnSelectEvent}
            />
            <SalesHome
              data={data}
              section="vendors"
            />
            <SalesHome
              data={data}
              section="zones"
            />
            <SalesHome
              data={data}
              section="zonesForDiscount"
            />
            {/* <SalesHome
              data={data}
              section="totalsForZone"
            /> */}
            <div className="mt-5 col-span-3 "></div>
          </>
        ) : (
          <SkeletonHome />
        )}
      </div>
    </>
  );
}

export default Resume
