import { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import useUser from '../../../Hooks/useUser'
import { InputText } from 'primereact/inputtext'
import { BreadCrumb } from 'primereact/breadcrumb'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProgressSpinner } from 'primereact/progressspinner'
import useProducerUser from '../../../Hooks/useProducerUser'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'
import { Image } from 'primereact/image';
import { SketchPicker } from 'react-color';
import { FileUploadImg } from './FileUploadImg'
import { OverlayPanel } from 'primereact/overlaypanel'


export default function BrandImage() {

    const items = [
        { label: 'Configuración' },
        { label: 'Imagen de marca' },
    ];

    const home = { icon: 'pi pi-home', url: '/' }

    const toast = useRef()

    const op = useRef(null)


    const [loading, setLoading] = useState(false)

    const { userData } = useUser()

    const [data, setData] = useState(false)

    const [stateColor, setStateColor] = useState("#fff")

    const { getBrandImage, brandImageConfiguration } = useProducerUser()


    const onSubmit = async () => {
        try {
            setLoading(true)
            const response = await brandImageConfiguration({ producerId: userData.producer, data: { ...data, brandColor: stateColor } })
            if (response) {
                if (response.isSuccess) {
                    toast.current.show({ severity: 'info', summary: 'Exito', detail: 'Datos ajustados' })
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }



    const handleChangeStateColor = (c) => {
        setStateColor(c.hex)
    }

    const requestBrandImageConfiguration = async () => {
        try {
            setLoading(true)
            const request = await getBrandImage({ id: userData.producer })
            if (request) {
                if (request.logotipo) setData({ logotipo: request.logotipo })
                if (request.brandColor) setStateColor(request.brandColor)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const onChangeImage = (x) => {
        if (x) setData({ ...data, logotipo: x })
    }

    const cleanImage = () => {
        setData({ ...data, logotipo: false })
    }
    useEffect(() => {
        if (userData) requestBrandImageConfiguration()
    }, [userData])

    return (<>
        <>
            <div className="grid">
                <BreadCrumb className='border-none text-sm mb-4' model={items} home={home} />
                <div>
                    {
                        loading ? <>
                            <div className="w-full  h-[40vh] flex justify-center items-center">
                                <ProgressSpinner />
                            </div>
                        </> : <>
                            <div className=' card mb-4'>
                                <div className='mt-4 grid gap-3'>
                                    <div className='grid items-center justify-center grid-cols-3'>
                                        <label className='text-sm  '>Logotipo</label>
                                        <div className='col-span-2 flex justify-end relative'>

                                            {
                                                data ? <>
                                                    {data.logotipo ? <>
                                                        <div className='absolute z-[1] right-0'>
                                                            <Button
                                                                onClick={() => cleanImage()}
                                                                icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined " aria-label="Cancel"
                                                            />
                                                        </div>
                                                        <Image
                                                            src={data.logotipo}
                                                            alt="Image"
                                                            className='m-auto'
                                                            width="250"
                                                            preview />
                                                    </> : <div className='w-full'>
                                                        <FileUploadImg setImg={onChangeImage} title="Logotipo" route="PRODUCER_BRANDS" />
                                                    </div>}

                                                </> : <div className='w-full'>
                                                    <FileUploadImg setImg={onChangeImage} title="Logotipo" route="PRODUCER_BRANDS" />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className='grid items-center justify-center grid-cols-3'>
                                        <label className='text-sm '>Color de la marca</label>
                                        <div className='  col-span-2 flex justify-end '>
                                            <div className="p-inputgroup">

                                                <span className="p-inputgroup-addon cursor-pointer p-0" onClick={(e) => op.current.toggle(e)}>

                                                    <div className='w-full h-full' style={{ background: `${stateColor}` }}></div>
                                                </span>
                                                <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: '300px' }} className="overlaypanel-demo flex justify-center">
                                                    <SketchPicker
                                                        color={stateColor}
                                                        onChange={handleChangeStateColor}
                                                    />
                                                </OverlayPanel>
                                                <span className="p-float-label">
                                                    <InputText id="inputgroup" type="text" value={stateColor} />
                                                    {/* <label htmlFor="inputgroup">{stateColor}</label> */}
                                                </span>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <Divider />
                                <div className='flex justify-end'>
                                    <Button
                                        label="Guardar"
                                        icon="pi pi-save"
                                        onClick={() => onSubmit()}
                                        loading={loading}
                                    ></Button>
                                </div>
                            </div>

                        </>
                    }
                </div>
            </div>
        </>
        <Toast ref={toast}></Toast>
    </>
    )
}