import { useState } from "react";

function useSalesData() {

    const [data, setData] = useState({})
    const [dataOriginal, setDataOriginal] = useState({})
    const [loading, setLoading] = useState(true);
    const [dateFilter, setDateFilter] = useState(null);
    const [dateRangeFilter, setDateRangeFilter] = useState(null);

    function onFilterDate() {
        if (dateFilter || dateRangeFilter?.length) {
            setData({ ...data, sales: dataOriginal.sales.filter(x => String(x.fecha) === dateFilter.toLocaleDateString('en-ES')) })
            setLoading(true);
        } else {
            setLoading(true);

        }
    }

    return {
        data,
        setData,
        loading,
        dateFilter,
        dataOriginal,
        onFilterDate,
        setDateFilter,
        setDataOriginal,
        dateRangeFilter,
        setDateRangeFilter,
    };
}

export default useSalesData;
