import { Props } from "../../../types/propTypes";
import { UseFormRegister } from 'react-hook-form'
import { InputHTMLAttributes } from 'react'
import { InputText } from "primereact/inputtext"



interface InputProps extends Props, InputHTMLAttributes<HTMLInputElement> {
  register?: any
  textlLabel?: string | null
  info?: string | null | undefined
  defaultValue?:string
  errorMessage?: string
  variant?: 'small' | 'big' | 'extra'
}

export default function TextFieldInput(props: InputProps) {

  const { variant ,textlLabel, info, errorMessage, id, register, ...rest } = props


  return (
    <div className="grid">
      {textlLabel && <label htmlFor={id} className="text-gb-gray-900" >{textlLabel}</label>}
      <InputText
        className={`gb-input ${variant?  variant : ''}`}
        id={id}
        {...rest}
        {...register}
      />
      {info && <span> {info} </span>}
      {errorMessage && <span className="text-message-error text-red-600	">{errorMessage}</span>}
    </div>
  )
}
