import { Skeleton } from "primereact/skeleton";

function SkeletonHome() {
  return (
    <>
      <Skeleton className="w-full col-span-full lg:col-span-2  h-auto" width="100%" height="auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 p-4 gap-4 h-full justify-center items-center"
        >
          <Skeleton width="100%" height="80px" className="w-full h-[80px]"></Skeleton>
          <Skeleton width="100%" height="80px" className="w-full h-[80px]"></Skeleton>
          <Skeleton width="100%" height="80px" className="w-full h-[80px]"></Skeleton>
          <Skeleton width="100%" height="80px" className="w-full h-[80px]" ></Skeleton>
        </div>
      </Skeleton>
      <Skeleton className="w-full col-span-full lg:col-span-2  h-auto p-4 px-4" width="100%" height="auto">
        <Skeleton className=" w-[300px] " width="300px"></Skeleton>
        <Skeleton className=" w-full h-[240px] mt-3 " width="100%" height="240px" ></Skeleton>
      </Skeleton>
      <Skeleton className="w-full col-span-full lg:col-span-4 h-auto p-2 " width="100%" height="auto">
        <Skeleton className="w-[300px]" width="300px"></Skeleton>
        <div className="flex gap-4">
          <Skeleton className=" w-[80px] h-[30px] mt-2 " width="80px" height="30px"></Skeleton>
          <Skeleton className=" w-[80px] h-[30px] mt-2 " width="80px" height="30px"></Skeleton>
        </div>
        <Skeleton className=" w-full mt-5 h-[260px]  " width="100%" height="260px"></Skeleton>
      </Skeleton>
      <Skeleton className="w-full col-span-full lg:col-span-4 h-auto p-2 " width="100%" height="auto">
        <Skeleton className=" w-[300px] " width="300px"></Skeleton>
        <div className="flex gap-4">
          <Skeleton className=" w-[80px] h-[30px] mt-2 " width="80px" height="30px"></Skeleton>
          <Skeleton className=" w-[80px] h-[30px] mt-2 " width="80px" height="30px"></Skeleton>
        </div>
        <Skeleton className=" w-full mt-5 h-[260px]  " width="100%" height="260px"></Skeleton>
      </Skeleton>
      <Skeleton className="w-full col-span-full lg:col-span-2 h-[300px] " width="100%" height="300px"></Skeleton>
      <Skeleton className="w-full col-span-full lg:col-span-2 h-[300px] " width="100%" height="300px"></Skeleton>
    </>
  )
}

export default SkeletonHome
