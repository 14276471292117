import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Stage, Layer, Group, Rect, Text, Transformer } from 'react-konva';
import SeatLabel from '../SeatLabel';
import Table from '../Table';
import useLabel from "../hooks/useLabel";
import Seat from '../Seat';
import MagnifyingGlassMinus from "../icons/index";
// const handleCircleClick = (e) => {
//   console.log('Clicked on Circle');
//   e.cancelBubble = false; // Detiene la propagación del evento
// };
import "../index.css";
import { Button } from 'primereact/button';
import useMultiSelect from '../hooks/useMultiSelect';
import Rectangle from '../Rectangle';
import Typography from '../Typography';

const StageV2 = ({ data, selected, select, zone, onChange, position, selectedId, selectShape }) => {

    const { type, structure } = data

    const design = false

    const stageRef = useRef(null);

    const {
        onMouseDown,
        onMouseUp,
        onMouseMove,
        checkDeselect,
        onClickTap,
        layerRef,
        trRef,
        selectionRectRef
    } = useMultiSelect({ design, stageRef })

    const containerStage = document.getElementById('stage_container')
    const width = containerStage.offsetWidth - 100
    const height = containerStage.offsetHeight;
    const elementSize = type === 'chairs' ? 0.5 : 50
    const margin = type === 'chairs' ? 20 : 35
    const draggable = design

    const elementsInRows = useMemo(() => {

        if (type === 'tables') {
            let columns = 0;
            structure.forEach(row => {
                const columnsInRow = row.tables.length;
                if (columnsInRow > columns) columns = columnsInRow;
            });
            return columns;
        } else if (type === 'chairs') {
            let columns = 0;
            structure.forEach(row => {
                const columnsInRow = row.seats.length;
                if (columnsInRow > columns) columns = columnsInRow;
            });
            return columns;
        }

    }, [data]);

    const totalWidthOfElements = elementsInRows * (elementSize + margin);
    const totalHeightOfElements = type === 'chairs' ? (30 * (structure.length - 1)) + 100 : (140 * (structure.length - 1)) + 170;

    // Calcular la escala necesaria para mostrar todos los elementos centrados
    const scaleX = width / totalWidthOfElements;
    const scaleY = height / totalHeightOfElements;
    const scale = Math.min(scaleX, scaleY);

    // Calcular las coordenadas para centrar los elementos
    const offsetX = (width - totalWidthOfElements * scale) / 2;
    const offsetY = (height - totalHeightOfElements * scale) / 2;

    const selectChair = useCallback(() => {
        if (select) select()
    }, [])

    const onWheel = (e) => {
        const stage = stageRef.current
        const scaleBy = 1.01;
        e.evt.preventDefault();

        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        let direction = e.evt.deltaY > 0 ? 1 : -1;

        if (e.evt.ctrlKey) {
            direction = -direction;
        }

        const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        stage.scale({ x: newScale, y: newScale });

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
    }

    const handleZoomInElement = () => {

        const stage = stageRef.current

        const oldScale = stage.scaleX();

        const position = stage.getPointerPosition();
        const pointTo = {
            x: position.x / oldScale - stage.x() / oldScale,
            y: position.y / oldScale - stage.y() / oldScale,
        };

        const newScale = oldScale === 1 ? 2 : 2; // Cambiar el factor de escala según tus necesidades

        stage.to({
            scaleX: newScale,
            scaleY: newScale,
            x: -(pointTo.x - position.x / newScale) * newScale,
            y: -(pointTo.y - position.y / newScale) * newScale,
            duration: 0.4, // Duración de la animación en milisegundos
        })
        stage.batchDraw();
    }

    const handleZoomOut = () => {

        const stage = stageRef.current.getStage();

        stage.to({
            scaleX: scale,
            scaleY: scale,
            x: offsetX,
            y: offsetY,
            duration: 0.2, // Duración de la animación en milisegundos
        })
        stage.batchDraw();

    }

    const { showLabel, handleShowLabel, closeShowLabel } = useLabel()



    return <div className='stage_container' >
        <div className='stage_container_buttons'>
            <Button className='flex justify-center items-center z-[1]' onClick={() => handleZoomOut()} >
                <MagnifyingGlassMinus size={25} />
            </Button>
        </div>
        <Stage
            // width={window.innerWidth + 400}
            // height={window.innerHeight + 400}
            width={width}
            height={height}
            ref={stageRef}
            draggable={design ? false : true}
            scaleX={scale}
            scaleY={scale}
            x={offsetX}
            y={offsetY}
            onWheel={design ? null : onWheel}
            onClick={design ? onClickTap : handleZoomInElement}
            onTap={design ? null : handleZoomInElement}
            onMouseUp={design ? onMouseUp : null}
            onMouseDown={design ? onMouseDown : null}
            onMouseMove={design ? onMouseMove : null}
            onTouchStart={design ? checkDeselect : null}
        >
            <Layer ref={design ? layerRef : null}>
                <Group>
                    <Rectangle
                        radius={5}
                        fill="#a782c9"
                        name='rectangle'
                        strokeWidth={5}
                        stroke="#A9A9A9"
                        id={`Escenario-${zone}`}
                        cornerRadius={5}
                        design={design}
                        onChange={onChange}
                        selectShape={selectShape}
                        isSelected={selectedId === `Escenario-${zone}` ? true : false}
                        width={position({ parameter: "width", id: `Escenario-${zone}`, defaultPosition: totalWidthOfElements })}
                        height={position({ parameter: "height", id: `Escenario-${zone}`, defaultPosition: 60 })}
                        x={position({ parameter: "x", id: `Escenario-${zone}`, defaultPosition: 0 })}
                        y={position({ parameter: "y", id: `Escenario-${zone}`, defaultPosition: 0 })}
                    />




                    <Typography
                        design={design}
                        name='rectangle'
                        padding={8}
                        fill="black"
                        align="center"
                        fontStyle='bold'
                        text={`${zone}`}
                        verticalAlign="center"
                        onChange={onChange}
                        selectShape={selectShape}
                        id={`Escenario-${zone}-Text`}
                        isSelected={selectedId === `Escenario-${zone}-Text` ? true : false}
                        x={position({ parameter: "x", id: `Escenario-${zone}-Text`, defaultPosition: 0 })}
                        y={position({ parameter: "y", id: `Escenario-${zone}-Text`, defaultPosition: 15 })}
                        height={position({ parameter: "height", id: `Escenario-${zone}-Text`, defaultPosition: 50 })}
                        fontSize={position({ parameter: "fontSize", id: `Escenario-${zone}-Text`, defaultPosition: 24 })}
                        width={position({ parameter: "width", id: `Escenario-${zone}-Text`, defaultPosition: totalWidthOfElements })}
                    />

                    {
                        type === "tables" && structure.map((x, index) => <React.Fragment key={index}>
                            {
                                x.tables.map((y, table) => <>

                                    <Table
                                        zone={zone}
                                        row={index}
                                        key={`F${index}-M${table}`}
                                        margin={35}
                                        initialX={0}
                                        text={y.position}
                                        onChange={onChange}
                                        selectShape={selectShape}
                                        index={table}
                                        seats={y.seats}
                                        position={position}
                                        selectedId={selectedId}
                                        selected={selected}
                                        draggable={draggable}
                                        elemetSize={elementSize}
                                        selectChair={selectChair}
                                        handleShowLabel={handleShowLabel}
                                        closeShowLabel={closeShowLabel}
                                    />
                                </>)
                            }
                        </React.Fragment>)
                    }

                    {
                        type === "chairs" && structure.map((x, index) => <React.Fragment key={index}>
                            {
                                x.seats.map((z, seat) => {

                                    const x = seat + 0.3
                                    const y = 90 + (index * 30)

                                    return <>
                                        <Seat
                                            y={y}
                                            data={z}
                                            key={seat}
                                            row={z.row}
                                            type={type}
                                            position={position}
                                            selectedId={selectedId}
                                            text={z.position}
                                            selectChair={selectChair}
                                            x={x * (elementSize + margin)}
                                            closeShowLabel={closeShowLabel}
                                            handleShowLabel={handleShowLabel}
                                            onChange={onChange}
                                            selectShape={selectShape}
                                            isSelected={selected.some(z => z.id === z.id)}
                                        />
                                    </>
                                })
                            }
                        </React.Fragment>)
                    }



                    {showLabel && !design && (<SeatLabel showLabel={showLabel} />)}

                    {
                        design && <>
                            <Transformer ref={trRef} boundBoxFunc={(oldBox, newBox) => {
                                if (newBox.width < 5 || newBox.height < 5) {
                                    return oldBox;
                                }
                                return newBox;
                            }} />
                            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
                        </>
                    }

                </Group>

            </Layer>
        </Stage>
    </div>;
};

export default StageV2





