import { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import useUser from '../../../Hooks/useUser'
import { InputText } from 'primereact/inputtext'
import { BreadCrumb } from 'primereact/breadcrumb'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProgressSpinner } from 'primereact/progressspinner'
import useProducerUser from '../../../Hooks/useProducerUser'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'

export default function Account() {

    const items = [
        { label: 'Configuración' },
        { label: 'Datos de la cuenta' },
    ];

    const home = { icon: 'pi pi-home', url: '/' }

    const toast = useRef()

    const [loading, setLoading] = useState(true)

    const { userData } = useUser()

    const [data, setData] = useState(false)

    const { configureAccountDetails, confirationAccountDetails } = useProducerUser()

    const onSubmit = async () => {
        try {
            setLoading(true)
            const response = await configureAccountDetails({ producerId: userData.producer, accountName: data.accountName, commercialAddress: data.commercialAddress })
            if (response) {
                if (response.isSuccess) {
                    toast.current.show({ severity: 'info', summary: 'Exito', detail: 'Datos ajustados' })
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error en petición' })
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const requestConfirationAccountDetails = async () => {
        try {
            const request = await confirationAccountDetails({ id: userData.producer })
            console.log(request)
            if (request) {
                if (request.commercialAddress && request.accountName) {
                    setData({ ...data, accountName: request.accountName, commercialAddress: request.commercialAddress })
                } else {
                    if (request.accountName) setData({ ...data, accountName: request.accountName })
                    if (request.commercialAddress) setData({ ...data, commercialAddress: request.commercialAddress })
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userData) requestConfirationAccountDetails()
    }, [userData])

    return (<>
        <>
            <div className="grid">
                <BreadCrumb className='border-none text-sm mb-4' model={items} home={home} />
                <div className='card'>
                    {
                        loading ? <>
                            <div className="w-full  h-[40vh] flex justify-center items-center">
                                <ProgressSpinner />
                            </div>
                        </> : <>
                            <div className='mt-4 grid gap-3'>
                                <div className='grid items-center justify-center grid-cols-2'>
                                    <label>Nombre de la cuenta</label>
                                    <InputText value={data?.accountName} name="accountName" onChange={handleInputChange} />
                                </div>
                                <div className='grid items-center justify-center grid-cols-2'>
                                    <label>Dirección comercial</label>
                                    <InputTextarea value={data?.commercialAddress} rows={5} cols={30} autoResize name="commercialAddress" onChange={handleInputChange} />
                                </div>
                            </div>
                            <Divider />
                            <div className='flex justify-end'>
                                <Button
                                    label="Guardar"
                                    icon="pi pi-save"
                                    onClick={() => onSubmit()}
                                    loading={loading}
                                ></Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
        <Toast ref={toast}></Toast>
    </>
    )
}