import React from 'react';
import { useState } from 'react';
import useDate from '../../../Hooks/useDate';
import SalesResumen from './SalesResumen/SalesResumen';
import SalesTable from './SalesTable/SalesTable';
import SalesToEvent from './SalesToEvent/SalesToEvent';
import SalesToVendors from './SalesToVendors/SalesToVendors';
import SalesToZone from './SalesToZone/SalesToZone';
import SalesToDiscount from './SalesToDiscount/SalesToDiscount';
import TotalsForZone from './TotalsForZone/TotalsForZone';



function SalesHome({ request, data,today, setData, restartData, section, setOnSelectEvent, setShowModal, daysAgo, setDaysAgo, filterDateByDaysAgo, setDateFilter, text }) {

    return (<>
        {section === 'vendors' && <><SalesToVendors data={data} text={text} /></>}
        {section === 'resumen' && <>
            <SalesResumen
                request={request}
                data={data}
                today={today}
                daysAgo={daysAgo}
                setDateFilter={setDateFilter}
                setData={setData}
                setDaysAgo={setDaysAgo}
                restartData={restartData}
                filterDateByDaysAgo={filterDateByDaysAgo}
            /></>}
        {section === 'sales' && <><SalesTable data={data} /></>}
        {section === 'zones' && <><SalesToZone data={data} text={text} /></>}
        {section === 'zonesForDiscount' && <><SalesToDiscount data={data} /></>}
        {  section === 'totalsForZone' && <><TotalsForZone data={data} /></>}
        {section === 'toEvent' && <SalesToEvent data={data} text={text} setShowModal={setShowModal} setOnSelectEvent={setOnSelectEvent} />}
    </>);
}

export default SalesHome;