import React from "react";
import { Rect, Transformer } from "react-konva";
import "../index.css"
import Zone from "./Zone"
import { memo, useCallback } from "react";
import Rectangle from "../Rectangle";
import useUtils from "../hooks/useUtils";
import { Group, Layer, Stage } from "react-konva";
import Typography from "../Typography";
import useMultiSelect from "../hooks/useMultiSelect";

function Flat({ selectZone, zones, design, onChange, position, selectShape, selectedId }) {

    const { scale, offsetX, offsetY, getZoneHeightAndColor, width, height } = useUtils()
    const { onMouseDown, onMouseUp, onMouseMove, checkDeselect, onClickTap, layerRef, trRef, selectionRectRef} = useMultiSelect({ design })

    const select = useCallback((x) => {
        if (selectZone) selectZone(x)
    }, [])

    let accumulatedHeight = 110;

    return (
        <>
            <Stage
                x={offsetX}
                y={offsetY + 10}
                width={width}
                height={height}
                scaleX={scale}
                scaleY={scale}
                draggable={design?false:true}
                // width={window.innerWidth + 400}
                // height={window.innerHeight + 400}
                onClick={design ? onClickTap : null}
                onMouseUp={design ? onMouseUp : null}
                onMouseDown={design ? onMouseDown : null}
                onMouseMove={design ? onMouseMove : null}
                onTouchStart={design ? checkDeselect : null}
            >
                <Layer ref={design ? layerRef : null}>

                    <Rectangle
                        fill="#FFF"
                        id="Escenario"
                        design={design}
                        name='rectangle'
                        cornerRadius={8}
                        onChange={onChange}
                        selectShape={selectShape}
                        isSelected={selectedId === "Escenario" ? true : false}
                        width={position({ parameter: "width", id: "Escenario", defaultPosition: 300 })}
                        height={position({ parameter: "height", id: "Escenario", defaultPosition: 100 })}
                        x={position({ parameter: "x", id: "Escenario", defaultPosition: 0 })}
                        y={position({ parameter: "y", id: "Escenario", defaultPosition: 0 })}
                    />

                    <Typography
                        design={design}
                        id="Escenario-Text"
                        name='rectangle'
                        padding={8}
                        fill="black"
                        align="center"
                        fontStyle='bold'
                        text={'ESCENARIO'}
                        verticalAlign="center"
                        onChange={onChange}
                        selectShape={selectShape}
                        isSelected={selectedId === "Escenario-Text" ? true : false}
                        width={position({ parameter: "width", id: "Escenario-Text", defaultPosition: 300 })}
                        height={position({ parameter: "height", id: "Escenario-Text", defaultPosition: 50 })}
                        x={position({ parameter: "x", id: "Escenario-Text", defaultPosition: 0 })}
                        y={position({ parameter: "y", id: "Escenario-Text", defaultPosition: 30 })}
                        fontSize={position({ parameter: "fontSize", id: "Escenario-Text", defaultPosition: 24 })}
                    />

                    {
                        zones.zones.map((x, index) => {


                            const zoneHeight = getZoneHeightAndColor(x.aforo, zones.capacity, x.zone)
                            const y = accumulatedHeight
                            accumulatedHeight = accumulatedHeight + zoneHeight.height - 15

                            return (
                                <Zone
                                    y={y}
                                    zone={x}
                                    key={index}
                                    width={300}
                                    name={x.zone}
                                    design={design}
                                    position={position}
                                    onChange={onChange}
                                    fill={zoneHeight.color}
                                    selectShape={selectShape}
                                    height={zoneHeight.height - 25}
                                    select={selectZone ? select : null}
                                    isSelected={selectedId === x.zone ? true : false}
                                    textSelect={selectedId === `${x.zone}-text` ? true : false}
                                // rectangleProps={{
                                //     // onSelect: (e) => {
                                //     //     console.log('ON SELECT')
                                //     //     if (e.current !== undefined) {
                                //     //         let temp = nodesArray;
                                //     //         if (!nodesArray.includes(e.current)) temp.push(e.current);
                                //     //         setNodes(temp);
                                //     //         trRef.current.nodes(nodesArray);
                                //     //         trRef.current.nodes(nodesArray);
                                //     //         trRef.current.getLayer().batchDraw();
                                //     //     }
                                //     //     selectShape('rect1'); // Cambié rect.id a 'rect1' para que coincida con el ID
                                //     // },
                                //     // // Otras props de rectangleProps si las hay
                                // }}
                                />
                            )
                        })
                    }

                    {
                        design && <>
                            <Transformer ref={trRef} boundBoxFunc={(oldBox, newBox) => {
                                if (newBox.width < 5 || newBox.height < 5) {
                                    return oldBox;
                                }
                                return newBox;
                            }} />
                            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
                        </>
                    }

                </Layer>
            </Stage>
        </>
    )
}


export default Flat
