import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function FormControl({ data, orderInputClassName, title, separator, general }) {
    return (
        <div>
            <h4>{title ?? ""}</h4>
            <div className={orderInputClassName ?? ""}>
                {data.map((el, key) => {
                    return (<React.Fragment key={key}>
                        {
                            el?.visible === undefined || el?.visible === true ?
                                <>
                                    <div className={`my-4 ${el?.className} `} >
                                        <label htmlFor={el.id} className="block font-bold">
                                            {el.label}
                                        </label>
                                        {el?.type === "textarea" ? (
                                            <InputTextarea
                                                className={`${el.className} w-full`}
                                                value={el.value}
                                                id={el.id}

                                                placeholder={el.placeholder}
                                                onChange={(e) => el.onChange(e.target.value)}
                                                autoResize
                                            />
                                        ) : el?.type === "calendar" ? (
                                            <Calendar
                                                id={el.id}
                                                inputClassName={`${el.inputClassName} w-full`}
                                                className={`${el.className} w-full`}
                                                value={el.value}
                                                placeholder={el.placeholder}
                                                onChange={(e) => el.onChange(el.value)}
                                                showIcon
                                            />
                                        ) : el?.type === "select" ? (
                                            <Dropdown
                                                id={el.id}
                                                className={`${el.className} w-full`}
                                                value={el.value}
                                                options={el.options}
                                                emptyMessage={el.emptyMessage}
                                                placeholder={el.placeholder}
                                                onChange={(e) => el.onChange(e.value)}
                                                optionValue={el.optionValue}
                                                optionLabel={el.optionLabel}
                                            />
                                        ) : el?.type === "chip" ? (
                                            <Chips
                                                className={"w-full p-fluid "}
                                                id={el.id}
                                                value={el.value}
                                                placeholder={el.placeholder}
                                                onChange={(e) => el.onChange(el.value)}
                                                separator=","
                                            />
                                        ) : el?.type === "number" ? (
                                            <InputNumber
                                                className={`${el.className} w-full`}
                                                inputClassName={`${el.inputClassName} w-full`}
                                                inputId={el.id}
                                                value={el.value}
                                                placeholder={el.placeholder}
                                                onChange={(e) => {
                                                    el.onChange(e.value);
                                                }}

                                                mode={el.mode ?? "decimal"}
                                                useGrouping={el.useGrouping ?? true}
                                                minFractionDigits={2}
                                            />
                                        ) : el?.type === "component" ? (
                                            <div className="flex w-full items-center">
                                                <p className="p-4 w-[60%]">{el.value}</p>
                                                <Button
                                                    className="text-xs"
                                                    label="Agregar al total"
                                                    onClick={el.onClick}
                                                />
                                            </div>
                                        ) :
                                            el?.type === "component-delete" ? (
                                                <div className="flex w-full items-center">

                                                    <Button
                                                        className="w-full mt-0 p-button-danger"
                                                        icon={el.icon}
                                                        onClick={() => el.onClick(key)}

                                                    />
                                                </div>
                                            ) :
                                                (
                                                    <InputText

                                                        className={`${el.className} w-full`}
                                                        value={el.value}
                                                        id={el.id}
                                                        placeholder={el.placeholder}
                                                        onChange={(e) => el.onChange(e.target.value)}
                                                        autoResize
                                                    />
                                                )}
                                    </div>
                                </> : ''
                        }
                    </React.Fragment >
                    );

                })}
            </div>
            {separator && <hr className=" border-black/30" />}
        </div>
    );
}

export default FormControl;
