export const creditCardImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Ftarjeta%20de%20credito.png?alt=media&token=4a18d103-b87e-4a6c-857f-a0b07ebe5c8d"

export const binanceImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fbinance-logo-1.png?alt=media&token=564c1a6c-df81-414f-864a-10d61d115a3f"

export const zelleImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fzelle.png?alt=media&token=dcf424f0-790f-403c-8d80-0050381a5d2f"

export const mobilePaymentsImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fpago%20movil.png?alt=media&token=64835ec8-8508-4814-8433-0467bf749bdd"

export const zinliImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fzinli.png?alt=media&token=b0965f43-74a8-4b2f-bb01-92cbb55f4e61"

export const reserveImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Freserve.png?alt=media&token=39b2b386-bde6-4ab0-8a0d-22c714e421f5"

export const bdvImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpaymentMethods%2FbancoDeVenzuelaLogoNuevo.png?alt=media&token=7bfdf7e7-b1dd-48d0-82f1-2c926c63d26f"

export const patriaImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpatriaLogo.png?alt=media&token=9794d0be-a6fc-400b-81d4-2686c450153e"

export const metamaskImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpaymentMethods%2Fmetamask.png?alt=media&token=11a1270c-ee2b-4870-b95f-774d0cfca771"

export const mobilePayment = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fpago%20movil.png?alt=media&token=64835ec8-8508-4814-8433-0467bf749bdd"

export const cuentaNacional="https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpaymentMethods%2Fcuenta%20nacional.png?alt=media&token=8bc3b64a-d550-4b02-889d-315f52f80b48"

export const cuentaInternacional="https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpaymentMethods%2Fcuenta%20nacional.png?alt=media&token=8bc3b64a-d550-4b02-889d-315f52f80b48"


export const nequiImg = "https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2FpaymentMethods%2Fnequi-removebg-preview.webp?alt=media&token=0815d231-03d0-48a7-b124-75e6f13c08e9"