import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

export default function DateFilter({ setDateFilter, dateFilter, onFilterDate, setDateRangeFilter, setData, dataOriginal, dateRangeFilter, request, noRange }) {

    const filterExists = (key) => {
        if (key === "range") {
            if (dateFilter) return true
        }
        if (key === "date") {
            if (dateRangeFilter) return true
        }
        return false
    }

    const handleClickRangeFilter = () => {
        request({ filters: dateRangeFilter, date: false })
    }

    const handleClickDateFilter = () => {
        request({ filters: false, date: dateFilter })
    }

    const clearFilter = () => {
        onFilterDate()
        setDateFilter(null)
        setData(dataOriginal)
        setDateRangeFilter(null)
        request({ filters: false, date: false })
    }

    return (
        <div className="w-full card  flex flex-col lg:flex-row gap-2 mb-5 items-center">

            {noRange && <div className="field">
                <span className="p-float-label">
                    <Calendar
                        id="date"
                        name="date"
                        onChange={(e) => setDateFilter(e.target.value)}
                        value={dateFilter}
                        inputClassName="h-[40px] border-[#ffffff]/20 w-full"
                        className="h-[40px] w-full"
                        disabled={filterExists("date")}
                        showIcon
                    />
                    <label htmlFor="date">Fecha</label>
                </span>
            </div>}

            {
                !noRange && <div className="field">
                    <span className="p-float-label">
                        <Calendar
                            id="rangeDate"
                            name="date"
                            selectionMode="range"
                            disabled={filterExists("range")}
                            onChange={(e) => setDateRangeFilter(e.target.value)}
                            value={dateRangeFilter}
                            inputClassName="h-[40px] border-[#ffffff]/20 w-full"
                            className="h-[40px] w-full"
                            showIcon
                        />
                        <label htmlFor="date">Rango de Fecha</label>
                    </span>
                </div>
            }

            <div className="field">
                <span className="p-float-label">

                    {
                        dateRangeFilter && dateRangeFilter.length > 1 && <Button
                            label="Filtrar"
                            onClick={() => {
                                handleClickRangeFilter()
                            }}
                            icon="pi pi-filter"
                        />
                    }
                    {
                        dateFilter && <Button
                            label="Filtrar"
                            onClick={() => {
                                handleClickDateFilter()
                            }}
                            icon="pi pi-filter"
                        />
                    }

                    {dateRangeFilter?.length || dateFilter ? (
                        <Button
                            label="Limpiar Filtro"
                            onClick={() => clearFilter()}
                            className="ml-2"
                            icon="pi pi-filter-slash"
                        />
                    ) : null}
                </span>
            </div>
            <div className="field ml-auto">
                <span className="p-float-label">
                    <p>{""}</p>
                </span>
            </div>
        </div>
    )
}