import React from 'react';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { logout } from '../../Firebase/client';
import useUser from '../../Hooks/useUser';
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import { useRef } from 'react';

function HeaderBar({ showDrawer, setShowDrawer }) {

    const op = useRef(null);

    const { userData } = useUser()

    return (<>
        <header className='w-full  bg-[#1e1e1e] shadow-lg z-30 h-[50px] fixed flex justify-end items-center p-3 gap-4 z-[1005]'>
            {
                userData && <>
                    {
                        userData.rol==="promotor" ?
                            <div className='flex items-center'>
                                {/* <NotificationPanel op={op} /> */}
                                {/* <Avatar label="G" className="mr-2" size="small" shape="circle" /> */}
                                <p className='text-xs'>{userData ? userData.accountName ? userData.accountName : "PRODUCTOR" : "PRODUCTOR"}</p>
                            </div>
                            : <></>
                    }
                    <Button onClick={() => logout()} icon="pi pi-sign-out " className=' h-[30px] p-button-text p-button-plain text-white' />
                    {showDrawer ? (
                        <div className="p-button contents lg:hidden p-component p-button-text  text-white p-button-icon-only" onClick={() => setShowDrawer(false)}>
                            <span className="p-button-icon p-c pi pi-times"></span>
                            <span className="p-button-label p-c">&nbsp;</span>
                        </div>
                    ) : (
                        <Button
                            className="p-button-text  text-white  contents lg:hidden"
                            icon="pi pi-bars"
                            onClick={() => setShowDrawer(true)}
                        />
                    )}
                </>
            }
        </header>
    </>)
}

export default HeaderBar;