import React from "react";
import TemplateSalesToEvent from "./TemplateSalesToEvent";

function SalesToEvent({ data, setOnSelectEvent, setShowModal, text }) {
  return (<section className="card col-span-full lg:col-span-2 w-full">
    <h5 className="font-bold mb-5">{text ? text : "Total boletos vendidos por evento"}</h5>
    <section className=" grid grid-cols-1 gap-y-4 ">
      {data?.events?.map((x, key) => {
        const boletos = data.getTotals.ticketsForEvent[x.id]?data.getTotals.ticketsForEvent[x.id]:0
        return (
          <React.Fragment key={key}>
            <div className="cursor-pointer w-full rounded hover:bg-black/20 h-auto border-[0px] border-b border-[#ffffff]/5 p-1 " onClick={() => {
              setOnSelectEvent(x.id)
              setShowModal(true)
            }}>
              <TemplateSalesToEvent length={boletos} aforo={x?.aforo ?? 0} event={x.title} />
            </div>
          </React.Fragment>
        )
      })}
    </section>
  </section>
  )
}

export default SalesToEvent;
