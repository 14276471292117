import { query, orderBy, getDocs, collection } from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../Config/firebaseConfig";
import { formatDate } from "./useDateFormat";

export default function useDisputedInvoices({ eventId, noEffect }) {

    const [loading, setLoading] = useState(true)
    const [disputedSales, setDisputedSales] = useState(false)


    const requestDisputedSales = async () => {
        try {
            setLoading(true)
            const q = query(collection(db, "events", eventId, "disputed-invoices"), orderBy("startOfDispute", "desc"));
            const querySnapshot = await getDocs(q);
            const arr = querySnapshot.docs.map((doc) => {
                const datas = doc.data();
                const id = doc.id;
                const idInvoice = datas.saleTag.lastTenDiginId
                // date: formatDate(datas.cancellationDate),
                // hour: formatDateTime(datas.cancellationDate)
                const hour = formatDate(datas.startOfDispute)
                return {
                    ...datas,
                    id,
                    hour: hour,
                    idInvoice: idInvoice
                }
            });
            setLoading(false)
            setDisputedSales(arr)
        } catch (err) {
            console.log(err)
            setLoading(false)
            setDisputedSales([])
        }
    }


    useEffect(() => {
        if (!noEffect) {
            requestDisputedSales()
        }
    }, [eventId])

    return { loading, disputedSales }
}