import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Text, Transformer } from "react-konva";

const SimpleTypography = ({ ...props }) => {
    return (<>
        <Text {...props} />
    </>)
}

const DesignTypography = ({ onChange, id, selectShape, isSelected, ...props }) => {


    const shapeRef = useRef();
    const trRef = useRef()

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const handleSelect = () => {
        // trRef.current.nodes([shapeRef.current]);
        // trRef.current.getLayer().batchDraw();
        selectShape(id)
    }

    const onTransformEnd = () => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        console.log(node.fontSize())

        node.scaleX(1);
        node.scaleY(1);

        const originalFontSize = 24; // Tamaño de fuente original del texto
        const newFontSize = originalFontSize * scaleX;


        onChange({
            id: id,
            x: node.x(),
            y: node.y(),
            fontSize: newFontSize,
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
        });
        selectShape(null)
    }

    const onDragEnd = (e) => {
        onChange({
            id: id,
            x: e.target.x(),
            y: e.target.y(),
        });
        selectShape(null)
    }

    return (<Fragment>
        <Text
            {...props}
            draggable
            ref={shapeRef}
            onTap={handleSelect}
            onClick={handleSelect}
            onDragEnd={(e) => onDragEnd(e)}
            onTransformEnd={(e) => onTransformEnd()}
            onMouseEnter={null}
            onMouseLeave={null}
        />
        {isSelected && (
            <Transformer
                ref={trRef}
                boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                }}
            />
        )}
    </Fragment>)
}


function Typography({ design, ...props }) {

    if (design) return <DesignTypography {...props} />

    return <SimpleTypography  {...props} />
}

export default memo(Typography)