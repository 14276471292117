import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClosingReceipt from "../../Components/Files/Closures";
import Receipt from "../../Components/Files/Receipts";
import { fetchClosure, fetchReceipt } from "../../Firebase/client";

export default function Closure() {

    let { id, billing } = useParams()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(
        {
            addres: '',
            createdAt: new Date(),
            income: {},
            ratesUsed: [],
            impressions: 0,
            refUsed: [],
            tickets: { keys: [], ticketsByZoneObject: {} },
            returnAndInsurance: { totalInReturns: [], totalsRefundProtecion: [] }
        }
    )

    const request = async () => {
        try {
            setLoading(true)
            const response = await fetchClosure({ id: id, billing: billing })
            if (response) {
                setLoading(false)
                setData({
                    ...response,
                    impressions: response.impressions ? response.impressions : 0,
                    refUsed: response.refUsed ? response.refUsed : [],
                    ratesUsed: response.ratesUsed ? response.ratesUsed : [],
                })
            }
        } catch (err) {
            console.log(err)
            // setLoading(false)
        }
    }

    useEffect(() => {
        request()
    }, [])


    if (loading) return <></>

    return (
        <ClosingReceipt data={data} />
    )
}