
import { getAuth, signOut, signInWithEmailAndPassword, sendEmailVerification, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { db } from "../Config/firebaseConfig"

import { collection, doc, query, getDoc, setDoc, where, orderBy, getDocs } from "firebase/firestore";
import axios from "axios";
import { serverBaseUrl } from "../repository/config";

const auth = getAuth();

export const USER_STATES = {
    NOT_LOGGED: null,
    NOT_KNOW: undefined,
}

export const sendResetPassword = async (email) => {
    try {
        const response = await sendPasswordResetEmail(auth, email);
        return true
        // console.log('Se ha enviado un correo electrsónico de restablecimiento de contraseña');
    } catch (error) {
        console.error('Error al enviar el correo electrónico de restablecimiento de contraseña:', error);
        return false
    }
};

export const createUser = async ({ email, password }) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;
        const addUser = await axios.post(`${serverBaseUrl}/api/authentication/create-producer-user`, { email: String(email).toLowerCase(), password: password, uid: user.uid })
        if (addUser.data.isSuccess) {
            await sendEmailVerification(auth.currentUser)
            return { isSuccess: false, message: "confirmacion-email" }
            // return addUser.data
        } else {
            return ({ isSuccess: false })
        }
    } catch (err) {
        console.log(err)
        return ({ isSuccess: false })
    }
}

export const loginUser = async (email, password, fixed) => {
    try {
        const res = await signInWithEmailAndPassword(auth, String(email).toLowerCase(), password)
        const user = res.user;

        if (!String(email).includes("@gb.com")) {
            if (!user.emailVerified && !fixed) {
                await sendEmailVerification(auth.currentUser)
                await signOut(auth)
                return { isSuccess: false, message: "el usuario no se ha confirmado exitosamente" }
            }
        }

        const producer = await fetchUser(String(email).toLowerCase())
        if (producer) {
            return { ...producer[0], contraseña: null, direccion: null, isSuccess: true }
        } else {
            const addUser = await axios.post(`${serverBaseUrl}/api/authentication/create-producer-user`, { email: String(email).toLowerCase(), password: password, uid: user.uid })
            if (addUser.data.isSuccess) {
                console.log(addUser.data.user)
                return { ...addUser.data.user, contraseña: null, direccion: null, isSuccess: true }
            } else {
                return { isSuccess: false }

            }
        }
    } catch (err) {
        if (err.message) {
            if (err.message === `Firebase: Error (auth/wrong-password).`) return { isSuccess: false, message: "Contraseña incorrecta" }
            if (err.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).") {
                return { isSuccess: false, message: "Cuenta bloqueada" }
            }
        }
        console.log(err.message ? err.message : err)
        return { isSuccess: false }
    }
}

export const logout = async () => {
    let res = await signOut(auth).then((res) => {
        localStorage.clear()
        if (window.location.hash !== "#/SignIn" && window.location.hash !== "#/Signin") {
            window.location = "/"
        }
        return true
    }).catch(err => {
        return err
    })
    if (res) {
        return true
    } else {
        return false
    }
}

const mapUserFromFirebaseAuth = (user) => {
    // localStorage.setItem('jwt', JSON.stringify(user))
    return user
}
export const onAuthState = (onChange) => {

    return auth
}

export const fetchEvents = async () => {
    const q = query(collection(db, "events"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {

        const data = doc.data()
        const id = doc.id
        const { date } = data
        return {
            ...data, id, date: date ? formatDate(date) : false,
        }
    });
}

export const fetchEvent = async (id) => {

    const docRef = doc(db, "events", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data()
        const { date, startOfSale } = data
        return { ...docSnap.data(), id: docSnap.id, date: formatDate(date), startOfSale: false }
    } else {
        // doc.data() will be undefined in this case
        return false
    }
}

export const fetchProducerInformation = async (id) => {
    const docRef = doc(db, "users-dashboard", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}

export const fetchProducerPublicData = async (id) => {
    const docRef = doc(db, "users-dashboard", id, "public-data", "public-data");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}
export const fetchBrandImage = async (id) => {
    const docRef = doc(db, "users-dashboard", id, "brand-image", "brand-image");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}

export const fetchConfigureAccountDetails = async ({ id }) => {
    const docRef = doc(db, "users-dashboard", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}

export const fetchUser = async (email) => {

    const users = collection(db, "users-dashboard");
    const q = query(users, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        logout()
        return false
    } else {

        const results = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id, password: null
            }
        })

        const user = results[0]

        const refPublicData = doc(db, 'users-dashboard', user.id, 'public-data', 'public-data')
        const queryPublicData = await getDoc(refPublicData)

        if (queryPublicData.exists()) {
            return [{ ...user, publicData: true }]
        }

        return [{ ...user, publicData: false }]

    }
}

export const fetchTables = async (collec, fila) => {
    const tables = query(collection(db, collec))
    const q = query(tables, where("fila", "==", fila));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const id = doc.id
        return {
            ...data, id
        }
    });

}

export const formatDate = (item) => {
    return new Date((item.seconds + item.nanoseconds * 10 ** -9) * 1000)
}

export const fetchChair = async (collection, id) => {
    const docRef = doc(db, collection, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data()
        const { operationExpiration, selectionStartTime } = data
        return { ...docSnap.data(), selectionStartTime: formatDate(selectionStartTime), operationExpiration: formatDate(operationExpiration) }
    } else {
        // doc.data() will be undefined in this case
        return false
    }
}

export const fetchSale = async (id) => {
    const docRef = doc(db, "sales", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        const { date, startOfSale } = data
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        // doc.data() will be undefined in this case
        return false
    }
}

export const getTables = async (event, inputType, row) => {
    const tables = collection(db, "events", event, "tables");
    const q = query(tables, where("zona", "==", inputType), orderBy("positions", "asc"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return false
    }
    else {
        return querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id
            }
        });
    }
}

export const totalSaleInday = async ({ event, puestoTrabajo, date }) => {
    const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    const clock = date ? date : new Date()
    const year = clock.getFullYear()
    const month = clock.getMonth()
    const day = clock.getDate()

    const formatReportDate = `${months[month]} ${day}`

    const q = query(collection(db, "events", event, "sales"), where("lugarDeVenta", "==", puestoTrabajo), where("formatFacturationDate", "==", formatReportDate));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) return []
    return querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const id = doc.id
        const { fecha } = data
        return {
            ...data, id: id, date: formatDate(fecha)
        }
    });
}

export const fetchSeller = async (id) => {
    const docRef = doc(db, "vendors", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data()
        const { date, startOfSale } = data
        return { ...docSnap.data(), id: docSnap.id, Contraseña: null }
    } else {
        return false
    }
}

export const fetchDollar = async () => {
    const docRef = doc(db, "utils", "dollar");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}

export const savePrePrinted = async ({ start, end, user, event }) => {
    const id = start + "-" + end
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const clock = new Date()
    const year = clock.getFullYear()
    const month = clock.getMonth()
    const hour = clock.getHours()
    const minutes = clock.getMinutes()
    const day = clock.getDate()
    const formatReportDate = ` ${year} ${months[month]} ${day}  ${hour}:${minutes > 9 ? minutes : "0" + minutes} ${hour > 12 ? "pm" : "am"}`
    const reportSingleDate = `${year + months[month] + day + hour}:${minutes > 9 ? minutes : "0" + minutes}${hour > 12 ? "pm" : "am"}`
    return await setDoc(doc(db, "events", event, "prePrinted", id + "-" + reportSingleDate), {
        start: parseFloat(start),
        end: parseFloat(end),
        date: clock,
        user: user,
        dateFormat: formatReportDate
    });
}

export const fetchReportPrePrinted = async (id, event) => {

    const q = query(collection(db, "events", event, "prePrinted"), where("user", "==", id), orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const id = doc.id
        const { date } = data
        return {
            ...data, id, date: formatDate(date)
        }
    });

}

export const fetchVendors = async () => {
    const vendors = collection(db, "vendors");
    const q = query(vendors);
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return false
    }
    else {
        return querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id
            }
        });
    }
}

export const fetchInsuranceOperations = async ({ eventId }) => {
    const q = query(collection(db, "events", eventId, "sales"), where("refundProtection", "==", true));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return []
    }
    else {
        return querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id
            }
        });
    }
}

export const fetchOperations = async ({ eventId }) => {
    const q = query(collection(db, "events", eventId, "sales"), orderBy("fecha", "desc"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return []
    }
    else {
        return querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id
            }
        });
    }
}

export const fetchZone = async ({ eventId, zone }) => {
    console.log({ eventId, zone })
    const users = collection(db, `/events/${eventId}/chairs`)

    const q = query(users, where("zona", "==", zone));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        return false
    }
    else {
        return querySnapshot.docs.map((doc) => {
            const data = doc.data()
            const id = doc.id
            return {
                ...data, id
            }
        });
    }
}




export const getZones = async ({ eventId }) => {
    try {

        const docRef = doc(db, "events", eventId, "config-and-spech", `${eventId}-place-map`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data()
            return { ...data }
        } else {
            // doc.data() will be undefined in this case
            return false
        }

    } catch (err) {
        console.log(err)
    }
}

export const fetchReceipt = async ({ id, billing }) => {

    const docRef = doc(db, "vendors", id, "movements", billing, "detail", "detail");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

        const data = docSnap.data()
        const { paymentDate } = data
        return { ...data, id: docSnap.id, date: formatDate(paymentDate) }

    } else {

        const docRef2 = doc(db, "users-dashboard", id, "movements", billing, "detail", "detail");
        const docSnap2 = await getDoc(docRef2);

        if (docSnap2.exists()) {

            const data = docSnap2.data()
            const { paymentDate } = data
            return { ...data, id: docSnap.id, date: formatDate(paymentDate) }

        } else {
            return false
        }
    }

}


export const fetchClosure = async ({ id, billing }) => {
    const docRef = doc(db, "vendors", id, "closures", billing);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        const { createdAt } = data
        return { ...data, id: docSnap.id, createdAt: formatDate(createdAt) }
    } else {
        return false
    }
}