import "./style.css";
import React from "react";
import Logo from "../../assets/Logo";
import { Button } from "primereact/button";
import { useState } from "react";
import EnterEmail from "./EnterEmail";
import axios from "axios";
import { serverBaseUrl } from "../../repository/config";
import RegisterUser from "./RegisterUser";
import RegisterPassword from "./RegisterPassword";
import { createUser, loginUser, sendResetPassword } from "../../Firebase/client";
import { useNavigate } from "react-router-dom";
import { useSessionAuthContext } from "../../Context/session-auth-context";
import EnterPassword from "./EnterPassword";
import { Message } from 'primereact/message';
import SuccessRegister from "./SuccessRegister";

const STEPS = {
    START: 1,
    ENTER_EMAIL: 2,
    ENTER_PASSWORD: 3,
    REGISTER_USER: 4,
    REGISTER_PASSWORD: 5,
    SUCCESS_REGISTER: 6
}

export default function SignIn() {

    const [step, setStep] = useState(STEPS.START)
    const [loading, setLoading] = useState(false)
    const [dataForm, setDataForm] = useState({})
    const [error, setError] = useState(false)
    const [reset, setReset] = useState(false)
    const navigate = useNavigate()
    const { setUserData } = useSessionAuthContext()

    const validateEmail = async (data) => {
        try {
            setError(false)
            setLoading(true)
            const request = await axios.post(`${serverBaseUrl}/api/authentication/is-email-registered`, { email: String(data.email).toLowerCase() })
            if (request.data.isSuccess) {
                if (request.data.email) {
                    setStep(STEPS.ENTER_PASSWORD)
                } else {
                    setStep(STEPS.REGISTER_USER)
                }
            }
            setLoading(false)
            setDataForm({ ...dataForm, email: data.email })
        } catch (err) {
            setLoading(false)
        }
    }

    const validateDataUser = async (data) => {
        try {
            setLoading(true)
            setDataForm({ ...dataForm, name: data.name, lastName: data.lastName })
            setStep(STEPS.REGISTER_PASSWORD)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const registerUser = async (data) => {
        try {
            setLoading(true)
            const user = { ...dataForm, password: data.password }
            const request = await createUser({ email: user.email, password: user.password })
            if (request.isSuccess) {
                navigate('/')
            } else {
                if (request.message === 'confirmacion-email') setStep(STEPS.SUCCESS_REGISTER)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const userLogin = async ({ password }) => {
        try {
            setReset(false)
            setError(false)
            localStorage.clear()
            if ('caches' in window) {
                caches.keys()
                    .then(function (keyList) {
                        return Promise.all(keyList.map(function (key) {
                            return caches.delete(key);
                        }));
                    })
            }
            setLoading(true)
            const response = await loginUser(dataForm.email, password)
            if (response.isSuccess) {
                setUserData(response)
                if (response.rol) {
                    if (response.rol === "aseguradora") {
                        navigate("/Insurance")
                        return null
                    }
                    if (response.rol === "delivery") {
                        navigate("/Delivery")
                        return null
                    }
                }
                navigate('/')
                setLoading(false)
            } else {
                setError(response.message ? response.message : "Ocurrio un error")
                setLoading(false)
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const resetPassword = async () => {
        try {
            setReset(false)
            setLoading(true)
            const response = await sendResetPassword(dataForm.email)
            if (response) setReset(true)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setError('Error en enviar correo de recuperación')
            setLoading(false)
        }
    }

    const goBack = () => {
        switch (step) {
            case STEPS.REGISTER_USER:
                setStep(STEPS.ENTER_EMAIL)
                return;
            case STEPS.REGISTER_PASSWORD:
                setStep(STEPS.REGISTER_USER)
                return;
            case STEPS.ENTER_PASSWORD:
                setStep(STEPS.ENTER_EMAIL)
                return;
            default:
                return;
        }
    }

    return (
        <section className="w-full h-full grid grid-cols-1 lg:grid-cols-2">
            <div className="left-half"></div>
            <div className="right-half lg:p-4 ">
                <div className=" text-center mt-[3rem]">
                    <Logo className="m-auto" />
                </div>
                <div className="right-half-container w-[80%] m-auto text-center mt-[4rem] flex flex-col	gap-[2rem]">
                    {step === STEPS.START && <>
                        <h3 className="font-bold">¡Publica, promociona y vende tus eventos!</h3>
                        <Button label="Continuar con correo" icon="pi pi-envelope" className="w-[100%]" onClick={() => setStep(STEPS.ENTER_EMAIL)} />
                        <span>
                            Al iniciar sesión, acepto la política de privacidad y los términos de servicio de globalboletos.com
                        </span>
                    </>}
                    {
                        step !== STEPS.START && step !== STEPS.ENTER_EMAIL && <>
                            <Button
                                icon="pi pi-angle-left"
                                className='h-[30px] p-button-text text-white'
                                onClick={() => goBack()}
                            />
                        </>
                    }
                    {step === STEPS.ENTER_EMAIL && <>
                        <h3 className="font-bold">Comencemos</h3>
                        <span>
                            Usar el correo electrónico para empezar
                        </span>
                        <EnterEmail onSubmit={validateEmail} loading={loading} />
                    </>}
                    {step === STEPS.REGISTER_USER && <>
                        <h3 className="font-bold">Bienvenido</h3>
                        <span>¡Vamos a crear tu cuenta!</span>
                        <RegisterUser onSubmit={validateDataUser} loading={loading} />
                    </>}

                    {step === STEPS.REGISTER_PASSWORD && <>
                        <h3 className="font-bold">Un paso más cerca</h3>
                        <span>Crear contraseña para su cuenta</span>
                        <RegisterPassword onSubmit={registerUser} loading={loading} />
                    </>}

                    {step === STEPS.ENTER_PASSWORD && <>
                        <h3 className="font-bold">Bienvenido de nuevo</h3>
                        {/* <span>Crear contraseña para su cuenta</span> */}
                        <EnterPassword onSubmit={userLogin} loading={loading} />
                        <div className="flex flex-col gap-3">
                            <span className="text-right text-gray-500 cursor-pointer" onClick={() => resetPassword()}>Recuperar contraseña</span>

                            {
                                reset && <Message severity="info" text={`Correo para recuperación enviado`} className="w-full text-center justify-start" />
                            }
                        </div>
                    </>}
                    {
                        step === STEPS.SUCCESS_REGISTER && <SuccessRegister setStep={setStep} STEPS={STEPS} />
                    }

                    <div className="grid gap-1">


                        {
                            error && <Message severity="error" text={`${error}`} className="w-full text-left justify-start" />
                        }

                        {error && error === "el usuario no se ha confirmado exitosamente" && <Message className="w-full justify-start" severity="info" text="Se ha enviado un correo de confirmacion"></Message>}
                    </div>

                </div>
            </div>
        </section>
    );
}
