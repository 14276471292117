// export const serverBaseUrl = "http://localhost:5000"

export const serverBaseUrl = "https://global-boletos-socket-io-server-2-2cmhx3gilq-uc.a.run.app"

export const methodsInBs = [
    "puntoDeVenta",
    "pagoMovil",
    "Debito Ahorro Vnzla",
    "Debito Corriente Vnzla",
    "vzlaTransfer",
    "Credito Mastercard Vnzla",
    "Credito Visa Vnzla",
    "Monedero Patria",
    "efectivoBs",
]

export const methodsInDollars = [
    "binance",
    "zelle",
    "zinli",
    "paypal",
    "efectivo",
    "reserve",
    "tarjetaDeCredito"
]

export const methodsInCOP = [
    "efectivoCOP",
    "nequi",
]

export const images = {
    concert: "/assets/image/SRC/CONCIERTO.webp",
    sports: "/assets/image/SRC/DEPORTES.jpg",
    general:"/assets/image/SRC/GENERAL.jpg",
    chairs:"/assets/image/SRC/SILLAS.jpg",
    tables:"/assets/image/SRC/MESAS.jpeg",
    turismo:"/assets/image/SRC/TURISMO.jpg"
}

