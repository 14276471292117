
export default function useUtils() {



    const containerStage = document.getElementById('stage_container')

    // const width = 300;
    const width = containerStage.offsetWidth - 100

    const height = containerStage.offsetHeight;

    const totalWidthOfElements = 300
    const totalHeightOfElements = height + 100


    // Calcular la escala necesaria para mostrar todos los elementos centrados
    const scaleX = width / totalWidthOfElements;
    const scaleY = height / totalHeightOfElements;
    const scale = Math.min(scaleX, scaleY);

    // Calcular las coordenadas para centrar los elementos
    const offsetX = (width - totalWidthOfElements * scale) / 2;
    const offsetY = (height - totalHeightOfElements * scale) / 2;

    const getColorForName = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16);
        return `#${'0'.repeat(6 - color.length)}${color}`;
    };

    const getZoneHeightAndColor = (aforoZona, aforoMaximoTotal, name) => {

        const capacity = aforoZona > 400 ? aforoZona : 400
        const porcentajeAforo = (capacity / aforoMaximoTotal) * 100;
        const heightForZone = 80

        // Generar un color pastel al azar
        const randomColor = getColorForName(name)

        return {
            height: heightForZone,
            color: randomColor,
        };
    }


    return { scale, offsetX, offsetY, getZoneHeightAndColor, width, height }
}