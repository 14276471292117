import React, { useContext, useState } from "react";
const SessionAuthContext = React.createContext();

//=============================================================================================================================================================================================================================================================================================================================================================================================================================================================
//                                                                                                                                                                                                                                                                                                                                                                                                                                                             
//  ##   ##   ####  #####             ####   #####   ##     ##  ######  #####  ##    ##  ######                                                                                                                                                                                                                                                                                                                                                              
//  ##   ##  ##     ##               ##     ##   ##  ####   ##    ##    ##      ##  ##     ##                                                                                                                                                                                                                                                                                                                                                                
//  ##   ##   ###   #####  ########  ##     ##   ##  ##  ## ##    ##    #####    ####      ##                                                                                                                                                                                                                                                                                                                                                                
//  ##   ##     ##  ##               ##     ##   ##  ##    ###    ##    ##      ##  ##     ##                                                                                                                                                                                                                                                                                                                                                                
//   #####   ####   #####             ####   #####   ##     ##    ##    #####  ##    ##    ##                                                                                                                                                                                                                                                                                                                                                                
//                                                                                                                                                                                                                                                                                                                                                                                                                                                             
//=============================================================================================================================================================================================================================================================================================================================================================================================================================================================

export const useSessionAuthContext = () => {
  const context = useContext(SessionAuthContext);
  return context;
};

//=====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================
//                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
//   ####   #####   ##     ##  ######  #####  ##    ##  ######            #####   #####     #####   ##   ##  ##  ####    #####  #####                                                                                                                                                                                                                                                                                                                                                                
//  ##     ##   ##  ####   ##    ##    ##      ##  ##     ##              ##  ##  ##  ##   ##   ##  ##   ##  ##  ##  ##  ##     ##  ##                                                                                                                                                                                                                                                                                                                                                               
//  ##     ##   ##  ##  ## ##    ##    #####    ####      ##    ########  #####   #####    ##   ##  ##   ##  ##  ##  ##  #####  #####                                                                                                                                                                                                                                                                                                                                                                
//  ##     ##   ##  ##    ###    ##    ##      ##  ##     ##              ##      ##  ##   ##   ##   ## ##   ##  ##  ##  ##     ##  ##                                                                                                                                                                                                                                                                                                                                                               
//   ####   #####   ##     ##    ##    #####  ##    ##    ##              ##      ##   ##   #####     ###    ##  ####    #####  ##   ##                                                                                                                                                                                                                                                                                                                                                              
//                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
//=====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================


function SessionAuthProvider({ children }) {
  const [userData, setUserData] = useState(false);
  const value = { userData, setUserData }

  return <SessionAuthContext.Provider value={{ ...value }}>{children}</SessionAuthContext.Provider>;
}

export default SessionAuthProvider;
