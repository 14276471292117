import React from 'react'

export default function Rombo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 24 24"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12 23.928a4.99 4.99 0 01-3.536-1.462l-6.93-6.931a5 5 0 010-7.07l6.93-6.931a5.008 5.008 0 017.072 0l6.93 6.931a5 5 0 010 7.07l-6.93 6.931A4.99 4.99 0 0112 23.928zm0-21.857a2.992 2.992 0 00-2.122.877l-6.93 6.931a3 3 0 000 4.242l6.93 6.931a3 3 0 004.244 0l6.93-6.931a3 3 0 000-4.242l-6.93-6.931A2.992 2.992 0 0012 2.071z"
                data-original="#000000"
            ></path>
        </svg>
    )
}
