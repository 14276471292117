import "primereact/resources/themes/mdc-dark-indigo/theme.css"; //theme
// import "primereact/resources/themes/arya-purple/theme.css";     
// import "primereact/resources/themes/mdc-dark-deeppurple/theme.css"
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./index.css";
import Layout from "./Layout/Layout";
import { HashRouter, Route, Routes } from "react-router-dom";
import LogIn from './Pages/LogIn/LogIn'
import Configuration from "./Pages/Configuration/Configuration";
import LinkedAccounts from "./Pages/Configuration/LinkedAccounts/LinkedAccounts";
import SelectEvent from "./Components/SelectEvent/SelectEvent"
import EventOptions from "./Components/EventOptions/EventOptions";
import Account from "./Pages/Configuration/Account/Account";
import PublicData from "./Pages/Configuration/PublicData/PublicData";
import BrandImage from "./Pages/Configuration/BrandImage/BrandImage";
import HomeInsurance from "./Pages/Insurance/Home/Home";
import EventOptionsForInsurance from "./Components/Insurance/EventOptions/EventOptions";
import HomeDelivery from "./Pages/Delivery/Home/Home";
import EventOptionsForDelivery from "./Components/Delivery/EventOptions/EventOptions";
import SignIn from "./Pages/SignIn";
import AddNewEvent from "./modules/event-generator/pages/AddNewEvent";
import File from "./Pages/File";
import Closure from "./Pages/Closure";
import Resume from "./Pages/Resume/Home";

function App() {


  return (
    <HashRouter>
      <Layout >
        <Routes >

          <Route path="/" element={<SelectEvent />}></Route>
          <Route path="/Home" element={<SelectEvent />}></Route>
          <Route path="/Resume" element={<Resume />}></Route>
          <Route path="/File/:id/:billing" element={<File />}></Route>
          <Route path="/closing/:id/:billing" element={<Closure />}></Route>
          <Route path="/login" element={<LogIn />}></Route>
          <Route path="/SignIn" element={<SignIn />}></Route>
          <Route path="/Create-Event" element={<AddNewEvent />}></Route>
          <Route path="/Select-Event" element={<SelectEvent />}></Route>
          <Route path="/Events/:eventId" element={<EventOptions />}></Route>
          <Route path="/Configuration" element={<Configuration />}></Route>
          <Route path="/Configuration/Linked-accounts" element={<LinkedAccounts />}></Route>
          <Route path="/Configuration/Account" element={<Account />}></Route>
          <Route path="/Configuration/Public-data" element={<PublicData />}></Route>
          <Route path="/Configuration/Brand-image" element={<BrandImage />}></Route>
          {/* INSURANCE ROUTES */}
          <Route path="/Insurance" element={<HomeInsurance />}></Route>
          <Route path="/Insurance/Event/:eventId" element={<EventOptionsForInsurance />}></Route>
          {/* DELIVERY ROUTES */}
          <Route path="/Delivery" element={<HomeDelivery />}></Route>
          <Route path="/Delivery/Event/:eventId" element={<EventOptionsForDelivery />}></Route>
        </Routes>
      </Layout>
    </HashRouter>
  );
}

export default App;
