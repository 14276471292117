import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../Config/firebaseConfig";


export class SalesRepository{
  
  


    async getSalesEvent(eventId) {
     
        const q = query(collection(db, "events",eventId,"sales"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          //        const { date } = data
          return {
            ...data,

            id,
          };
        });
    }
}