import React, { useEffect, useState } from "react";

import useUser from "../../../Hooks/useUser";
import { useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";
import { EventRepository } from "../../../repository/event.repository";
import HaaderSelectEvent from "../../SelectEvent/HeaderSelectEvent/HaaderSelectEvent";

const repository = new EventRepository();

export default function SelectEventForInsurance({ children }) {

    const itemsBreadCrumb = [
        { label: `Eventos` },
    ]

    const home = { icon: 'pi pi-building', url: '/Insurance' }

    const navigate = useNavigate()

    const onSelectEvent = (id) => {
        navigate(`/Insurance/Event/${id}?nav=resumen`)
    }

    const { userData } = useUser()

    const [events, setEvents] = useState([])
    const [eventStaticData, setEventStaticData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [sizes, setSizes] = useState({
        size: 250,
        width: {
            number: 250,
            class: "h-[250px]",
        },
        heigth: {
            number: 250,
            class: "h-[250px]",
        },
    });

    function changeSize(size, className) {
        setSizes((e) => {
            return {
                ...e,
                size,
                width: { number: size, class: className },
                heigth: { number: size, class: className },
            }
        })
    }

    useEffect(() => {
        if (userData) {
            Promise.resolve(
                repository.getEventos().then((res) => {
                    const eventArr = []
                    res.forEach((y => {
                        const permissionForThisEvent = userData.event.some(w => w === y.id)
                        if (permissionForThisEvent) eventArr.push(y)
                    }))
                    setEvents(eventArr)
                    setEventStaticData(eventArr)
                }).then(() => setLoading(false))
            )
        }
    }, [userData]);

    useEffect(() => {
        if (userData) {
            if (userData.rol === "promotor") navigate("/")
            setEvents((el) => {
                return el.filter((x) => String(x?.title).toLowerCase().includes(search));
            });
            if (String(search).length === 0) {
                setEvents(eventStaticData);
            }
        }
    }, [search, eventStaticData, userData]);

    return loading ? <div className="h-[90vh] w-full flex justify-center items-center"><ProgressSpinner /></div> : (
        <>
            <BreadCrumb className='border-none text-sm mb-4' model={itemsBreadCrumb} home={home} />

            <section className="w-full p-2 flex items-center gap-2 justify-between rounded bg-[#1e1e1e] mb-5">
                {children}
                <HaaderSelectEvent
                    setSearch={setSearch}
                    search={search}
                    setSizes={changeSize}
                    sizes={sizes.size}
                />
            </section>
            <div
                className={`flex flex-row  justify-center lg:justify-start flex-wrap transition-all duration-200 w-full gap-7`}
            >
                {events?.map((el, key) => {
                    return (
                        <div
                            key={key}
                            className={`relative group transition-all duration-200 cursor-pointer      `}
                            onClick={() => onSelectEvent(el.id)}
                        >
                            <div
                                className={`overflow-hidden bg-transparent flex transition-all text-transparent group-hover:text-white rounded-[14.5px] flex-col  group-hover:bg-black/80 z-20 absolute inset-0 justify-center   items-center text-center px-2   ${sizes.width.class}  ${sizes.heigth.class}`}
                            >
                                <h6 className="font-bold  translate-y-12 transition-all duration-200 group-hover:text-white text-transparent group-hover:translate-y-0">
                                    {el.title}
                                </h6>
                            </div>

                            <img
                                width={sizes.size}
                                height={sizes.size}
                                className={`  ${sizes.heigth.class} z-10  transition-all duration-500 rounded-2xl object-cover `}
                                src={`${el.img}`}
                                alt={el.id}
                            />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

