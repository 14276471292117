import React from 'react';
import { Button } from 'primereact/button';

function SalesToDiscount({ data }) {

    function getEventIdByZone(zone) {
        return data?.events?.filter(x => x.zonesArr)?.find(el => el?.zonesArr?.some(z => String(z).includes(zone)) === true)?.title
    }

    function separarTexto(texto) {
        const partes = texto.split('_', 2);
        return partes.length > 1 ? partes[0] : null;
    }

    function separarTexto2(texto) {
        const partes = texto.split('_', 2);
        return partes.length > 1 ? partes[1] : null;
    }



    return (<>
        <section className='card  h-auto col-span-full lg:col-span-2 px-1 lg:px-4'>
            <h6 className='font-bold pl-2'>Boletos por codigo promocional</h6>
            <div className='grid grid-cols-4 text-xs gap-y-1 mt-3 pl-2 justify-between 	'>


                <p className='font-bold text-xs  pt-[2px] bg-black rounded-[0px] rounded-tl-[8px] rounded-bl-[8px] pl-2 w-full'>Zona</p>


                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Total</p>
                <p className='font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center'>Evento</p>
                <p className='font-bold bg-black text-xs  pt-[2px] rounded-[0px] rounded-tr-[8px] rounded-br-[8px]'></p>
                {data?.discountTickets?.sort((a, b) => b.total - a.total)?.map((el, key) => {
                    return <React.Fragment key={key}>
                        <p className='pl-2 relative'>{ separarTexto(el?.zona) }</p>

                        <p className='justify-self-center'>{el?.total}</p>
                        <p className='justify-self-center'>{separarTexto2(el?.zona)}</p>

                        <Button icon={<i className='pi pi-ellipsis-v'></i>} className="p-button-text p-button-rounded h-[25px] w-[25px]  p-button-plain text-white justify-self-end mr-10 " />

                    </React.Fragment >

                })}
            </div>
        </section>
    </>);
}

export default SalesToDiscount