import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './style.css'
function SalesTable({ data }) {


    return (<section className='col-span-full lg:col-span-2 w-full card salesTable'>
        <DataTable value={data.last30Sales?.sort((a, b) => b.monthIndex - a.monthIndex || b.dayDate - a.dayDate)} size="small" header="Ultimas ventas" rows={5} rowClassName="text-sm text-ellipsis overflow-hidden truncate " cellClassName="text-sm " tableClassName='text-sm rounded' paginator showGridlines responsiveLayout="scroll">
            <Column field="eventId" header="Evento"></Column>
            <Column field="vendedor" header="Punto"></Column>

            <Column field="totalTicket" header="Boletos"></Column>
            <Column field="referenciaDePago" header="Metodo Pago"></Column>
            <Column field="fecha" header="Fecha"></Column>
        </DataTable>
    </section>);
}

export default SalesTable;