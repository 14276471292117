import { useCallback, useState } from "react"

export default function useLabel() {

    const [showLabel, setShowLabel] = useState(false)

    const closeShowLabel = useCallback(() => {
        setShowLabel(false)
    }, [])

    const handleShowLabel = useCallback(({ x, y, price, tablePosition, state, position, zone, row, type }) => {
        setShowLabel({ x: x, y: y, price: price, tablePosition: tablePosition, state: state, position: position, zone: zone, row, type })
    }, [])

    const chairState = (state) => {
        if (state === 'block') {
            return "#D3D3D3"
        } else if (state === 'recerved') {
            return "#FFC200"
        } else {
            return "#51BFA6"
        }
    }

    return { chairState, handleShowLabel, closeShowLabel, showLabel }
}