import { Button } from "primereact/button";
import React, { useState } from "react";
import LineDayGraphic from "./LineDay";
import LineEventGraphic from "./LineEvent";
import LineGraphicMonth from "./LineMonth";

function LineGraphics({ data }) {
  const [typeData, setTypeData] = useState("month");
 const [typeDataEvent, setTypeDataEvent] = useState("month");

  return (
    <>
      <article className={`card  ${typeData===null?'h-[80px]':'h-[420px]'} col-span-full  transition-all  duration-200`}>
        <h6 className="font-bold">
          Total boletos vendidos  {typeData === "month" ? "por mes" : typeData==='day'&&"por día"}
        </h6>
        <div className="flex gap-2">
          <Button
            onClick={() => setTypeData("month")}
            label="Mes"
            className={`h-[30px] ${
              typeData === "month"
                ? "p-button-primary"
                : " p-button-text  p-button-outlined"
            }`}
          />
          <Button
            onClick={() => setTypeData("day")}
            label="Día"
            className={`h-[30px] ${
              typeData === "day"
                ? "p-button-primary "
                : "p-button-text p-button-outlined"
            }`}
          />
            <Button
           onClick={() => typeData===null?setTypeData("month"):setTypeData(null)}
            icon={`pi pi-angle-up`}
            className={`h-[30px] p-button-text transition-all duration-200 transform   
            ${
              typeData === null?'rotate-180':' rotate-0'
            }`}
          />
        </div>

        {typeData === "month" && (
          <LineGraphicMonth
            data={data?.salesForMonth?.map((el) => el?.total)}
            label={data?.meses}
          />
        )}
        {typeData === "day" && (
          <LineDayGraphic
            data={data?.ticketForDay?.map((el) => el?.total)}
            label={data?.ticketForDay?.map((el) => el?.fecha)}
          />
        )}
      </article>

      {/* <article className={`card  ${typeDataEvent===null?'h-[80px]':'h-[420px]'}  col-span-full w-full  transition-all  duration-200` }>
        <h6 className="font-bold">Total boletos vendidos por evento  {typeDataEvent === "month" ? "- Mes" : typeDataEvent==='day'&&"- Día"}</h6>
        <div className="flex gap-2">
          <Button
            onClick={() => setTypeDataEvent("month")}
            label="Mes"
            className={`h-[30px] ${
                typeDataEvent === "month"
                ? "p-button-primary"
                : " p-button-text  p-button-outlined"
            }`}
          />
          <Button
            onClick={() => setTypeDataEvent("day")}
            label="Día"
            className={`h-[30px] ${
                typeDataEvent === "day"
                ? "p-button-primary "
                : "p-button-text p-button-outlined"
            }`}
          />
            <Button
            onClick={() => typeDataEvent===null?setTypeDataEvent("month"):setTypeDataEvent(null)}
            icon={`pi pi-angle-up`}
            className={`h-[30px] p-button-text transition-all duration-200 transform   
            ${
                typeDataEvent === null?'rotate-180':' rotate-0'
            }`}
          />
        </div>
        <div className={`transition-all duration-200 h-auto transform col-span-full w-full`}>

        {typeDataEvent === "month" && (
            <LineEventGraphic
            data={data}
            />
            )}
            </div>
      </article> */}
    </>
  );
}

export default LineGraphics;
