import axios from 'axios'
import { useState, useRef } from 'react'
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel'
import { InputText } from 'primereact/inputtext';


export default function GenerateDescriptionAi(props: any) {

    const { op, setValue: setDescription } = props

    const input = useRef<InputText>(null)

    const [value, setValue] = useState("")
    const [error, setError] = useState(false)
    const [response, setResponse] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const handleSubmit = async () => {
        try {
            if (loading || value === '') return null
            setLoading(true)
            if (error) {
                setError(false)
            }
            const url = "https://creatorsbot.globalboletos.com/tell"

            const response = await axios.post(url, {
                open: true,
                messages: [],
                message: `Redacta una descripcion para un evento que cumpla con lo siguiente: ${value}`
            })
            if (response.data.isSuccess) {
                setValue('')
                setDescription("information", response.data.data)
            } else {
                setError(true)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setError(true)
            setLoading(false)
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const items = [
        {
            label: 'Update',
            command: () => {
            }
        },
        {
            label: 'Delete',
            command: () => {
            }
        }
    ]

    return (

        <OverlayPanel ref={op}>
            <div className="grid">
                <label className='scroll-mb-2'>Intrucciones para la descripción*</label>
                <span className="p-input-icon-right">
                    {loading ? <i className="pi pi-spin pi-spinner" /> : <i className="pi pi-search cursor-pointer" onClick={() => handleSubmit()} />}
                    <InputText
                        ref={input}
                        id="prompt"
                        value={value}
                        className='w-full'
                        onChange={onChange}
                        onKeyDown={handleKeyPress}
                    // errorMessage={errors.prompt ? errors.prompt.message : undefined}
                    />
                </span>
                {/* <Menu className='w-full mt-3' model={items} /> */}
                {response && <p className='max-w-[350px]'> {response} </p>}
            </div>
        </OverlayPanel >


    )
}