import AddNewEventForm from '../complex-template/add-new-event/AddNewEventForm'


export default function AddNewEvent() {
  return (
    <div className='w-[100%]  grid '>
      <AddNewEventForm />
    </div>
  )
}
