import axios from "axios"
import { serverBaseUrl } from "../repository/config"

export default function useServices() {

    const sendServiceRequest = async ({ message, service, user, event }) => {

        try {
            const request = await axios.post(`${serverBaseUrl}/api/user-requests/create-request`, {
                affair: message,
                event: event,
                service: service,
                user: user
            })
            return request.data
        } catch (err) {
            console.log(err)
        }
    }

    const sendFundsTransferRequest = async ({ event, amount, currency, destinationAccount, producer }) => {

        try {
            const request = await axios.post(`${serverBaseUrl}/api/user-requests/fundsTrransferRequest`, {
                event: event,
                amount: amount,
                currency: currency,
                producer: producer,
                destinationAccount: destinationAccount
            })
            return request.data
        } catch (err) {
            console.log(err)
        }
    }

    return { sendServiceRequest, sendFundsTransferRequest }
}