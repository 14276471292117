import axios from "axios"
import { fetchConfigureAccountDetails, fetchProducerInformation, fetchProducerPublicData, fetchBrandImage } from "../Firebase/client"
import { serverBaseUrl } from "../repository/config"

export default function useProducerUser() {


    const thereAreAccountSettings = async ({ event }) => {
        try {
            const request = await fetchProducerInformation(event)
            return request
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const findLinkedAccounts = async ({ event }) => {
        try {
            const request = await fetchProducerInformation(event)
            return request
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const getPublicData = async ({ id }) => {
        try {
            const request = await fetchProducerPublicData(id)
            return request
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const getBrandImage = async ({ id }) => {
        try {
            const request = await fetchBrandImage(id)
            return request
        } catch (err) {
            console.log(err)
            return false
        }
    }



    const addAccountSettings = async ({ event, methodsConfigure }) => {
        try {
            const request = await axios.post(`${serverBaseUrl}/api/producer/add-producer-configuration`, { methodsConfigure: methodsConfigure, eventId: event })
            return request.data
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const configureAccountDetails = async ({ producerId, accountName, commercialAddress }) => {
        try {
            const request = await axios.post(`${serverBaseUrl}/api/producer/configure-account-details`, { producerId: producerId, accountName: accountName, commercialAddress: commercialAddress })
            return request.data
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const confirationAccountDetails = async ({ id }) => {
        try {
            const request = await fetchConfigureAccountDetails({ id })
            return request
        } catch (err) {
            console.log(err)
            return false
        }
    }

    const brandImageConfiguration = async ({ data, producerId }) => {
        try {
            const request = await axios.post(`${serverBaseUrl}/api/producer/brand-image-configuration`, { data: data, producerId: producerId })
            return request.data
        } catch (err) {
            console.log(err)
            return false
        }
    }


    const publicDataConfiguration = async ({ data, producerId }) => {
        try {
            const request = await axios.post(`${serverBaseUrl}/api/producer/public-data-configuration`, { data: data, producerId: producerId })
            return request.data
        } catch (err) {
            console.log(err)
            return false
        }
    }

    return {
        addAccountSettings,
        thereAreAccountSettings,
        findLinkedAccounts,
        configureAccountDetails,
        confirationAccountDetails,
        publicDataConfiguration,
        getPublicData,
        brandImageConfiguration,
        getBrandImage
    }

}