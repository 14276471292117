export default function Logo(props) {
  return (
    <img
      width={props.width ?? 200}
      alt="globalboletos"
      height={props.height ?? 45}
      className={props.className??''}
      src={props.local?"/assets/image/globalticket-icon-original.png":props.mini?"/assets/image/mini-icon.png":"https://firebasestorage.googleapis.com/v0/b/globalboletos.appspot.com/o/recursos%2Fglobalticket_original.png?alt=media&token=e344f52c-58a6-46c8-8aab-c394f0923c14"}
    />
  );
}
