import axios from "axios";
import React, { useEffect, useState } from "react";

// import Graphic from "./Graphics/Graphics";

import SalesHome from "../../Home/Sales/Sales";
import useUser from "../../../Hooks/useUser";
import SkeletonHome from "../../Home/Skeleton/Skeleton";

import useDate from "../../../Hooks/useDate";
import useSalesData from "../../../Hooks/useSaleData";
import DateFilter from "../../Home/DateFilter/DateFilter";
import { serverBaseUrl } from "../../../repository/config";

export default function ResumeForInsurance({ eventId }) {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false)

    const { userData } = useUser()
    const { setData, data, dataOriginal, dateFilter, setDateFilter, setDateRangeFilter, dateRangeFilter, onFilterDate, setDataOriginal } = useSalesData()



    const request = async ({ filters, date }) => {
        setLoading(true)
        try {
            const response = await axios.post(`${serverBaseUrl}/api/sales/get-totals-for-insurer`, {
                idEvents: [eventId],
                dateToFilter: date ? date : false,
                filterByRange: filters ? filters : false,
                chargeLockerServiceIn: userData.chargeLockerServiceIn ? userData.chargeLockerServiceIn : false,
            })
            setData(response.data)
            setDataOriginal(response.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (userData) {
            request({ filters: false, date: false })
        }
    }, [userData, dateFilter, eventId])

    const [daysAgo, setDaysAgo] = useState("todos");
    const { datesByDaysAgo } = useDate()

    function filterDateByDaysAgo(number) {
        const rangeDate = datesByDaysAgo(number);
        const filterRangeDate = dataOriginal?.sales?.filter(
            (x) =>
                new Date(x?.fechaDate) >= rangeDate?.start &&
                new Date(x?.fechaDate) <= rangeDate?.end
        )
        return filterRangeDate;
    }

    function restartData() {
        setData(dataOriginal)
    }

    return (
        <>
            <div>
                <DateFilter
                    setData={setData}
                    dateFilter={dateFilter}
                    onFilterDate={onFilterDate}
                    request={request}
                    dataOriginal={dataOriginal}
                    setDateFilter={setDateFilter}
                    dateRangeFilter={dateRangeFilter}
                    setDateRangeFilter={setDateRangeFilter}
                />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-4   ">
                {!loading ? (
                    <>
                        <SalesHome
                            data={data}
                            setData={setData}
                            setDateFilter={setDateFilter}
                            section="resumen"
                            daysAgo={daysAgo}
                            restartData={restartData}
                            setDaysAgo={setDaysAgo}
                            filterDateByDaysAgo={filterDateByDaysAgo}
                        />
                        <SalesHome
                            data={data}
                            section="sales" />
                        {/* <Graphic
                            data={data} /> */}
                        <SalesHome
                            data={data}
                            section="toEvent"
                            text="Seguros vendidos en relación a la capacidad"
                            setShowModal={() => { }}
                            setOnSelectEvent={() => { }}
                        />
                        <SalesHome
                            data={data}
                            text="Seguros vendidos por origen"
                            section="vendors"
                        />
                        <SalesHome
                            data={data}
                            section="zones"
                            text="Seguros vendidos por zona"
                        />
                        <SalesHome
                            data={data}
                            section="totalsForZone"
                        />
                        <div className="mt-5 col-span-3 "></div>
                    </>
                ) : (
                    <SkeletonHome />
                )}
            </div>
        </>
    );
}

