import { useRef } from "react";
import { animated } from '@react-spring/konva';
import Typography from "../../Typography";
import Rectangle from "../../Rectangle";

export default function Zone({ y, width, fill, name, height, select, isSelected, zone, design, selectShape, onChange, position, textSelect, rectangleProps }) {

    const ref = useRef()

    const handleMouseEnter = (e) => {
        ref.current.to({
            strokeWidth: 2,
            stroke: "#ccc",
        })
        e.target.getStage().container().style.cursor = 'pointer';
    }

    const handleMouseLeave = (e) => {
        ref.current.to({
            stroke: `transparent`,
            strokeWidth: 0,
        });
        e.target.getStage().container().style.cursor = 'default';
    }

    const handleMouseEnterText = (e) => {
        ref.current.to({
            strokeWidth: 2,
            stroke: "#ccc"
        })
        e.target.getStage().container().style.cursor = 'pointer';
    }

    const handleMouseTextLeave = (e) => {
        ref.current.to({
            stroke: `transparent`,
            strokeWidth: 0,
        });
        e.target.getStage().container().style.cursor = 'default';
    }

    const onCLick = () => {
        select(zone)
    }


    return (
        <>
            {
                design && <Rectangle
                    fill={fill}
                    cornerRadius={8}
                    id={name}
                    design={design}
                    onChange={onChange}
                    selectShape={selectShape}
                    isSelected={isSelected}
                    width={position({ parameter: "width", id: `${name}`, defaultPosition: width })}
                    height={position({ parameter: "height", id: `${name}`, defaultPosition: height })}
                    y={position({ parameter: "y", id: `${name}`, defaultPosition: y })}
                    x={position({ parameter: "x", id: `${name}`, defaultPosition: 0 })}
                    shadowColor="#0F0F0F"
                    shadowBlur={10}
                    shadowOpacity={0.6}
                    shadowOffsetX={5}
                    shadowOffsetY={5}
                    rectangleProps={rectangleProps}
                />
            }

            {
                !design && <animated.Rect
                    ref={ref}
                    fill={fill}
                    cornerRadius={8}
                    onCLick={() => onCLick()}
                    onMouseEnter={(e) => handleMouseEnter(e)}
                    onMouseLeave={(e) => handleMouseLeave(e)}

                    width={position({ parameter: "width", id: `${name}`, defaultPosition: width })}
                    height={position({ parameter: "height", id: `${name}`, defaultPosition: height })}
                    y={position({ parameter: "y", id: `${name}`, defaultPosition: y })}
                    x={position({ parameter: "x", id: `${name}`, defaultPosition: 0 })}

                    shadowColor="#0F0F0F"
                    shadowBlur={10}
                    shadowOpacity={0.6}
                    shadowOffsetX={5}
                    shadowOffsetY={5}
                    {...rectangleProps}
                />
            }

            <Typography
                text={name}
                align="center"
                fontStyle='bold'
                design={design}
                selectShape={selectShape}
                name='rectangle'
                isSelected={textSelect}
                id={`${name}-text`}
                onChange={onChange}
                verticalAlign="center"
                onCLick={() => onCLick()}
                onMouseEnter={(e) => handleMouseEnterText(e)}
                onMouseLeave={(e) => handleMouseTextLeave(e)}
                width={position({ parameter: "width", id: `${name}-text`, defaultPosition: width })}
                height={position({ parameter: "height", id: `${name}-text`, defaultPosition: 20 })}
                y={position({ parameter: "y", id: `${name}-text`, defaultPosition: y - 5 + height / 2 })}
                x={position({ parameter: "x", id: `${name}-text`, defaultPosition: 0 })}
                fontSize={position({ parameter: "fontSize", id: `${name}-text`, defaultPosition: 24 })}

            />
        </>
    )
}