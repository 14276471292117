import React from "react";
import { Button } from "primereact/button";
import { useSessionAuthContext } from "../../../../Context/session-auth-context";


function SalesToZone({ data, text }) {
  const { userData } = useSessionAuthContext();

  function getEventIdByZone(zone) {
    return data?.events
      ?.filter((x) => x.zonesArr)
      ?.find(
        (el) => el?.zonesArr?.some((z) => String(z).includes(zone)) === true
      )?.title;
  }

  return userData?.email === "lacarlota@gb.com" ? (
    <></>
  ) : (
    <>
      <section className="card  h-auto col-span-full lg:col-span-2 px-1 lg:px-4">
        <h6 className="font-bold pl-2">
          {text ? text : "Boletos vendidos por zona"}{" "}
        </h6>
        <div className="grid grid-cols-4 text-xs gap-y-1 mt-3 pl-2 justify-between 	">
          <p className="font-bold text-xs  pt-[2px] bg-black rounded-[0px] rounded-tl-[8px] rounded-bl-[8px] pl-2 w-full">
            Vendedor
          </p>

          <p className="font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center">
            Total
          </p>
          <p className="font-bold bg-black text-xs  pt-[2px] justify-self-center w-full text-center">
            Evento
          </p>
          <p className="font-bold bg-black text-xs  pt-[2px] rounded-[0px] rounded-tr-[8px] rounded-br-[8px]"></p>
          {data?.tickets
            ?.sort((a, b) => b.total - a.total)
            ?.map((el, key) => {



              return (
                <React.Fragment key={key}>

                  {
                    userData.email === 'pimpinela@gb.com' ? <>

                      {
                        el.zona !== 'BOX' && <>
                          <p className="pl-2 relative">{el?.zona}</p>

                          <p className="justify-self-center">{el?.total}</p>
                          <p className="justify-self-center">
                            {getEventIdByZone(el?.zona)}
                          </p>

                          <Button
                            icon={<i className="pi pi-ellipsis-v"></i>}
                            className="p-button-text p-button-rounded h-[25px] w-[25px]  p-button-plain text-white justify-self-end mr-10 "
                          />
                        </>
                      }
                    </> : <>
                      <p className="pl-2 relative">{el?.zona}</p>

                      <p className="justify-self-center">{el?.total}</p>
                      <p className="justify-self-center">
                        {getEventIdByZone(el?.zona)}
                      </p>

                      <Button
                        icon={<i className="pi pi-ellipsis-v"></i>}
                        className="p-button-text p-button-rounded h-[25px] w-[25px]  p-button-plain text-white justify-self-end mr-10 "
                      />
                    </>
                  }


                </React.Fragment>
              );
            })}
        </div>
      </section>
    </>
  );
}

export default SalesToZone;
