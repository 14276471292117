import { useEffect, useState } from "react";
import { CortesyRepository } from "../repository/courtesy.repository";
import { SalesRepository } from "../repository/sales.repository";
import useUser from "./useUser";

const repository = new SalesRepository();
const repositoryCortesy = new CortesyRepository()

function useSales(eventId) {

  const [data, setData] = useState([]);
  const [cortesy, setCortesy] = useState([]);

  const { userData } = useUser()


  function amountSales(arr) {
    return arr
      ?.map((el) => el.precioTotal)
      ?.reduce((prev, curr) => prev + curr, 0);
  }

  function quantityTotalTicket(arr) {
    return arr
      ?.map((el) => el.boletos?.length)
      ?.reduce((prev, curr) => prev + curr, 0);
  }

  function totalCourtesy(arr) {
    return arr?.length
  }

  function getTotals({ arr }) {


    const arrLockerServiceIn = userData.chargeLockerServiceIn ? userData.chargeLockerServiceIn : []

    const metodoDePagoBs = [
      {
        name: "Punto de venta",
        key: "puntoDeVenta"
      },
      {
        name: "Pago movil",
        key: "pagoMovil"
      },
      {
        name: "Debito Ahorro Vnzla",
        key: "Debito Ahorro Vnzla"
      },
      {
        name: "Debito Corriente Vnzla",
        key: "Debito Corriente Vnzla"
      },
      {
        name: "Transferencia Vnzla",
        key: "vzlaTransfer"
      },
      {
        name: "Credito mastercard Vnzla",
        key: "Credito Mastercard Vnzla"
      },
      {
        name: "Credito visa Vnzla",
        key: "Credito Visa Vnzla"
      },

      {
        name: "Monedero patria",
        key: "Monedero Patria"
      },
      {
        name: "Efectivo Bs",
        key: "efectivoBs"
      }
    ]

    const metodoBs = metodoDePagoBs?.map((x) => x.key);

    const arrmetodoDollars = [
      "binance",
      "zelle",
      "zinli",
      "efectivo",
      "reserve",
      "tarjetaDeCredito"
    ];

    const arrBs = [];
    const arrDollars = [];
    const arrDollarsProductor = [];
    const arrBsProductor = [];
    const arrDollarsGlobalboletos = [];
    const arrBsGlobalboletos = [];
    const dolaresPorCredito = []



    arr.forEach((x) => {


      const chargeBoxOfficeService = userData.chargeLockerServiceIn ? arrLockerServiceIn.some(y => y === x.vendedor) : true


      const serviceOfficeBoxUni = chargeBoxOfficeService ? Number(
        x.bill.generalPlace
          ? x.bill.generalPlace.servicioTaquillaUnitario
          : x.bill.vipPlaces.servicioTaquillaUnitario
      ) : 0

      const isBs = metodoBs.some((z) => z === x.referenciaDePago);

      if (x.referenciaDePago === "multipagos") {
        const amountBsInBs = [];
        const amountDollarsInBs = [];

        metodoBs.forEach((y) => {
          const values = x.metodoDePago;
          if (values[y]) {
            if (values[y].value > 0) {
              arrBs.push(values[y].value * x.tasaDelDolarToBs);
              amountBsInBs.push(values[y].value);
            }
          }
        });

        arrmetodoDollars.forEach((y) => {
          const values = x.metodoDePago;
          if (values[y]) {
            if (values[y].value > 0) {
              arrDollars.push(values[y].value);
              amountDollarsInBs.push(values[y].value);
            }
          }
        });

        const amountTotalBsInMultipayment = amountBsInBs.reduce(
          (prev, curr) => prev + curr,
          0
        );
        const amountTotalDollarInMultipayment = amountDollarsInBs.reduce(
          (prev, curr) => prev + curr,
          0
        );

        const whereMoreAmount =
          amountTotalBsInMultipayment > amountTotalDollarInMultipayment
            ? "Bolivares"
            : "Dolares";


        const serviceOfficeBoxTotal = serviceOfficeBoxUni * x.boletos.length;

        if (amountTotalDollarInMultipayment > serviceOfficeBoxTotal) {

          arrDollarsProductor.push(
            amountTotalDollarInMultipayment -
            serviceOfficeBoxUni * x.boletos.length
          );
          arrBsProductor.push(x.tasaDelDolarToBs * amountTotalBsInMultipayment);
          arrDollarsGlobalboletos.push(serviceOfficeBoxUni * x.boletos.length);

        } else {

          if (whereMoreAmount === "Dolares") {
            arrDollarsProductor.push(
              amountTotalDollarInMultipayment -
              serviceOfficeBoxUni * x.boletos.length
            );
            arrBsProductor.push(x.tasaDelDolarToBs * amountTotalBsInMultipayment);
            arrDollarsGlobalboletos.push(serviceOfficeBoxUni * x.boletos.length);
          } else if (whereMoreAmount === "Bolivares") {
            arrBsProductor.push(
              x.tasaDelDolarToBs *
              (amountTotalBsInMultipayment -
                serviceOfficeBoxUni * x.boletos.length)
            );
            arrDollarsProductor.push(amountTotalDollarInMultipayment);
            arrBsGlobalboletos.push(
              x.tasaDelDolarToBs * (serviceOfficeBoxUni * x.boletos.length)
            );
          }


        }


      } else if (isBs) {
        arrBs.push(x.precioTotal * x.tasaDelDolarToBs);
        arrBsProductor.push(
          x.tasaDelDolarToBs *
          (x.precioTotal - serviceOfficeBoxUni * x.boletos.length)
        );
        arrBsGlobalboletos.push(
          x.tasaDelDolarToBs * (serviceOfficeBoxUni * x.boletos.length)
        );
      } else {
        if (x.referenciaDePago === "tarjetaDeCredito") { dolaresPorCredito.push(x.precioTotal) }
        arrDollars.push(x.precioTotal);
        arrDollarsProductor.push(
          x.precioTotal - serviceOfficeBoxUni * x.boletos.length
        );
        arrDollarsGlobalboletos.push(serviceOfficeBoxUni * x.boletos.length);
      }
    });



    const totaDollarsProductor = arrDollarsProductor.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const totaBsProductor = arrBsProductor.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const totaDollarsGlobalboletos = arrDollarsGlobalboletos.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const totaBsGlobalboletos = arrBsGlobalboletos.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const totalDolaresPorTarjetaDeCredito = dolaresPorCredito.reduce(
      (prev, curr) => prev + curr,
      0
    );

    const totaDollars = arrDollars.reduce((prev, curr) => prev + curr, 0);
    const totalBs = arrBs.reduce((prev, curr) => prev + curr, 0);
    const totalBankCommissionBs = totalBs * 0.02
    const totalBankCommissionDollars = totalDolaresPorTarjetaDeCredito * 0.01

    return {
      totalDolares: totaDollars,
      totalBs: totalBs,
      totalComisionBancaria: totalBankCommissionBs,
      totalComisionBancariaDolares: totalBankCommissionDollars,
      totalDolaresProductor: totaDollarsProductor - totalBankCommissionDollars,
      totalBolivaresProductor: totaBsProductor - totalBankCommissionBs,
      totalDolaresGlobalboletos: totaDollarsGlobalboletos,
      totalBolivaresGlobalboletos: totaBsGlobalboletos,
    }
  }

  useEffect(() => {
    if (eventId) {
      Promise.all(
        repository.getSalesEvent(eventId).then((res) => {
          setData(res);
        }),
        repositoryCortesy.getCortesy(eventId).then(res => {
          setCortesy(res)
        }),
      );
    }
  }, [eventId]);

  return { data, amountSales, quantityTotalTicket, totalCourtesy, cortesy, getTotals };
}

export default useSales;
