import { collection, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../Config/firebaseConfig";


export class EventRepository {
  async getEventos() {
    const q = query(collection(db, "events"), orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const id = doc.id;
      //        const { date } = data
      return {
        ...data,
        startOfSale: new Date(data.startOfSale),
        fecha: new Date(data.date),
        id,
      };
    });
  }
  async getEvento(eventId) {
    const q = query(collection(db, "events", eventId));
    const querySnapshot = await getDoc(q);
    return { ...querySnapshot.data(), id: querySnapshot.id }

  }
}