import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Receipt from "../../Components/Files/Receipts";
import { fetchReceipt } from "../../Firebase/client";

export default function File() {

    let { id, billing } = useParams()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(
        {
            addres: '',
            name: "",
            ci: "",
            signature: "",
            amount: '',
            dni: '',
            description: "",
            date: new Date(),
        }
    )


    const request = async () => {
        try {
            setLoading(true)
            const response = await fetchReceipt({ id: id, billing: billing })
            if (response) {
                setData({
                    addres: String(response.responsableName).toUpperCase(),
                    name: String(response.receptorName).toUpperCase(),
                    ci: String(response.dni_receiver ? response.dni_receiver : "").toUpperCase(),
                    signature: response.signature ? response.signature : "",
                    description: String(response.descripcion).toUpperCase(),
                    amount: response.monto,
                    dni: response.dni ? response.dni : "",
                    date: response.date
                })
                setLoading(false)
            }
        } catch (err) {
            console.log(err)
            // setLoading(false)
        }
    }

    useEffect(() => {
        request()
    }, [])

    // if (loading) return <div className="w-full  h-[100vh] flex justify-center items-center">
    //     <ProgressSpinner />
    // </div>

    if (loading) return <></>

    return (
        <Receipt data={data} />
    )
}