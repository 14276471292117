import React, { useEffect, useState } from 'react'
// import Calendar from '../../icons/Calendar'
import Chevron from '../../icons/Chevron'
import DateTimePiker from '../date-time-piker/DateTimePiker'
import { format } from 'date-fns'
import { es } from 'date-fns/esm/locale'
import { Calendar } from 'primereact/calendar'

interface Props {
  name?: string,
  value?: string | number | boolean,
  label?: string
  date?: Date
  info?: string
  errorMessage?: string,
  setDates: any
  defaulRenderValue?: string
}

export default function GBDatePiket({ name = "default", label, date, info, setDates, errorMessage, defaulRenderValue = "Ahora mismo" }: Props) {

  const [dateTime, setDateTime] = useState(date ? date : new Date())
  const [datePiketModalC, setDatePiketModalC] = useState(false)

  const [renderValue, setRenderValue] = useState(defaulRenderValue);


  useEffect(() => {
    setDates(dateTime);
    if (new Date(dateTime) > new Date()) {
      // console.log("es una fecha despues de ahora")

      const newRenderValue = format(new Date(dateTime), 'dd LLLL yyyy - hh : mm aa', {
        locale: es
      })

      setRenderValue(newRenderValue)
    } else {
      // console.log("es ahora mismo")
      setRenderValue(defaulRenderValue)

    }

  }, [dateTime])



  return (
    <div>
      <Calendar value={date} className='w-full' showTime hourFormat="12" showIcon onChange={(e) => setDates(e.value)}  />
      {errorMessage && <span className="text-message-error text-red-600	">{errorMessage}</span>}
    </div>
  )
}
