import { useEffect, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import Liquidations from "../../Pages/Liquidations/Liquidations";
import Courtesies from "../../Pages/Courtesies/Courtesies";
import DisputedSales from "../../Pages/DisputedSales/DisputedSales";
import { BreadCrumb } from "primereact/breadcrumb";
import useUser from "../../Hooks/useUser";
import { fetchEvent, logout } from "../../Firebase/client";
// import Balances from "../../Pages/Balances/Balances";
import Detail from "../../Pages/Detail";
import Design from "../../Pages/Design";
import { Message } from "primereact/message";
import Resources from "../../Pages/Resources";
// import Design from "../../modules/mapdesign/Test";

function EventOptions({ children }) {

    let { eventId } = useParams()

    const { userData } = useUser()

    const navigate = useNavigate()

    const itemsBreadCrumb = [
        { label: `${eventId}` },
    ]

    const home = { icon: 'pi pi-building', url: '/' }

    const [valueNav, setValueNav] = useState("resumen");
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const [eventData, setEventData] = useState({ title: "", validated: true })

    const requestEventData = async () => {
        try {
            const request = await fetchEvent(eventId)
            if (request) {
                setEventData(request)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [items] = useState([
        { label: "Resumen", icon: "pi pi-fw pi-home", value: "resumen" },
        // { label: "Operaciones", icon: "pi pi-fw pi-database", value: "detalle" },
        // { label: "Billetera", icon: "pi pi-fw pi-credit-card", value: "billetera" },
        { label: "Liquidaciones", icon: "pi pi-fw pi-book", value: "liquidaciones" },
        { label: "Cortesia", icon: "pi pi-fw pi-heart", value: "cortesias" },
        { label: "Disputas", icon: "pi pi-fw pi-info-circle", value: "disputas" },
        { label: "Construcción (beta)", icon: "pi pi-fw pi-cog", value: "construccion" },
        { label: "Recursos", icon: "pi pi-fw pi-book", value: "recursos" },

    ])

    const [itemsCarlota] = useState([
        { label: "Resumen", icon: "pi pi-fw pi-home", value: "resumen" },
    ])

    let nav = searchParams.get("nav");

    useEffect(() => {
        if (userData) {
            const permissionForThisEvent = userData.event.some(w => w === eventId)
            if (!permissionForThisEvent) navigate("/")
            if (userData.rol !== "promotor") logout()
            if (nav) {
                setValueNav(nav);
                const result = items.findIndex((el) => el.value === nav);
                setActiveIndex(result);
            } else if (valueNav === "") {
                setSearchParams({ nav: "resumen" });
                setValueNav("resumen");
                setActiveIndex(0);
            }
        }
    }, [items, nav, valueNav, setSearchParams, eventId, userData])

    useEffect(() => {
        requestEventData()
    }, [eventId])

    return (<>

        <BreadCrumb className='border-none text-sm mb-4' model={itemsBreadCrumb} home={home} />

        <div className=" relative">
            {nav ? (
                <TabMenu
                    model={userData?.email==='pimpinela@gb.com'?itemsCarlota:items}
                    activeIndex={activeIndex}
                    scrollable
                    onTabChange={(e) => {
                        setActiveIndex(e.index);
                        setSearchParams({ nav: e.value.value });
                    }}
                    className="mb-4 text-sm"
                />
            ) : null}

            {
                !eventData.validated && eventData.admin && <Message severity="warn" text="Este evento esta en proceso de validación" className='w-full mt-1 mb-3' />
            }
            {(() => {
                switch (nav) {
                    case "resumen":
                        return <Home eventId={eventId} />
                    case "liquidaciones":
                        return <Liquidations eventId={eventId} />
                    case "cortesias":
                        return <Courtesies eventId={eventId} />
                    case "disputas":
                        return <DisputedSales eventId={eventId} />
                    // case "billetera":
                    //     return <Balances eventId={eventId} />
                    case "detalle":
                        return <Detail eventId={eventId} />
                    case "construccion":
                        return <Design eventId={eventId} />
                    case "recursos":
                        return <Resources eventId={eventId} event={eventData} />
                    default:
                        <Home />
                }
            })()}

            {children}
        </div>




    </>
    );
}

export default EventOptions;
