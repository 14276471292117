import { useRef, useState } from "react";


export default function useMultiSelect() {

    const [selectedId, selectShape] = useState(null);
    const [nodesArray, setNodes] = useState([]);

    const trRef = useRef();
    const layerRef = useRef();
    const selectionRectRef = useRef();

    const Konva = window.Konva;

    // Referencia para el rectángulo de selección
    const selection = useRef({
        visible: false,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    });

    //REMOVER RANGO AL HACER CLICK EN UNA ZONA VACIA
    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
            trRef.current.nodes([]);
            setNodes([]);
        }
    };

    // Función para actualizar el rectángulo de selección
    const updateSelectionRect = () => {
        const node = selectionRectRef.current;

        node.setAttrs({
            visible: selection.current.visible,
            x: Math.min(selection.current.x1, selection.current.x2),
            y: Math.min(selection.current.y1, selection.current.y2),
            width: Math.abs(selection.current.x1 - selection.current.x2),
            height: Math.abs(selection.current.y1 - selection.current.y2),
            fill: "rgba(0, 161, 255, 0.3)"
        });
        node.getLayer().batchDraw();
    };

    // Manejador de evento al hacer clic en el lienzo para iniciar la selección
    const onMouseDown = (e) => {
        const isElement = e.target.findAncestor(".elements-container");
        const isTransformer = e.target.findAncestor("Transformer");
        if (isElement || isTransformer) {
          return;
        }
      
        const stage = e.target.getStage();
        const position = stage.getPointerPosition();
      
        // Obtener las coordenadas del cursor en el sistema de coordenadas del Layer
        const transform = stage.getAbsoluteTransform().copy();
        transform.invert();
        const cursorPos = transform.point(position);
      
        // Establecer las coordenadas iniciales del rango de selección
        selection.current.visible = true;
        selection.current.x1 = cursorPos.x;
        selection.current.y1 = cursorPos.y;
        selection.current.x2 = cursorPos.x;
        selection.current.y2 = cursorPos.y;
      
        // Actualizar el rectángulo de selección
        updateSelectionRect();
      };
      

    // Manejador de evento al mover el ratón para actualizar el rectángulo de selección
    const onMouseMove = (e) => {
        if (!selection.current.visible) {
          return;
        }
        const stage = e.target.getStage();
        const position = stage.getPointerPosition();
      
        // Obtener las coordenadas del cursor en el sistema de coordenadas del Layer
        const transform = stage.getAbsoluteTransform().copy();
        transform.invert();
        const cursorPos = transform.point(position);
      
        // Actualizar las coordenadas del punto final del rango de selección
        selection.current.x2 = cursorPos.x;
        selection.current.y2 = cursorPos.y;
      
        // Actualizar el rectángulo de selección
        updateSelectionRect();
      };
      
    // Manejador de evento al liberar el ratón para finalizar la selección y seleccionar elementos
    const onMouseUp = () => {
        if (!selection.current.visible) {
            return;
        }
        const selBox = selectionRectRef.current.getClientRect();

        const elements = [];
        layerRef.current.find(".rectangle").forEach((elementNode) => {
            const elBox = elementNode.getClientRect();
            if (Konva.Util.haveIntersection(selBox, elBox)) {
                elements.push(elementNode);
            }
        });
        trRef.current.nodes(elements);
        selection.current.visible = false;
        updateSelectionRect();
    };

    const onClickTap = (e) => {
        if (selectionRectRef.current.visible()) {
            return;
        }
        let stage = e.target.getStage();
        let layer = layerRef.current;
        let tr = trRef.current;

        if (e.target === stage) {
            selectShape(null);
            setNodes([]);
            tr.nodes([]);
            layer.draw();
            return;
        }

        if (!e.target.hasName(".rect")) {
            return;
        }

        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = tr.nodes().indexOf(e.target) >= 0;

        if (!metaPressed && !isSelected) {
            tr.nodes([e.target]);
        } else if (metaPressed && isSelected) {
            const nodes = tr.nodes().slice();
            nodes.splice(nodes.indexOf(e.target), 1);
            tr.nodes(nodes);
        } else if (metaPressed && !isSelected) {
            const nodes = tr.nodes().concat([e.target]);
            tr.nodes(nodes);
        }
        layer.draw();
    };

    return { trRef, layerRef, selectionRectRef, checkDeselect, onMouseDown, onMouseMove, onMouseUp, onClickTap, selectedId, nodesArray, setNodes }
}