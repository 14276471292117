import { useRef } from "react"
import { animated } from '@react-spring/konva';

import { memo } from "react";
import { Circle, Text } from "react-konva";

const Seat = ({
    x,
    y,
    data,
    type,
    row,
    text,
    draggable,
    isSelected,
    selectChair,
    closeShowLabel,
    handleShowLabel,
    id: idSeact,
    onChange,
    selectShape,
}) => {

    const ref = useRef()

    const { state, id, price, tablePosition, position, zone } = data

    const chairState = () => {
        if (isSelected) return '#662d91'
        if (state === 'block') {
            return "#929090b8"
        } else if (state === 'recerved') {
            return "#FFC200"
        } else {
            return "#51BFA6"
        }
    }

    const handleMouseEnter = (e) => {

        if (!draggable) {
            ref.current.to({
                scaleX: 1.3,
                scaleY: 1.3,
                stroke: "#A9A9A9"
            })
        }

        e.target.getStage().container().style.cursor = 'pointer';
        handleShowLabel({ x, y, price, tablePosition, position, state, zone, type: type ? type : false, row: row })
    }

    const handleMouseLeave = (e) => {
        if (!draggable) {
            ref.current.to({
                scaleX: 1,
                scaleY: 1,
                stroke: `${chairState()}`
            });
        }
        e.target.getStage().container().style.cursor = 'default';
        closeShowLabel()

    }

    const onClick = () => {
        selectChair()
    }

    const onDragEnd = (e) => {
        onChange({
            id: idSeact,
            x: e.target.x(),
            y: e.target.y(),
        });
        selectShape(null)
    }

    const elementNumber = text ? String(text) : false

    return (
        <>

            {
                draggable ? <>
                    <Circle
                        x={x}
                        y={y}
                        ref={ref}
                        radius={5}
                        onDragEnd={onDragEnd}
                        fill={chairState()}
                        strokeWidth={2}
                        stroke={chairState()}
                        onClick={() => onClick()}
                        draggable={draggable}
                        name='rectangle'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                    {
                        text && <Text
                            x={x - 4}
                            width={35}
                            // align="center"
                            name='rectangle'
                            onDragEnd={onDragEnd}
                            // verticalAlign="center"
                            fontSize={7}
                            fill="#fff"
                            draggable={draggable}
                            y={y - 2}
                            fontStyle="bold"
                            text={elementNumber.length < 2 ? `0${elementNumber}` : elementNumber}
                            onClick={() => onClick()}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    }

                </> : <>
                    <animated.Circle
                        x={x}
                        y={y}
                        ref={ref}
                        radius={5}
                        fill={chairState()}
                        strokeWidth={2}
                        stroke={chairState()}
                        onClick={() => onClick()}
                        draggable={draggable}
                        name='rectangle'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                    {
                        text && <Text
                            x={x - 4}
                            width={35}
                            // align="center"
                            name='rectangle'
                            // verticalAlign="center"
                            fontSize={7}
                            fill="#fff"
                            draggable={draggable}
                            y={y - 2}
                            fontStyle="bold"
                            text={elementNumber.length < 2 ? `0${elementNumber}` : elementNumber}
                            onClick={() => onClick()}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    }
                </>
            }


        </>
    );
}

export default memo(Seat)

