import axios from "axios";
import React, { useEffect, useState } from "react";
import Graphic from "./Graphics/Graphics";
import SkeletonHome from "./Skeleton/Skeleton";
import SalesHome from "./Sales/Sales";
import useUser from "../../Hooks/useUser";
import ModalResumenZone from "./Modals/ModalResumenZone";
import { serverBaseUrl } from "../../repository/config";
import useSalesData from "../../Hooks/useSaleData";
import useDate from "../../Hooks/useDate";
import DateFilter from "./DateFilter/DateFilter";
import SendMessage from "../../Components/Forms/SendMessage/SendMessage";
import RequestService from "./RequestService/RequestService";

function Home({ eventId }) {

  const [showModal, setShowModal] = useState(false)
  const [onSelectEvent, setOnSelectEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)

  const { userData } = useUser()
  const { setData, data, dataOriginal, dateFilter, setDateFilter, setDateRangeFilter, dateRangeFilter, onFilterDate, setDataOriginal } = useSalesData()

  const request = async ({ filters, date }) => {
    setLoading(true)
    try {
      const response = await axios.post(`${serverBaseUrl}/api/sales/get-all-sales-for-events`, {
        idEvents: [eventId],
        dateToFilter: date ? date : false,
        filterByRange: filters ? filters : false,
        chargeLockerServiceIn: userData.chargeLockerServiceIn ? userData.chargeLockerServiceIn : false,
        userData
      })
      setData(response.data)
      setDataOriginal(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (userData) {
      if (userData.rol === "promotor") {
        request({ filters: false, date: false })
      }
    }
  }, [userData, eventId])

  const [daysAgo, setDaysAgo] = useState("todos")
  const { datesByDaysAgo } = useDate()

  function filterDateByDaysAgo(number) {
    const rangeDate = datesByDaysAgo(number);
    const filterRangeDate = dataOriginal?.sales?.filter(
      (x) =>
        new Date(x?.fechaDate) >= rangeDate?.start &&
        new Date(x?.fechaDate) <= rangeDate?.end
    )
    return filterRangeDate;
  }

  function restartData() {
    setData(dataOriginal)
  }

  return (
    <>

      {
        show && <SendMessage
          setShow={setShow}
          show={show}
        />
      }

      <div>
        {

          userData?.email === 'pimpinela@gb.com' ? <></> : <>
            <RequestService
              eventId={eventId}
              totals={data}
              loading={loading}
              propsConfig={() => { }}
              onClick={() => setShow(true)}
            />
            <div>
              <DateFilter
                setData={setData}
                request={request}
                dateFilter={dateFilter}
                onFilterDate={onFilterDate}
                dataOriginal={dataOriginal}
                setDateFilter={setDateFilter}
                dateRangeFilter={dateRangeFilter}
                setDateRangeFilter={setDateRangeFilter}
              />
            </div>
          </>
        }
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-4   ">
        {!loading ? (
          <>
            <ModalResumenZone
              onHide={() => {
                setShowModal(false)
                setOnSelectEvent(null)
              }}
              userData={userData}
              id={onSelectEvent}
              show={showModal}
              dataCourtesy={data.arrCourtesy}
              data={data?.sales}
              tickets={data?.tickets}
              ticketsForCourtesy={data?.ticketsForCourtesy}
              dataEvent={data?.events?.find(x => x.id === onSelectEvent)}
            />


            <SalesHome
              data={data}
              request={request}
              userData={userData}
              setData={setData}
              setDateFilter={setDateFilter}
              section="resumen"
              daysAgo={daysAgo}
              restartData={restartData}
              setDaysAgo={setDaysAgo}
              filterDateByDaysAgo={filterDateByDaysAgo}
            />
            <SalesHome
              data={data}
              section="sales" />

            {

              userData?.email === 'pimpinela@gb.com' ? <></> : <Graphic
                data={data} />
            }

            <SalesHome
              data={data}
              section="toEvent"
              userData={userData}
              setShowModal={setShowModal}
              setOnSelectEvent={setOnSelectEvent}
            />

            {
              userData.email !== 'pimpinela@gb.com' && <SalesHome
                data={data}
                section="vendors"
                userData={userData}
              />
            }

            <SalesHome
              data={data}
              section="zones"
            />
            <SalesHome
              data={data}
              section="zonesForDiscount"
            />
            <SalesHome
              data={data}
              section="totalsForZone"
            />
            <div className="mt-5 col-span-3 "></div>
          </>
        ) : (
          <SkeletonHome />
        )}
      </div>
    </>
  );
}

export default Home;
