import { useState } from "react"
import { useEffect } from "react"
import { TableXConfig } from "../../Components/TableX/TableXResumen"
import useCourtesies from "../../Hooks/useCourtesies"
import useUser from "../../Hooks/useUser"
import tableConfig from "./tableConfig.json"
import { Skeleton } from "primereact/skeleton"
// import { BreadCrumb } from "primereact/breadcrumb"


export default function Courtesies({ eventId }) {

    const home = { icon: 'pi pi-home', url: '/' }

    const items = [
        { label: 'Cortesias' },
    ];

    const { request, loading } = useCourtesies()
    const { userData } = useUser()

    const [courtesies, setCourtesies] = useState([])


    const requestLiquidations = async () => {
        try {
            const responseCourtesies = await request({ eventId: eventId })
            if (eventId === 'LUISXFONSIXVIAJEXTOURXVALENCIA') {
                setCourtesies([])
            } else {
                setCourtesies(responseCourtesies)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const tickets = (rowData) => {
        return `${rowData.tickets.length}`;
    };

    const zone = (rowData) => {
        const dt = rowData?.tickets?.map((el) => el.zona);
        const convert = dt.filter((valor, indice) => {
            return dt.indexOf(valor) === indice;
        });
        const result = String(convert);
        return result;
    };

    // const attendance = (rowData) => {
    //     if (rowData.attendance) return 'SI'
    //     if (!rowData.attendance) return 'PENDIENTE'
    // }

    useEffect(() => {
        if (userData) requestLiquidations()
    }, [userData, eventId])


    return (
        <>
            {
                loading ?
                    <Skeleton className=" w-full h-[350px] mt-3 " width="100%" height="350px"></Skeleton>
                    : <div className="w-auto grid grid-cols-1">
                        {/* <BreadCrumb className='border-none text-sm' model={items} home={home} /> */}
                        <div className="col-span-full">
                            <TableXConfig
                                tableConfig={tableConfig}
                                data={courtesies}
                                exportable
                                autoLayout={true}
                                noLazy
                                // attendance={attendance}
                                tickets={tickets}
                                zone={zone}
                            />
                        </div>
                    </div>
            }
        </>
    )
}