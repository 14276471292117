import { Children, useMemo } from 'react'
import { useDropzone, } from 'react-dropzone';
import axios from 'axios'
import DragAndDropIcon from '../icons/DragAndDropIcon';
import { useWatch, useForm } from 'react-hook-form'
import FilledClose from '../icons/FilledClose';
import GbToolTips from '../tooltips/GbToolTips';
import { Button } from 'primereact/button';


interface FileDropZonePickerProps {
    onChange: any
    desription?: string
    isMultiple?: boolean
    children?: any
    control: any,
    name: string
    reset: any
    prevImgUrl?: string
    inforForButton?: string
}

const enviar = async (files: any[], file2?: any) => {




    const formData = new FormData()
    let i = 0;
    for (let file of files) {
        formData.append('file' + (i + 1).toString(), file)
        i++
    }

    const result = await axios.post('http://localhost:5000/api/v2/events', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })

    console.log(result.data)

}


export default function FileDropZonePicker({
    onChange,
    desription,
    isMultiple,
    children,
    control,
    name,
    reset,
    prevImgUrl,
    inforForButton,
}: FileDropZonePickerProps) {


    const filesWatch = useWatch({
        control,
        name
    })



    const filesRender = filesWatch?.map((file: any) => {
        return Object.assign(file, {
            preview: URL.createObjectURL(file)
        })
    })


    const onDrop = async (files: File[]) => {

        const formData = new FormData()
        onChange(files)
        console.log('enviado')

    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        acceptedFiles,
        isDragReject,
        open,

    } = useDropzone({
        accept: { 'image/*': [] },
        onDrop,
        multiple: isMultiple ? true : false,
        noClick: true

    });


    const className = useMemo(() => {
        if (isFocused) return 'gb-dropzone on-focus reletive'
        if (isDragAccept) return 'gb-dropzone on-drag-accept relative'
        if (isDragReject) return 'gb-dropzone on-drag-rejet relative'
        return 'gb-dropzone'
    }, [isFocused,
        isDragAccept,
        isDragReject])



    return (

        <div className="container w-[100%]">
            <div {...getRootProps({
                className
            })}>
                <input {...getInputProps()} />

                {filesRender?.map((fileRender: any) => {
                    return <div className='relative'>
                        <img
                            src={fileRender.preview}
                            style={{
                                width: '250px',
                                height: 'auto',
                                borderRadius:"6px",
                                objectFit: 'cover'
                            }}
                            alt="thumails"
                            onLoad={() => {
                                URL.revokeObjectURL(fileRender.preview)
                            }}
                        />
                        <GbToolTips title="limpiar imagen" >
                            <button className='absolute right-0	top-0' type='button' onClick={() => {
                                reset(name)
                                // console.log('imagen lipiada')
                            }}> <FilledClose /> </button>
                        </GbToolTips>
                    </div>
                })}

                {
                    !filesRender && prevImgUrl && <div className='prev-img mb-[16px]'>
                        <img src={prevImgUrl} alt={prevImgUrl} />
                    </div>
                }


                {children}
                {
                    !filesRender && <>
                        <Button
                            onClick={open}
                            type="button"
                            style={{
                                backgroundColor: "#9fa8da"
                            }}
                        >
                            {inforForButton ? inforForButton : 'Presiona para seleccionar imagen '}
                        </Button>
                        <span>o</span>
                        <p className='flex'><span>Arrastra y suelta aqui</span>  <span className='ml-2'><DragAndDropIcon /></span> </p>
                    </>
                }
            </div>
        </div>

    )
}
