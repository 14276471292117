import * as React from "react";

const IconBs = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="429.476px"
    height="429.477px"
    viewBox="0 0 429.476 429.477"
    style={{
      enableBackground: "new 0 0 429.476 429.477",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M429.476,304.228c0,46.831-37.061,77.117-94.406,77.117c-25.677,0-50.88-6.165-69.109-16.881l-3.475-2.057 c-1.018-0.598-1.479-1.797-1.1-2.927l13.843-42.185c0.248-0.715,0.827-1.312,1.561-1.584c0.721-0.248,1.537-0.147,2.181,0.284 l5.45,3.558c11.868,7.767,32.408,15.581,51.595,15.581c23.578,0,37.64-10.107,37.64-27.077c0-16.113-9.67-25.115-38.325-35.695 c-54.828-19.559-66.354-50.378-66.354-72.817c0-44.556,36.056-75.685,87.681-75.685c21.822,0,44.626,5.769,60.976,15.454 l3.487,2.069c1.028,0.618,1.478,1.847,1.088,2.979l-13.938,40.56c-0.249,0.718-0.805,1.3-1.537,1.548 c-0.722,0.249-1.514,0.151-2.164-0.248l-5.343-3.372c-5.84-3.709-21.787-12.291-43.527-12.291 c-18.784,0-31.397,9.714-31.397,24.172c0,13.497,8.83,20.762,39.354,32.385C409.179,244.445,429.476,268.23,429.476,304.228z  M207.879,197.703l-9.708,4.909l9.942,4.454c27.314,12.164,40.76,36.765,41.122,75.208c0.23,24.908-7.543,45.501-23.123,61.224 c-24.885,25.121-67.176,37.853-125.678,37.853c-4.185,0-8.343-0.07-12.779-0.188L4.69,380.926c-1.241,0-2.403-0.474-3.275-1.336 C0.502,378.668,0,377.474,0,376.209V52.852c0-2.589,2.119-4.702,4.714-4.702l100.392-0.024c74.035,0,142.833,10.12,144.884,83.472 C250.995,168.04,227.647,187.661,207.879,197.703z M182.123,284.438c0-30.948-26.805-41.906-49.751-41.906l-69.921-0.344v86.771 l68.281,0.023c1.224,0.06,2.19,0.095,3.535,0.095c7.288,0,25.493-1.277,37.137-13.134 C178.765,308.453,182.365,297.85,182.123,284.438z M182.123,134.798c0-30.945-26.545-41.919-49.284-41.919L62.45,92.737v86.572 l67.616-0.012c0.047,0,0.089,0,0.133,0.012c1.554,0.062,2.415,0.085,4.025-0.047l2.843-0.225 c11.088-0.854,23.643-1.823,34.342-12.723C178.765,158.822,182.365,148.216,182.123,134.798z" />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default IconBs;