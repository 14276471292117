
import { Chart } from "primereact/chart";
import { useState } from "react";

function LineDayGraphic({ data,label }) {
    const [lineData] = useState({
        labels:label,
        datasets: [
            {
                label: 'Boletos vendidos',
                data: data ,
                fill: true,
                borderWidth:2,
                borderColor: '#42A5F5',
                tension: .1,
         
            }
        ],
        
        
    }); 
    let options = {
        maintainAspectRatio: false,
        aspectRatio: .9,
        
        plugins: {
            
            legend: {
              
                labels: {
                    color: '#ffffff',
                  
                }
                
            }
        },
        
        scales: {
            
            x: {
              
                ticks: {
                    color: '#ffffff',
                    font:{
                        size:10.5
                    }
                    
                },
                grid: {
                    
                    color: '#9c9c9c'
                }
            },
            y: {
                ticks: {
                    color: '#ffffff',
                    font:{
                        size:10.5
                    }
                },
                grid: {
                    color: '#9c9c9c'
                }
            }
            
        }
        
    };
    return (
  <>
      
       
          <Chart type="line"  data={lineData} options={options}
         />

  </>
    );
  }
  
  export default LineDayGraphic;
  