import { ChangeEvent, FocusEvent, useEffect, useState } from "react"
import { Taxt, TaxtFormOption } from "../../types/data.d.type"
// import RoadioButonGroupBase from "./RoadioButonGroupBase"
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import { InputText } from "primereact/inputtext";

interface AutoTaxtEventFormProps {
    onChange: (...event: any[]) => void
    value: Taxt | undefined,
    errorsPrice?: string
    errorsBasePrice?: string,
    paymentPlan?: number,
    errorsIVA?: string,
    errorsImpMunicipal?: string,
    defaultValues?:any,
    errorsServicioDeTaquilla?: string,
    errorsTotal?: string,
    eventType?: string,
}

interface Option {
    Icon?: any
    key: string
    View: string
}

const taxtComisionOption: Option[] = [{
    key: "integrated-commissions",
    View: "Integrar la Comision al precio actual",
    Icon: FullscreenExitIcon
},
{
    key: "aggregate-commissions",
    View: "Agregar la comicion Sobre el precio actual",
    Icon: FullscreenIcon
},
{
    key: "added-taxes-and-commissions",
    View: "Incluir Comisiones y taxes",
    Icon: AttachMoneyIcon
}
    ,
{
    key: "custom",
    View: "Agregar la comisiones de forma presonalizada",
    Icon: SettingsIcon
}
]

interface IntegrateComisionForm {
    price?: string
}


interface CustomTaxtFormProps {
    handleCheange: any
    taxtValues: Taxt
    errorsPrice?: string
    errorsBasePrice?: string,
    settaxtValues: any
    errorsIVA?: string,
    errorsImpMunicipal?: string,
    errorsServicioDeTaquilla?: string,
    paymentPlan?: number
    errorsTotal?: string
}

const CustomTaxtForm = (props: CustomTaxtFormProps) => {

    const {
        handleCheange,
        taxtValues,
        errorsBasePrice,
        errorsIVA,
        errorsImpMunicipal,
        errorsServicioDeTaquilla,
        settaxtValues,
        errorsTotal,
        paymentPlan
    } = props

    // console.log('ESTE ES MI PAYMENT PLAN', paymentPlan)

    const serviceBoxOffice = () => {
        switch (paymentPlan) {
            case 1:
                return 0
            case 2:
                const boxOffice = Number(taxtValues.price) * 0.10
                return boxOffice
            default:
                return 0
        }
    }

    const totalWithComission = Number(taxtValues.price) + serviceBoxOffice()

    const basePrice = Number((Number(totalWithComission) / 1.36).toFixed(2))
    const IVA = Number(Number(basePrice * 0.16).toFixed(2))
    const impMunicipal = Number((Number(basePrice) * 0.1).toFixed(2))
    // const servicioDeTaquilla = Number((Number(value) - total).toFixed(2))
    // const servicioDeTaquilla = Number((Number(basePrice * 0.1).toFixed(2)))
    const servicioDeTaquilla = serviceBoxOffice()

    const total = Number((Number(totalWithComission) - servicioDeTaquilla).toFixed(2))

    useEffect(() => {
        settaxtValues({
            ...taxtValues,
            basePrice: basePrice,
            IVA: IVA,
            impMunicipal: impMunicipal,
            total: total,
            servicioDeTaquilla: servicioDeTaquilla
        })
    }, [taxtValues.price])


    const preventScroll = (e: any) => {
        e.target.blur()
    }


    return <>
        {/* <label>
            <span>Precio Base</span>
            <InputText
                type="number"
                className=gb-input'
                name="basePrice"
                value={taxtValues?.basePrice}
                onChange={handleCheange}
                onWheel={preventScroll}

            />
            {errorsBasePrice && <span className="error-meessage">{errorsBasePrice}</span>}

        </label>
        <label>
            <span>IVA</span>
            <InputText
                type="number"
                className='gb-input'
                name="IVA"
                value={taxtValues?.IVA}
                onChange={handleCheange}
                onWheel={preventScroll}

            />
            {errorsIVA && <span className="error-meessage">{errorsIVA}</span>}
        </label>
        <label>
            <span>Impuesto municipal</span>
            <InputText
                type="number"
                className='gb-input'
                name="impMunicipal"
                value={taxtValues?.impMunicipal}
                onChange={handleCheange}
                onWheel={preventScroll}

            />
            {errorsImpMunicipal && <span className="error-meessage">{errorsImpMunicipal}</span>}
        </label> */}
        <label>
            <span>Comisión de servicio de taquilla</span>
            <InputText
                type="number"
                className='gb-input'
                name="servicioDeTaquilla"
                value={taxtValues?.servicioDeTaquilla}
                onChange={handleCheange}
                onWheel={preventScroll}
                readOnly
            />
            {errorsServicioDeTaquilla && <span className="error-meessage text-red-600">{errorsServicioDeTaquilla}</span>}
        </label>
        <label>
            <span>Total precio boleto USD</span>
            <InputText
                type="number"
                className='gb-input'
                name="total"
                value={totalWithComission}
                onChange={handleCheange}
                onWheel={preventScroll}
                readOnly
            />
            {errorsTotal && <span className="error-meessage text-red-600">{errorsTotal}</span>}
        </label>
    </>
}

const IntegrateComisionForm = (props: IntegrateComisionForm) => {

    const { price } = props

    const basePrice = Number((Number(price) / 1.36).toFixed(2))
    const IVA = Number(Number(basePrice * 0.16).toFixed(2))
    const impMunicipal = Number((Number(basePrice) * 0.1).toFixed(2))
    // const servicioDeTaquilla = Number((Number(value) - total).toFixed(2))
    const total = Number((Number(price) / 1.36).toFixed(2))
    const servicioDeTaquilla = Number((Number(basePrice * 0.1).toFixed(2)))




    return <div className="taxt-c-form">
        <label className="taxt-c-form-contorl">
            <span>Precio Base</span>
            <InputText type="text" value={basePrice} className="taxt-c-form-input" readOnly />
        </label>

    </div>

}

export default function AutoTaxtEventForm({
    onChange,
    value,
    defaultValues,
    errorsPrice,
    eventType,
    paymentPlan,
    errorsBasePrice,
    errorsIVA,
    errorsImpMunicipal,
    errorsServicioDeTaquilla,
    errorsTotal
}: AutoTaxtEventFormProps) {

    const [taxtFormOption, setTaxtFormOption] = useState<TaxtFormOption>('')

    // console.log('MIS VALORES',defaultValues)

    const [taxtValues, settaxtValues] = useState<Taxt>({
        basePrice: defaultValues.basePrice? defaultValues.basePrice:0,
        IVA: defaultValues.IVA? defaultValues.IVA:0,
        impMunicipal: defaultValues.impMunicipal? defaultValues.impMunicipal:0,
        servicioDeTaquilla: defaultValues.servicioDeTaquilla? defaultValues.servicioDeTaquilla:0,
        price: defaultValues.price? defaultValues.price:0,
        total: defaultValues.total? defaultValues.total:0
    })

    const [readOnlyTaxt, setReadOnlyTaxt] = useState({
        basePrice: true,
        IVA: true,
        impMunicipal: true,
        total: true,
        servicioDeTaquilla: true

    })

    const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value: taxtValue } = e.target
        settaxtValues({
            ...taxtValues,
            [name]: taxtValue
        })
    }

    useEffect(() => {
        onChange(taxtValues)
    }, [taxtValues])

    const preventScroll = (e: any) => {

        e.target.blur()

    }

    if (paymentPlan === 1) return <div className="mb-4"></div>

    return (
        <div className='taxt-form-zone mt-[15px]'>
            <div className="gb-form-control">
                <label>
                    <span> {eventType === 'turismo' ? "Precio del paquete USD" : "Precio del boleto USD"} </span>
                    <InputText
                        type="number"
                        className='gb-input'
                        name="price"
                        value={taxtValues?.price}
                        onChange={handlerChange}
                        onWheel={preventScroll}
                    />
                    {errorsPrice && <span className="error-meessage text-red-600">{errorsPrice}</span>}
                </label>
                {/* <RoadioButonGroupBase
                    currentOption={taxtFormOption}
                    labelText="Selectiona el sistema de Gatos y comision"
                    options={taxtComisionOption}
                    setData={setTaxtFormOption}
                />
               */}
                {/* {taxtFormOption === 'integrated-commissions' && <IntegrateComisionForm price={taxtValues.price?.toString()} />} */}
                <CustomTaxtForm
                    handleCheange={handlerChange}
                    paymentPlan={paymentPlan}
                    taxtValues={taxtValues}
                    settaxtValues={settaxtValues}
                    errorsBasePrice={errorsBasePrice}
                    errorsIVA={errorsIVA}
                    errorsImpMunicipal={errorsImpMunicipal}
                    errorsServicioDeTaquilla={errorsServicioDeTaquilla}
                    errorsTotal={errorsTotal}
                />
            </div>
        </div>
    )
}

