import { Button } from "primereact/button";


export default function UsagePLan({ setPaymentPlan, PAYMENTS_PLANS, setStep }) {

    // const PAYMENTS_PLANS = {
    //     NP: 0,
    //     FREE: 1,
    //     P1: 2,
    //     P2: 3
    // }

    const handleClick = (x) => {
        try {
            setPaymentPlan(x)
            setStep(0)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="grid gap-3 grid-cols-1 lg:grid-cols-3 ">
            <div className="border-gray-800 border p-3 rounded-[6px]">
                <div className=" grid w-full h-[60px] text-center  p-3 mb-2">
                    <h6 className="text-[2rem] mb-1" >Gratis</h6>
                    <span></span>
                </div>
                <div className="border border-b-gray-800 border-t-gray-800 border-l-transparent border-r-transparent mt-4 mb-4	pt-4 pb-4" >
                    <span className="flex w-full text-center">Para eventos con 0 - 300 visitantes</span>
                </div>
                <div className="mt-3 h-auto">
                    <ul className="grid gap-3">
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Entradas QR</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Sistema completo de tickets y solución de gestión de entradas</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Atención al cliente completa 24/7</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Aplicación de control de acceso para Android</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Precio gratuito</span>
                        </li>
                    </ul>
                </div>
                <div className="grid w-full mt-4">
                    <Button label="Continuar" onClick={() => handleClick(PAYMENTS_PLANS.FREE)} />
                </div>
            </div>
            <div className="border-gray-800 border p-3 rounded-[6px]">
                <div className=" grid w-full h-[60px] text-center  p-3 mb-2">
                    <h6 className="text-[2rem] mb-1" >10%</h6>
                    <span>Por boleto vendido</span>
                </div>
                <div className="border border-b-gray-800 border-t-gray-800 border-l-transparent border-r-transparent mt-4 mb-4	pt-4 pb-4" >
                    <span className="flex w-full text-center">Para eventos con 300 - 3.000 visitantes</span>
                </div>
                <div className="mt-3 h-auto">
                    <ul className="grid gap-3">
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Entradas QR</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Sistema completo de tickets y solución de gestión de entradas</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Atención al cliente completa 24/7</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Aplicación de control de acceso para Android</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Precios establecidos</span>
                        </li>
                    </ul>
                </div>
                <div className="grid w-full mt-4">
                    <Button label="Continuar" onClick={() => handleClick(PAYMENTS_PLANS.P1)} />
                </div>
            </div>
            <div className="border-gray-800 border p-3 rounded-[6px]">
                <div className=" grid w-full h-[60px] text-center  p-3 mb-2">
                    <h6 className="text-[2rem] mb-1" >Contacto</h6>
                    <span></span>
                </div>
                <div className="border border-b-gray-800 border-t-gray-800 border-l-transparent border-r-transparent mt-4 mb-4	pt-4 pb-4" >
                    <span className="flex w-full text-center">Para eventos con mas de 3.000 visitantes</span>
                </div>
                <div className="mt-3 h-auto">
                    <ul className="grid gap-3">
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Entradas QR o fisicas</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Sistema completo de tickets y solución de gestión de entradas</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Atención al cliente completa 24/7</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Aplicación de control de acceso para Android</span>
                        </li>
                        <li className="flex gap-2 items-center justify-left">
                            <i className="pi pi-check-circle text-green-500" ></i>
                            <span>Precios personalizados</span>
                        </li>
                    </ul>
                </div>
                <div className="grid w-full mt-4">
                    <Button label="Continuar" onClick={() => window.open(`https://api.whatsapp.com/send?phone=+5804241917939&text=Hola,+me+interesa+su+servicio+de+boleteria`)} />
                </div>
            </div>
        </div>
    )
}