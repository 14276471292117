import { Skeleton } from "primereact/skeleton"
import { useState } from "react"
import { useEffect } from "react"
import useUser from "../../Hooks/useUser"
import tableConfig from "./tableConfig.json"
import { TableXConfig } from "../../Components/TableX/TableXResumen"
import { methodsInBs, methodsInDollars, methodsInCOP } from "../../repository/config"
import { collection, getDocs, orderBy, query } from "firebase/firestore"
import { db } from "../../Config/firebaseConfig"

function totalBySaleBilling(x) {

    const chargeBoxOfficeService = true

    const arrBs = [];
    const arrBsProductor = [];
    const arrBsGlobalboletos = [];


    const arrDollars = [];
    const arrDollarsProductor = [];
    const arrDollarsGlobalboletos = [];

    const dolaresPorCredito = []

    const arrCop = []
    const arrCopProductor = []
    const arrCopGlobalboletos = []

    const serviceOfficeBoxUni = chargeBoxOfficeService ? Number(
        x.bill.generalPlace
            ? x.bill.generalPlace.servicioTaquillaUnitario
            : x.bill.vipPlaces.servicioTaquillaUnitario
    ) : 0

    const isBs = methodsInBs.some((z) => z === x.referenciaDePago);
    const isCOP = methodsInCOP.some((z) => z === x.referenciaDePago)

    if (x.referenciaDePago === "multipagos") {


        const amountBsInBs = [];
        const amountDollarsInBs = [];
        const amountCOPInMultipayments = []

        methodsInBs.forEach((y) => {
            const values = x.metodoDePago;
            if (values[y]) {
                if (values[y].value > 0) {
                    arrBs.push(values[y].value * x.tasaDelDolarToBs);
                    amountBsInBs.push(values[y].value);
                }
            }
        });

        methodsInDollars.forEach((y) => {
            const values = x.metodoDePago;
            if (values[y]) {
                if (values[y].value > 0) {
                    arrDollars.push(values[y].value);
                    amountDollarsInBs.push(values[y].value);
                }
            }
        })

        methodsInCOP.forEach((y) => {
            const values = x.metodoDePago;
            if (values[y]) {
                if (values[y].value > 0) {
                    arrCop.push(values[y].value * x.tasaDelCop);
                    amountCOPInMultipayments.push(values[y].value);
                }
            }
        })

        const amountTotalBsInMultipayment = amountBsInBs.reduce(
            (prev, curr) => prev + curr,
            0
        );
        const amountTotalDollarInMultipayment = amountDollarsInBs.reduce(
            (prev, curr) => prev + curr,
            0
        );

        const amountTotalCopInMultipayment = amountCOPInMultipayments.reduce(
            (prev, curr) => prev + curr,
            0
        );

        const calculateLargerQuantity = (numbers) => {
            if (toString.call(numbers) !== '[object Array]') {
                return null;
            }

            if (!numbers.every(e => typeof e == 'number')) {
                return null;
            }
            const highestNumber = Math.max.apply(null, numbers)

            if (highestNumber === amountTotalBsInMultipayment) {
                return "Bolivares"
            } else if (highestNumber === amountTotalDollarInMultipayment) {
                return "Dolares"
            } else if (highestNumber === amountTotalCopInMultipayment) {
                return "COP"
            }
        }

        const whereMoreAmount = calculateLargerQuantity([amountTotalBsInMultipayment, amountTotalDollarInMultipayment, amountTotalCopInMultipayment])


        const serviceOfficeBoxTotal = serviceOfficeBoxUni * x.boletos.length;
        const totalByItems = x.addedItems ? x.registryAddedItems.amount : 0
        const totalOfItemsWithBoxOfficeService = serviceOfficeBoxTotal + totalByItems
        const refundProtectionTotal = x.refundProtectionAmount ? x.refundProtectionAmount : 0

        if (amountTotalDollarInMultipayment > totalOfItemsWithBoxOfficeService) {

            // COBRAR AL PRODUCTOR EL SERVICIO DE TAQUILLA DE LOS DOLARES 
            arrDollarsProductor.push(amountTotalDollarInMultipayment - refundProtectionTotal - serviceOfficeBoxTotal)

            //SUMARLE TODOS LOS BOLIVARES EN LA ORDEN AL PRODUCTOR
            arrBsProductor.push(x.tasaDelDolarToBs * amountTotalBsInMultipayment);

            //SUMARLE TODOS LOS COP EN LA ORDEN AL PRODCUTOR
            if (amountTotalCopInMultipayment > 0) arrCopProductor.push(x.tasaDelCop * amountTotalCopInMultipayment);

            //SUMARLE EL SERVICIO DE TAQUILLA EN DOLARES A GLOBALBOLETOS
            arrDollarsGlobalboletos.push(serviceOfficeBoxUni * x.boletos.length);
        } else {

            if (whereMoreAmount === "Dolares") {
                // COBRAR AL PRODUCTOR EL SERVICIO DE TAQUILLA DE LOS DOLARES 
                arrDollarsProductor.push(amountTotalDollarInMultipayment - refundProtectionTotal - serviceOfficeBoxTotal);

                //SUMARLE TODOS LOS BOLIVARES EN LA ORDEN AL PRODUCTOR
                arrBsProductor.push(x.tasaDelDolarToBs * amountTotalBsInMultipayment);

                //SUMARLE TODOS LOS COP EN LA ORDEN AL PRODCUTOR
                if (amountTotalCopInMultipayment > 0) arrCopProductor.push(x.tasaDelCop * amountTotalCopInMultipayment);

                //SUMARLE EL SERVICIO DE TAQUILLA EN DOLARES A GLOBALBOLETOS
                arrDollarsGlobalboletos.push(serviceOfficeBoxUni * x.boletos.length);


            } else if (whereMoreAmount === "Bolivares") {

                // SE LE COBRA AL PRODUCTOR EL SERVICIO DE TAQUILLA DE LOS BOLIVARES
                arrBsProductor.push(x.tasaDelDolarToBs * (amountTotalBsInMultipayment - refundProtectionTotal - serviceOfficeBoxTotal));

                // EL PRODUCTOR SE LLEVA TODOS LOS DOLARES EN LA OPERACION
                arrDollarsProductor.push(amountTotalDollarInMultipayment);

                //SUMARLE TODOS LOS COP EN LA ORDEN AL PRODCUTOR
                if (amountTotalCopInMultipayment > 0) arrCopProductor.push(x.tasaDelCop * amountTotalCopInMultipayment);

                //SE LE SUMA A GLOBALBOLETOS DEL SERVICIO DE TAQUILLA EN BOLIVARES
                arrBsGlobalboletos.push(x.tasaDelDolarToBs * (serviceOfficeBoxUni * x.boletos.length));

            } else if (whereMoreAmount === "COP") {

                //CALCULAR TOTAL DE BOLIVARES EN LA OPERACION CON PORCENTAJE
                const totalBsWithPercentage = amountTotalBsInMultipayment * x.tasaDelDolarToBs - (amountTotalBsInMultipayment * x.tasaDelDolarToBs * 0.02)

                // SE LE COBRA AL PRODUCTOR EL SERVICIO DE TAQUILLA DE LOS COP
                arrBsProductor.push(x.tasaDelCop * (amountTotalCopInMultipayment - serviceOfficeBoxUni * x.boletos.length));

                // EL PRODUCTOR SE LLEVA TODOS LOS BOLIVARES EN LA OPERACION
                arrBsProductor.push(totalBsWithPercentage);

                // EL PRODUCTOR SE LLEVA TODOS LOS DOLARES EN LA OPERACION
                arrDollarsProductor.push(amountTotalDollarInMultipayment);

                //SE LE SUMA A GLOBALBOLETOS DEL SERVICIO DE TAQUILLA EN COP
                arrCopGlobalboletos.push(x.tasaDelCop * (serviceOfficeBoxUni * x.boletos.length));

            }

        }



    } else if (isBs) {

        const thereIsADiscount = x.discountedAmount ? x.discountedAmount : 0
        const totalByItems = x.addedItems ? x.registryAddedItems.amount : 0
        const totalAmountInOperation = x.precioTotal + totalByItems - parseFloat(thereIsADiscount)
        const serviceOfficeBoxTotal = serviceOfficeBoxUni * x.boletos.length;

        arrBs.push(totalAmountInOperation * x.tasaDelDolarToBs);
        arrBsProductor.push(x.tasaDelDolarToBs * (totalAmountInOperation - serviceOfficeBoxTotal));
        arrBsGlobalboletos.push(x.tasaDelDolarToBs * serviceOfficeBoxTotal);

    } else if (isCOP) {
        const dollarRate = x.tasaDelCop
        const thereIsADiscount = x.discountedAmount ? x.discountedAmount : 0
        const totalByItems = x.addedItems ? x.registryAddedItems.amount : 0
        const totalAmountInOperation = x.precioTotal + totalByItems - parseFloat(thereIsADiscount)
        const serviceOfficeBoxTotal = serviceOfficeBoxUni * x.boletos.length;

        arrCop.push(totalAmountInOperation * dollarRate)
        arrCopProductor.push(dollarRate * (totalAmountInOperation - serviceOfficeBoxTotal))
        arrCopGlobalboletos.push(dollarRate * serviceOfficeBoxTotal)

    } else {
        const thereIsADiscount = x.discountedAmount ? x.discountedAmount : 0
        const totalByItems = x.addedItems ? x.registryAddedItems.amount : 0
        const totalAmountInOperation = x.precioTotal + totalByItems - parseFloat(thereIsADiscount)
        const serviceOfficeBoxTotal = serviceOfficeBoxUni * x.boletos.length;

        if (x.referenciaDePago === "tarjetaDeCredito") { dolaresPorCredito.push(totalAmountInOperation) }
        arrDollars.push(totalAmountInOperation);
        arrDollarsProductor.push(totalAmountInOperation - serviceOfficeBoxTotal);
        arrDollarsGlobalboletos.push(serviceOfficeBoxTotal);
    }


    const totaBsProductor = arrBsProductor.reduce((prev, curr) => prev + curr, 0);
    const totaBsGlobalboletos = arrBsGlobalboletos.reduce((prev, curr) => prev + curr, 0);
    const totalBs = arrBs.reduce((prev, curr) => prev + curr, 0);
    const totaDollars = arrDollars.reduce((prev, curr) => prev + curr, 0);
    const totaDollarsProductor = arrDollarsProductor.reduce((prev, curr) => prev + curr, 0)
    const totaDollarsGlobalboletos = arrDollarsGlobalboletos.reduce((prev, curr) => prev + curr, 0);
    const totalDolaresPorTarjetaDeCredito = dolaresPorCredito.reduce((prev, curr) => prev + curr, 0);
    const totaCop = arrCop.reduce((prev, curr) => prev + curr, 0)
    const totaCopProductor = arrCopProductor.reduce((prev, curr) => prev + curr, 0)
    const totalCopGlobalboletos = arrCopGlobalboletos.reduce((prev, curr) => prev + curr, 0)
    const totalTickets = x.boletos.length
    const totalBankCommissionBs = totalBs * 0.02
    const totalBankCommissionDollars = totalDolaresPorTarjetaDeCredito * 0.01

    return {
        totalBs: totalBs,
        totalBolivaresProductor: totaBsProductor - totalBankCommissionBs,
        totalBolivaresGlobalboletos: totaBsGlobalboletos,

        totalDolares: totaDollars,
        totalDolaresProductor: totaDollarsProductor - totalBankCommissionDollars,
        totalDolaresGlobalboletos: totaDollarsGlobalboletos,

        totaCop: totaCop,
        totaCopProductor: totaCopProductor,
        totalCopGlobalboletos: totalCopGlobalboletos,

        totalTickets: totalTickets,
        totalComisionBancaria: totalBankCommissionBs,
        totalComisionBancariaDolares: totalBankCommissionDollars,

    }
}

export const changeTimezone = (date) => {
    let formatter = new Intl.DateTimeFormat('en-US', { timeZone: "America/Caracas" });
    let usDate = formatter.format(date);
    return new Date(usDate)
}

export const formatDate = (item) => {
    const clock = new Date((item.seconds + item.nanoseconds * 10 ** -9) * 1000)
    // console.log(new Date(clock.toLocaleDateString("es-ES", { timeZone: "America/Caracas" })))
    const formatterClock = changeTimezone(clock)
    if (formatterClock) return changeTimezone(clock)
    return clock
}

export default function Detail({ eventId }) {

    const { userData } = useUser()

    const [loading, setLoading] = useState(true)
    const [operations, setOperations] = useState(false)

    const requestOperations = async () => {
        try {
            const request = await fetchOperations({ eventId: eventId })
            setLoading(false)
            setOperations(request.filter(x => x !== false))
        } catch (err) {
            console.log(err)
        }
    }
    const filterExportData = (params, datad) => {
        let result = datad?.filter((e) => {
            return (
                e
            )
        });
        return result;
    }
    function toFixed(num) {
        if (isNaN(num)) {
        } else {
            var re = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");
            return num?.toString().match(re)[0];
        }
    }
    const totalInBs = (rowData) => {
        return toFixed(totalBySaleBilling(rowData).totalBolivaresProductor)
    }
    const totalInDollars = (rowData) => {
        return toFixed(totalBySaleBilling(rowData).totalDolaresProductor)
    }
    const date = (rowData) => {
        return formatDate(rowData.fecha).toLocaleDateString("es-ES", { timeZone: "America/Caracas" })
    }
    const zone = (rowData) => {
        if (rowData.boletos) {
            return rowData.boletos[0].zona
        }
        return ""
    }
    const ticketAmount = (rowData) => {
        if (rowData.boletos) {
            return rowData.boletos.length
        }
        return ""
    }
    const ci = (rowData) => {
        return rowData.comprador.cedula
    }
    const name = (rowData) => {
        return rowData.comprador.nombre
    }
    const phone = (rowData) => {
        return rowData.comprador.phone
    }
    const nInvoice = (rowData) => {
        return rowData.saleTag.lastTenDiginId
    }
    function calculateAge(date) {
        var hoy = new Date();
        var cumpleanos = new Date(date);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        return edad;
    }
    const age = (rowData) => {
        if (rowData.comprador.dateOfBirth) {
            if (calculateAge(rowData.comprador.dateOfBirth)) {
                return calculateAge(rowData.comprador.dateOfBirth)
            }
            return
        }
        return ""
    }
    const dolllarRate = (rowData) => {
        if (rowData.tasaDelDolarToBs) {
            return rowData.tasaDelDolarToBs
        }
        return ""
    }
    const tickets = (rowData) => {
        const result = rowData.boletos;
        const arr = [];
        result.forEach((x) => {
            if (x.id) {
                arr.push(x.id)
            } else if (x.tiketId) {
                arr.push(x.tiketId)
            }
            return ""
        });
        return arr.toString();
    }
    const itemsPurchased = (rowData) => {
        const result = rowData.addedItems ? rowData.registryAddedItems.items : []
        const arr = [];
        result.forEach((x) => {
            if (x.name) {
                arr.push(`${x.name} ${x.outline ? `(${x.outline})` : ''}`)
            }
            return ""
        });
        return arr.toString();
    }
    const assistants = (rowData) => {
        if (rowData.comprador.assistants) {
            const result = rowData.comprador.assistants
            const arr = [];
            result.forEach((x) => {
                arr.push(`NOMBRE:${x.name ? x.name : ""}-EDAD:${x.age ? x.age : ''}-DNI:${x.dni ? x.dni : ''}`)
            });
            return arr.toString();
        }
        return ""
    }

    const fetchOperations = async ({ eventId }) => {
        const q = query(collection(db, "events", eventId, "sales"), orderBy("fecha", "desc"));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            return []
        }
        else {
            return querySnapshot.docs.map((doc) => {

                const hiddenRocko = [
                    "97248-51808",
                    "26575-38670",
                    "58167-71502",
                    "69390-40769",
                    "60798-89519",
                    "49816-33401",
                    "24028-39272",
                    'GB-2023-1214-2056-17-1702587377534-82480-24789',
                    'GB-2023-1214-2053-40-1702587220837-07921-66301',
                    'GB-2023-1214-2052-00-1702587120232-38413-92185',
                    'GB-2023-1214-2000-13-1702584013052-75339-24860',
                    'GB-2023-1214-1958-49-1702583929680-23531-80589',
                    'GB-2023-1214-1957-08-1702583828978-13365-35788',
                    'GB-2023-1214-1955-23-1702583723596-04201-50078',
                    'GB-2023-1214-1950-56-1702583456434-05026-60138',
                    'GB-2023-1214-1509-24-1702566564367-87119-34334',
                    'GB-2023-1214-1102-59-1702551779290-78729-43525',
                    'GB-2023-1214-1102-37-1702551757702-77090-35530',
                    'GB-2023-1214-1102-17-1702551737552-39532-74152',
                    'GB-2023-1214-1101-50-1702551710529-26734-73805',
                    'GB-2023-1214-1101-30-1702551690908-66334-46594',
                    'GB-2023-1214-1101-09-1702551669835-88827-68093',
                    'GB-2023-1214-1059-09-1702551549149-48621-96495',
                    'GB-2023-1214-1100-37-1702551637882-89121-93089',
                    'GB-2023-1214-1100-01-1702551601494-03719-25381',
                    'GB-2023-1214-1059-41-1702551581378-13795-59804',
                    'GB-2023-1214-1058-44-1702551524743-76505-30022',
                    'GB-2023-1214-1058-23-1702551503143-89701-53909',
                    'GB-2023-1214-1028-21-1702549701847-41218-23946'
                ]

                const data = doc.data()

                if (!hiddenRocko.some((x) => x === data.hashId)) {
                    const id = doc.id
                    const x = { ...data, id }
                    return {
                        ...x,
                        ci: ci(x),
                        age: age(x),
                        date: date(x),
                        ticketAmount: ticketAmount(x),
                        itemsPurchased: itemsPurchased(x),
                        zone: zone(x),
                        assistants: assistants(x),
                        name: name(x),
                        phone: phone(x),
                        dolllarRate: dolllarRate(x),
                        tickets: tickets(x),
                        nInvoice: nInvoice(x),
                        totalInBs: totalInBs(x),
                        totalInDollars: totalInDollars(x)
                    }
                }else{
                    return false
                }
            })
        }
    }


    useEffect(() => {
        if (userData) requestOperations()
    }, [userData, eventId])

    return (
        <>
            {
                loading ?
                    <Skeleton className=" w-full h-[350px] mt-3 " width="100%" height="350px"></Skeleton>
                    : <div className="w-auto grid grid-cols-1">
                        <div className="col-span-full">
                            <TableXConfig
                                noLazy
                                exportable
                                data={operations}
                                autoLayout={true}
                                tableConfig={tableConfig}
                                filterExportData={filterExportData}
                            />
                        </div>
                    </div>
            }
        </>
    )
}