import { Fragment, useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
// import Chair from '../../components/icons/Chair';
// import Standing from '../../components/icons/Standing';
// import Tables from '../../components/icons/Tables';
import RadioGroupButton, { RadioData } from '../../components/inputs/radios-gourp/RadioGroupButtonSamll';
import TextFieldInput from '../../components/inputs/text-field-input/TextFieldInput';
import { ZoneFormValue } from '../../types/data.d.type';
import ConditionalFomZones from './ConditionalFomZones';
import * as yup from 'yup'
import useYupValidationResolver from './YupValiadtionResolvers';
import { Button } from 'primereact/button';
import { images } from '../../../../repository/config';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider';

const checkTallerThanCero = (value: any) => {
    console.log('evaluando precio')
    return Number(value) > 0
}

export const placesType: RadioData[] = [
    {
        value: 'standing',
        view: 'Lugares sin enumerar',
        icon: images.general
    },
    // {
    //     value: 'chairs',
    //     view: 'Lugares enumerados',
    //     icon: images.chairs,
    // },
    // {
    //     value: 'tables',
    //     view: 'Mesas enumeradas',
    //     icon: images.tables
    // }
]

export const typeOfPlacesForTrips: RadioData[] = [
    {
        value: 'standing',
        view: 'Turismo',
        icon: images.general
    }
]

interface StepPlaceInitialConfigProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    setValue: (values: any) => void,
    paymentPlan: number,
    defaultValues: [{ name: string; } | {
        startOfNumbering: 1,
        startOfRow: 1
        rowLabel: "1,2,3"
        taxtData: { impMunicipal: string; IVA: string; basePrice: string; price: string; servicioDeTaquilla: number; total: number; };
    }],
    eventType: string
}

export default function StepPlaceInitialConfig({ setStep, setValue, paymentPlan, eventType, defaultValues }: StepPlaceInitialConfigProps) {

    const validationSchema = useMemo(() => {
        return yup.object().shape({
            zones: yup.array().of(
                yup.object().shape({
                    name: yup.string().required('Este campo es obligatorio.'),
                    // rows: yup.string().when('type', {
                    //     then: yup.string().test('check-rows', 'El numero de fila debe ser mayor que cero XC', checkTallerThanCero).test('isEmpty', 'el campo de filas es requerido', (value) => value ? true : false),
                    //     otherwise: yup.string().notRequired()
                    // }),
                    type: yup.string(),
                    // blocks: yup.string().when('type', {
                    //     is: (value: any) => value === 'chairs' || value === 'tables',
                    //     then: yup.string().required('el Precio es requerido').test('check-block', 'El numero de Bloques debe ser mayor que cero', checkTallerThanCero),
                    //     otherwise: yup.string().notRequired()
                    // }),
                    // colum: yup.string().when('type',
                    //     {
                    //         is: (value: any) => {
                    //             return value === 'chairs' || value === 'tables'
                    //         },
                    //         then: yup.string().required('El numero de columna Es requerido XC').test('cher-colusms', 'El numero de las columnas debe ser mayor a ceros', checkTallerThanCero),
                    //         otherwise: yup.string().notRequired()
                    //     }
                    // ),
                    taxtData: paymentPlan === 1 ? yup.object() : yup.object().shape({
                        price: yup.string().test('check-price', 'El campo de precio debe ser mayor a cero', checkTallerThanCero).test('isEmpty', 'el campo de precio es requerido', (value) => value ? true : false),
                        basePrice: yup.string().required('lo siento preo el precio base es requerido').test('check-base-price', 'El precio base debe ser mayor a cero', checkTallerThanCero),
                        IVA: yup.string().required('El iva es requerido'),
                        impMunicipal: yup.string().required('El impuesto municipal es requrido'),
                        // total: yup.string().required("El precio total es requerido").test('check-total', 'El precio de ganacias para el productor debe ser mayor a cero', checkTallerThanCero),
                        // servicioDeTaquilla: yup.string().required("La ganacias por servicio de taquilla es requerida").test('check-taquilla', 'Las ganacias de la taquilla deben ser mayor a cero', checkTallerThanCero)
                    }),
                    // chairs: yup.string().when('type', {
                    //     is: (value: any) => value === 'tables',
                    //     then: yup.string().required('el numero de sillas es requerido').test('chairs-check', 'el numero de sillas debe ser mayor a cero', checkTallerThanCero),
                    //     otherwise: yup.string().notRequired()
                    // }),
                    generalTiketQuantity: yup.string().when('type', (value: any): any => {
                        if (value[0] === 'standing') {
                            return yup.string().test('check-price', 'Cantidad de boletos: La cantidad de boletos debe ser mayor a cero.', checkTallerThanCero).test('isEmpty', 'Cantidad de boletos: Este campo es obligatorio.', (value) => value ? true : false)
                        } else { return yup.string() }
                    })

                    // generalTiketStratNumber: yup.string().when('type', {
                    //     is: (value: any) => value === 'standing',
                    //     then: yup.string().test('check-start-number', 'El numero de inicio debe ser mayor a cero', checkTallerThanCero).required('La numeracion de inicio es requerida'),
                    //     otherwise: yup.string().notRequired()
                    // })
                })
            )
        })
    }, [])

    const resolver = useYupValidationResolver(validationSchema)

    const { control, register, handleSubmit, formState: { errors }, watch } = useForm<ZoneFormValue>({
        defaultValues: {
            zones: defaultValues.length > 0 ? defaultValues : [{ name: "", rows: 0, type: 'standing', colum: 0, blocks: 1 }]
        },
        resolver
    })

    const zonesFields = useFieldArray

    const { fields, append, prepend, remove, swap, move, insert } = zonesFields({
        control,
        name: "zones",
    })

    const onsubmit = (data: ZoneFormValue) => {
        setValue(data.zones)
        setStep(3)
    }


    const zones = watch('zones')

    const totalSeatsUsed = () => {
        const arr: number[] = []

        zones.forEach(x => {
            if (x.type === 'tables') {
                const colums = Number(x.colum)
                const rows = Number(x.rows)
                const blocks = Number(x.blocks)
                const chairs = Number(x.chairs)
                const operation = ((colums * rows) * chairs) * blocks
                arr.push(operation)
            } else if (x.type === "standing") {
                const amount = Number(x.generalTiketQuantity)
                arr.push(amount)
            } else if (x.type === "chairs") {
                const colums = Number(x.colum)
                const rows = Number(x.rows)
                const blocks = Number(x.blocks)
                const operation = (colums * rows) * blocks
                arr.push(operation)
            }
        })
        return arr.reduce((prev, curr) => prev + curr, 0)
    }

    const totalTicketsAllowed = () => {
        if (paymentPlan === 1) return 300
        if (paymentPlan === 2) return 3000
    }

    const addZone = ({
        blocks,
        colum,
        type,
        rows,
        chairs,
        tableShape
    }) => {
        try {

            // if(String((name)))
            // if(type==='standing'){

            // }
            append({
                blocks,
                colum,
                type,
                rows,
                chairs,
                tableShape
            })
        } catch (err) {
            console.log(err)
        }
    }

    console.log('DEFAULT VALUES', defaultValues)
    // console.log('FIELS')

    return (
        <div className="w-[100%]  mb-[2rem]">

            <div className='flex'>
                <div className='form-zone m-auto w-[100%] max-w-[800px]'>
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <div className='flex justify-between'>
                            <h5 className='mb-3'>Paso 3</h5>
                            <Tag severity='info' value={`${totalSeatsUsed()}/${totalTicketsAllowed()}`}></Tag>
                        </div>
                        {fields.map(({ id, name }, index) => {
                            return <Fragment key={id}>
                                <div className='mt-[10px] mb-[20px] p-2 rounded-[8px] border border-[#9fa8da]' >
                                    <TextFieldInput
                                        register={register(`zones.${index}.name`)}
                                        defaultValue={name ? name : ""}
                                        textlLabel={eventType === "turismo" ? "Nombre de opción*" : "Nombre de la zona*"}
                                        errorMessage={errors[`zones[${index}].name`] ? errors[`zones[${index}].name`].message : undefined}
                                    />
                                    <RadioGroupButton
                                        name={`type-event-${index}`}
                                        items={eventType === 'turismo' ? typeOfPlacesForTrips : placesType}
                                        register={register(`zones.${index}.type`)}
                                        label={eventType === "turismo" ? "Tipo de opción*" : "Seleccionar tipo de zona*"}
                                    />
                                    <ConditionalFomZones
                                        defaultValues={{
                                            name: "",
                                            taxtData: {
                                                "impMunicipal": 0,
                                                "IVA": 0,
                                                "basePrice": 0,
                                                "price": 0,
                                                "servicioDeTaquilla": 0,
                                                "total": 0
                                            }
                                        }}
                                        paymentPlan={paymentPlan}
                                        control={control}
                                        eventType={eventType}
                                        index={index}
                                        register={register}
                                        errors={errors}
                                    />
                                    {
                                        index > 0 && <Button
                                            onClick={() => remove(index)}
                                            className='w-full bg-[#ef4444] hover:bg-[#dc2626]'
                                            style={{
                                                background: '#ef4444'
                                            }}
                                            label={eventType === "turismo" ? "Remover paquete" : "Remover zona"}
                                        />
                                    }


                                </div>
                                {
                                    fields.length > 1 && <Divider />
                                }
                            </Fragment>
                        })}
                        <div className='grid gap-5'>
                            <Button onClick={() => addZone({
                                blocks: 0,
                                colum: 0,
                                type: 'standing',
                                rows: 0,
                                chairs: 0,
                                tableShape: 'rectangle'

                            })
                            }
                                style={{
                                    backgroundColor: "#9fa8da8c"
                                }}
                                type="button"
                            >  {eventType === "turismo" ? "Agregar otro paquete" : "Agregar otra zona"} </Button>
                            <Button> Continuar </Button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}
