

function useFormatNumber() {
    function formatNumberMile(number, userData) {
        // if (userData && userData.email === 'lacarlota@gb.com') return new Intl.NumberFormat('de-DE').format(10000 + number)
        return new Intl.NumberFormat('de-DE').format(number)
    }
    return { formatNumberMile };
}

export default useFormatNumber;