import FileDropZonePicker from "../../components/dropzone/FileDropZonePicker";
import { useForm, Controller } from 'react-hook-form'
import { DynamicFiles } from '../../types/data.d.type'
import axios from 'axios'
import { useMemo, useState } from "react";
import FilledHIms from "../../components/icons/FilledHIms";
import { Button } from "primereact/button";
import * as yup from 'yup'
import useYupValidationResolver from "./YupValiadtionResolvers";

type DataKey = 'backCoverH' | 'backCoverV' | 'logos' | 'cover' | 'logoMono'

interface InputsStepMultimedia {
  backCoverH: File[]
  backCoverV: File[]
  logos: File[]
  cover: File[]
  logoMono: File[]
  logoTicker: File[]
}

interface ArrMulrimedia {

  [key: string]: File[]

}


interface StepLogisticMultimediaConfigProps {
  setStep: any
  setValue: any
  eventType: any
}



export default function StepLogisticMultimediaConfig({ setStep, setValue, eventType }: StepLogisticMultimediaConfigProps) {

  const validationSchema = useMemo(() => {
    return yup.object({
      backCoverH: yup.mixed().required('Imagen horizontal: Este campo es obligatorio.'),
      backCoverV: yup.mixed().required('Imagen horizontal: Este campo es obligatorio.'),
      cover: yup.mixed().required('Imagen horizontal: Este campo es obligatorio.')
    })
  }, [])

  const resolver = useYupValidationResolver(validationSchema)


  const [file, setFile] = useState({})
  const { control, handleSubmit, resetField, formState: { errors } } = useForm<InputsStepMultimedia>({ resolver: resolver })

  const enviar = async (data: InputsStepMultimedia) => {

    const multimadiaToProcees: ArrMulrimedia = {
      ...data
    }

    const filesToSend: DynamicFiles = {}

    // const formData = new FormData();

    for (let fileKey in multimadiaToProcees) {

      if (multimadiaToProcees[fileKey]) {
        if (multimadiaToProcees[fileKey].length > 1) {
          let i = 0
          for (let file of multimadiaToProcees[fileKey]) {
            filesToSend[fileKey + i.toString()] = file
            i++
          }
        } else {
          filesToSend[fileKey] = multimadiaToProcees[fileKey][0]
        }
      }

    }

    setValue(filesToSend)
    setStep(6)

  }

  return (
    <>
      <form onSubmit={handleSubmit(enviar)} className="w-[100%] mb-5">
        <div className="flex flex-col gap-3 w-[100%]">

          <h5 className="mb-4">Paso 4</h5>
          <div>
            <p> {eventType === 'turismo' ? "Imagen de la experiencia" : "Imagen horizontal*"} </p>
            <Controller
              control={control}
              name="backCoverH"
              render={({
                field: { onChange }
              }) => (<FileDropZonePicker control={control} name="backCoverH" onChange={onChange} reset={resetField} >
                {/* <span>La imagen debe ser horizontal</span>
                <span>1920px * 1080px</span>
                <FilledHIms /> */}
              </FileDropZonePicker>)}
            />
            {errors && errors.backCoverH && <span className="text-message-error text-red-600	">{errors.backCoverH.message}</span>}

          </div>

          <div>
            <p>{eventType === 'turismo' ? "Otra imagen de la experiencia" : "Imagen vertical*"} </p>
            <Controller
              control={control}
              name="backCoverV"
              render={({
                field: { onChange }
              }) => (<FileDropZonePicker onChange={onChange} control={control} name="backCoverV" reset={resetField} />)}
            />
            {errors && errors.backCoverV && <span className="text-message-error text-red-600	">{errors.backCoverV.message}</span>}

          </div>

          <div>
            <p>  {eventType === 'turismo' ? "Imagen principal de la experiencia" : "Imagen característica de la experiencia (500X500) *"}  </p>
            <Controller
              control={control}
              name="cover"
              render={({
                field: { onChange }
              }) => (<FileDropZonePicker onChange={onChange} control={control} name="cover" reset={resetField}>
              </FileDropZonePicker>)}
            />
            {errors && errors.cover && <span className="text-message-error text-red-600	">{errors.cover.message}</span>}
          </div>


        </div>
        <div className="grid w-full mt-3">
          <Button label="Siguiente" />
          {
            Object.keys(errors).length > 0 && <div>
              <p>Registro de errores:</p>
              {
                Object.keys(errors).map((x, index) => <p key={index}>
                  <span className="text-message-error  text-red-600">{errors[x].message} </span>
                </p>)
              }
            </div>
          }

        </div>
      </form>
    </>
  )
}
