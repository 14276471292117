import React, { useRef, useState, useEffect } from "react";
import Drawer from "../Components/Drawer/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../Components/HeaderBar/HeaderBar";
import { Message } from "primereact/message";
import useUser from "../Hooks/useUser";

function Layout({ children }) {

  const navigate = useNavigate()

  const route = useLocation().pathname;
  const [showDrawer, setShowDrawer] = useState(false);
  const refNav = useRef(null)

  const { userData } = useUser()

  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input) => {
      input.setAttribute('autocomplete', 'off');
    })
  }, [])

  if (route.includes("/File")) return <div className="flex w-full h-screen relative  ">{children}</div>
  if (route.includes("closing")) return <div className="flex w-full h-screen relative  ">{children}</div>
  if (route === "/Access/SignIn") return <div className="flex w-full h-screen relative  ">{children}</div>
  if (route === "/Signin") return <div className="flex w-full h-screen relative  ">{children}</div>
  if (route === "/SignIn") return <div className="flex w-full h-screen relative  ">{children}</div>
  if (route === "/Login") return <div className="flex w-full h-screen relative  ">{children}</div>



  return (
    <div className="flex w-full h-screen relative   ">
      <Drawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        refNav={refNav}
        className="z-[1007]"
      />
      <HeaderBar showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      <div className="ml-0 px-1 lg:mx-5 mt-16 w-full max-w-7xl layout-main" >
        {userData && !userData.publicData && <Message severity="warn" onClick={() => navigate('/Configuration/Public-data')} text="No has completado tus datos como organizador. Pulsa click aca para completar" className='w-full mt-4 mb-4 text-center underline cursor-pointer' />}
        {children}
      </div>
    </div>
  )
}

export default Layout;
