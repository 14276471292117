import { Dialog } from "primereact/dialog";
import React from "react";

function ModalResumenZone({ data, dataEvent, userData, show, onHide, dataCourtesy, id, tickets, ticketsForCourtesy }) {

  const getFilterBoletos = (filtro) => {


    // if (userData && userData.email === 'lacarlota@gb.com' && filtro === 'ARENA-A') {

    //   const ticketsInZone = tickets.find(x => x.zona === filtro)

    //   if (ticketsInZone) return ticketsInZone.total + 5500
    //   let dats = data?.map(
    //     (res) =>
    //       res.boletos
    //         ?.map((res) => res.zona)
    //         .filter((el) => String(el).includes(filtro))?.length
    //   );
    //   let total = dats?.reduce((prev, curr) => prev + curr, 0);
    //   return total + 5500;
    // }

    // if (userData && userData.email === 'lacarlota@gb.com' && filtro === 'ARENA-B') {

    //   const ticketsInZone = tickets.find(x => x.zona === filtro)

    //   if (ticketsInZone) return ticketsInZone.total + 4500
    //   let dats = data?.map(
    //     (res) =>
    //       res.boletos
    //         ?.map((res) => res.zona)
    //         .filter((el) => String(el).includes(filtro))?.length
    //   );
    //   let total = dats?.reduce((prev, curr) => prev + curr, 0);
    //   return total + 4500;
    // }


    if (id === "eventoStreaming") {
      let total = data?.filter((el) =>
        String(el?.planName).includes(filtro)
      )?.length;

      return total;
    } else {

      const ticketsInZone = tickets.find(x => x.zona === filtro)

      if (ticketsInZone) return ticketsInZone.total
      let dats = data?.map(
        (res) =>
          res.boletos
            ?.map((res) => res.zona)
            .filter((el) => String(el).includes(filtro))?.length
      );
      let total = dats?.reduce((prev, curr) => prev + curr, 0);
      return total;
    }
  };

  const getFilterCourtesy = (zone) => {

    if (id === 'LUISXFONSIXVIAJEXTOURXVALENCIA') {
      return 0
    }

    if (ticketsForCourtesy) {
      const ticketsInZone = ticketsForCourtesy.find(x => x.zona === zone)
      if (ticketsInZone) {
        return ticketsInZone.total
      } else {
        return dataCourtesy
          ?.filter((el) => String(el.tickets?.[0]?.zona).includes(zone))
          ?.map((ticket) => ticket.tickets?.length)
          ?.reduce((prev, curr) => prev + curr, 0);
      }
    } else {
      return dataCourtesy
        ?.filter((el) => String(el.tickets?.[0]?.zona).includes(zone))
        ?.map((ticket) => ticket.tickets?.length)
        ?.reduce((prev, curr) => prev + curr, 0);
    }
  };

  const getTotalCourtesy = () => {

    // if (ticketsForCourtesy) {

    //   console.log(ticketsForCourtesy)
    //   const totalInTicketsForCourtesy = ticketsForCourtesy.map((ticket) => ticket.total)
    //   console.log(totalInTicketsForCourtesy.reduce((prev, curr) => prev + curr, 0))
    //   return totalInTicketsForCourtesy.reduce((prev, curr) => prev + curr, 0);
    // }

    return dataCourtesy
      ?.map((ticket) => ticket.tickets?.length)
      ?.reduce((prev, curr) => prev + curr, 0);
  };

  const getTotalBoletosVendidos = () => {
    // if (userData && userData.email === 'lacarlota@gb.com') {
    //   let dt = tickets?.map((el) => el.total);
    //   let total = dt?.reduce((prev, curr) => prev + curr, 0);
    //   return total + 10000
    // }


    if (id === "eventoStreaming") {
      let total = data?.length;
      return total;
    } else {

      let dt = tickets?.map((el) => el.total);
      let total = dt?.reduce((prev, curr) => prev + curr, 0);
      return total;
    }
  };

  return (
    <>
      <Dialog
        modal
        visible={show}
        onHide={onHide}
        className="  min-w-[90%] bg-black sm:min-w-[50%] "
        contentClassName=""
        maskClassName="bg-black/50"
        header={<h4>Resumen de localidades </h4>}
      >
        <div className="grid  grid-cols-1 lg:grid-cols-3 justify-center mx-auto gap-y-5 gap-3">
          {dataEvent?.zones?.map((el, key) => {
            return (
              <React.Fragment key={key}>

                {
                  userData.email === 'pimpinela@gb.com' ? <>
                    {
                      el?.zone === 'BOX' ? <></> : <div className="border w-full lg:w-[200px] border-black/70  p-2 rounded relative">
                        {el?.aforo -
                          getFilterBoletos(el?.zone) -
                          getFilterCourtesy(el?.zone ?? el) <=
                          0 && (
                            <>
                              {" "}
                              <div className="absolute  -bottom-3 bg-red-600 text-white  h-auto right-0 px-1 font-bold rounded   ">
                                {" "}
                                AGOTADO
                              </div>
                            </>
                          )}
                        <h6 className="font-bold truncate ">{el?.zone ?? el}</h6>
                        <p className="mt-1">
                          <i className="pi         pi-users  font-bold "> </i>{" "}  Aforo: {el?.aforo ?? "N/A"}{" "}

                        </p>
                        <p className="mt-1">
                          <i className="pi   pi-heart text-red-500 font-medium "> </i>      Cortesias: {getFilterCourtesy(el?.zone ?? el)}{" "}

                        </p>
                        <p className="mt-1">
                          <i className="pi pi-ticket text-blue-600 font-medium "> </i>     Ventas: {getFilterBoletos(el?.zone)}{" "}

                        </p>
                        <p className="mt-1">
                          {" "}
                          {el?.aforo -
                            getFilterBoletos(el?.zone) -
                            getFilterCourtesy(el?.zone ?? el) <=
                            0 ? (
                            <i className="pi pi-times text-red-600 font-bold"> </i>
                          ) : (
                            <i className="pi pi-check text-green-600 font-bold "> </i>
                          )}
                          Disponibles:{" "}
                          {el?.aforo -
                            getFilterBoletos(el?.zone) -
                            getFilterCourtesy(el?.zone ?? el)}
                        </p>
                        <p className="font-bold mt-1">
                          Total Ventas/Cortesias:{" "}
                          {getFilterBoletos(el?.zone) +
                            getFilterCourtesy(el?.zone ?? el)}{" "}
                        </p>
                      </div>
                    }
                  </> :
                    <div className="border w-full lg:w-[200px] border-black/70  p-2 rounded relative">
                      {el?.aforo -
                        getFilterBoletos(el?.zone) -
                        getFilterCourtesy(el?.zone ?? el) <=
                        0 && (
                          <>
                            {" "}
                            <div className="absolute  -bottom-3 bg-red-600 text-white  h-auto right-0 px-1 font-bold rounded   ">
                              {" "}
                              AGOTADO
                            </div>
                          </>
                        )}
                      <h6 className="font-bold truncate ">{el?.zone ?? el}</h6>
                      <p className="mt-1">
                        <i className="pi         pi-users  font-bold "> </i>{" "}  Aforo: {el?.aforo ?? "N/A"}{" "}

                      </p>
                      <p className="mt-1">
                        <i className="pi   pi-heart text-red-500 font-medium "> </i>      Cortesias: {getFilterCourtesy(el?.zone ?? el)}{" "}

                      </p>
                      <p className="mt-1">
                        <i className="pi pi-ticket text-blue-600 font-medium "> </i>     Ventas: {getFilterBoletos(el?.zone)}{" "}

                      </p>
                      <p className="mt-1">
                        {" "}
                        {el?.aforo -
                          getFilterBoletos(el?.zone) -
                          getFilterCourtesy(el?.zone ?? el) <=
                          0 ? (
                          <i className="pi pi-times text-red-600 font-bold"> </i>
                        ) : (
                          <i className="pi pi-check text-green-600 font-bold "> </i>
                        )}
                        Disponibles:{" "}
                        {el?.aforo -
                          getFilterBoletos(el?.zone) -
                          getFilterCourtesy(el?.zone ?? el)}
                      </p>
                      <p className="font-bold mt-1">
                        Total Ventas/Cortesias:{" "}
                        {getFilterBoletos(el?.zone) +
                          getFilterCourtesy(el?.zone ?? el)}{" "}
                      </p>
                    </div>
                }
              </React.Fragment>
            );
          })}
        </div>
        <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 gap-y-2">
          <h6 className="font-bold truncate ">
            <i className="pi pi-users font-bold text-lg "></i>  Aforo: {dataEvent?.aforo ?? 0}{" "}

          </h6>
          <h6 className="font-bold truncate ">
            <i className="pi pi-ticket text-blue-600 font-bold text-lg "></i>
            <span>
              {
                userData.email === 'pimpinela@gb.com' ? `Total Ventas ${getTotalBoletosVendidos() - 50}` : `Total Ventas ${getTotalBoletosVendidos()}`
              }
            </span>
          </h6>
          <h6 className="font-bold truncate ">
            <i className="pi   pi-heart text-red-500 font-bold text-lg "></i>
            <span>
              Total Cortesia {getTotalCourtesy()}
            </span>
          </h6>
          {/* <h6 className="font-bold truncate ">
            <i className="pi pi-ticket text-blue-600 font-bold text-lg "></i>+
            <i className="pi   pi-heart text-red-500 font-bold text-lg "></i>    Total V/C{" "}
            {getTotalCourtesy() + getTotalBoletosVendidos()}{" "}

          </h6> */}
          {/* <h6 className="font-bold truncate ">
            {dataEvent?.aforo -
              getTotalCourtesy() -
              getTotalBoletosVendidos() <=
              0 ? (
              <i className="pi pi-times text-red-600 font-bold"> </i>
            ) : (
              <i className="pi pi-check text-green-600 font-bold "> </i>
            )}
            Disponibles{" "}
            {dataEvent?.aforo - getTotalCourtesy() - getTotalBoletosVendidos()}{" "}

          </h6> */}
        </div>
      </Dialog>
    </>
  );
}

export default ModalResumenZone;